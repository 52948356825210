import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const GetActivities = () => {
    return APIHandler.get(URLs.activities.GetAll, null);
}

const GetByIdSection = (IdSection) => {
    return APIHandler.get(`${URLs.activities.GetByIdSection}${IdSection}`);
}

const onGetByUserStoryAndSection = (epicsId, sectionId, userstoryId) => {
    return APIHandler.get(`${URLs.activities.Act_GetByUserStoryAndSection}${epicsId}/${userstoryId}/${sectionId}`);
}

const GetById = (IdActivity) => {
    return APIHandler.get(`${URLs.activities.GetById}${IdActivity}`);
}

const GetStatusActivities = () => {
    return APIHandler.get(URLs.activities.GetStatusActivities);
}

const onGetAllOriginActivities = () => {
    return APIHandler.get(URLs.activities.act_GetAllOriginActivities);
}

const onGetActivitiesWithFilters = () => {
    return APIHandler.get(URLs.activities.act_GetActivitiesFilters);
}

const onUpdateActivity = (id, activity) => {
    return APIHandler.put(`${URLs.activities.Update}${id}`, activity)
}

const DeleteActivity = (IdActivity) => {
    return APIHandler.delete(`${URLs.activities.Delete}${IdActivity}`);
}

const SaveActivity = (newActivity) => {
    return APIHandler.post(URLs.activities.Create, newActivity);
}

const UpdateStatus = (IdSection, newOrden) => {
    return APIHandler.put(`${URLs.activities.UpdateStatus}${IdSection}`, newOrden);
}

const onUpdateOriginActivity = (idActivity, idOrigin) => {
    return APIHandler.put(URLs.activities.UpdateOriginActivity, { IdActivity : idActivity, IdOriginActivity : idOrigin });
}

export {
    GetActivities,
    GetByIdSection,
    GetById,
    GetStatusActivities,
    onUpdateActivity,
    UpdateStatus,
    DeleteActivity,
    SaveActivity,
    onUpdateOriginActivity,
    onGetByUserStoryAndSection,
    onGetAllOriginActivities,
    onGetActivitiesWithFilters,
}