import React, { useEffect, useRef, useState } from 'react';

export const ControllerInput = ({ value, onChange, ...rest }) => {

    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const input = ref.current.focus();
        if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);

    const handleChange = (e) => {
        setCursor(e.target.selectionStart);
        onChange && onChange(e);
    };

    return (
        <>
          <input type="text" ref={ref} value={value} onChange={handleChange} placeholder="Descripción." {...rest} />
        </>
    )
}
