import { useRef } from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"

export const UserAccountPage = () => {
    const inputFile = useRef(null);

    const { user: { Email, NombreCompleto } } = useSelector((state) => state.authorization);

    const onFileHandler = (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        console.log(file);
        this.setState({file}); /// if you want to upload latter
    }

    return (
        <>
            <div className="container-main-wrap">
                <div className="container">
                    <div className="rows-main mb-4">
                        <article className="font-28">Cuenta</article>
                    </div>

                    <div className="card-main mb-4">
                        <article className="font-18 mb-3">Perfil</article>

                        <div className="rows-main gap-20">
                            <div>
                                <div className="user-circle lg bg-gradient-4">SP</div>
                            </div>
                            <div>
                                <article className="font-20">
                                    { NombreCompleto }
                                </article>
                                <p className="color-font">
                                    { Email }
                                </p>
                                <button className="button dark" onClick={() => inputFile.current.click()}>
                                    <i className="icon-"></i>
                                    Cargar imagen
                                </button>
                                <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={onFileHandler}/>
                            </div>
                        </div>
                    </div>

                    <div className="card-main mb-4">
                        <div className="rows-main mb-4">
                            <div className="flex-fill">
                                <article className="font-18">Información personal</article>
                            </div>
                            <div>
                                <button className="button dark">
                                    Editar
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Nombre</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Apellidos</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Teléfono</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Puesto</label>
                                    <select className="form-select" aria-label="Default select example">
                                        {/* <option selected>Open this select menu</option> */}
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label className="form-label">Tipo de recurso</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <Link to="/loginchangepass" className="button white w-100">Cambiar contraseña</Link>
                        </div>
                        <div className="col-md-4 offset-md-4 mb-2">
                            <button className="button dark w-100">
                                <span className="flex-fill">Cerrar sesión</span>
                                <span>
                                    <i className="icon-logout_ic"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
