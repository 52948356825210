export const BaseURL = 'https://api.xscrum.softbox.mx/';
export const timeoutDuration = 30000;

export const URLs = {
  dashboard: '/dashboard',
  users: {
    use_LogIn: "api/Users/Login",
    use_LogOut: "api/Users/Logout",
    use_GetAll: "api/Users/GetAll",
    use_GetCatalogues: "api/Users/GetCatalogues",
    use_Create: 'api/Users/Create',
    use_Delete: 'api/Users/',
    use_UpdateImg: 'api/Users/UpdateImgUser/',
  },
  projects: {
    pro_GetAll: "api/Projects/GetAll",
    pro_UpdateStatus: "api/Projects/UpdateStatusProject/",
    pro_Delete_Update_GetById: "api/Projects/",
    pro_Create: "api/Projects/Create",
    pro_AddProjectUsers: "api/Projects/AddProjectUsers",
    pro_RemoveProjectUser: "api/Projects/RemoveProjectUser",
    pro_GetLastStatusProject: 'api/Projects/GetLastStatusProject/',
  },
  epics: {
    epi_Create: "api/Epics/Create",
    epi_GetAll: "api/Epics/GetAll",
    epi_GetByIdProject: "api/Epics/GetByProject/",
    epi_GetAllTreeData: 'api/Epics/GetAllTreeDataByProject/',
    epi_GetById: "api/Epics/",
    epi_Update: "api/Epics/",
    epi_UpdateStatus: "api/Epics/UpdateOrder/",
    epi_Delete: "api/Epics/"
  },
  userStories: {
    GetAll: "api/UserStories/GetAll",
    GetByIdProject: "api/UserStories/GetByProject/",
    GetByIdEpic: "api/UserStories/GetByEpic/",
    GetByIdProjectRelation: "api/UserStories/GetByProjectWithEpicsRelation/",
    us_GetByProjectAndEpicWithEpicsRelation: "api/UserStories/GetByProjectAndEpicWithEpicsRelation/",
    GetById: "api/UserStories/",
    Update:  "api/UserStories/",
    Delete:  "api/UserStories/",
    Create:  "api/UserStories/Create",
    CreateWithEpic:  "api/UserStories/CreateRemoveRelWithEpic",
    UpdateOrder: "api/UserStories/UpdateOrder/"
  },
  sections: {
    GetAll: "api/Sections/GetAll",
    GetByIdProject: "api/Sections/GetByProject/",
    GetByIdUserStory: "api/Sections/GetByUserStory/",
    GetByIdProjectRelation: "api/Sections/GetByProjectWithUserStoriesRelation/",
    sc_GetByProjectAndEpicWithUserStoriesRelation: "api/Sections/GetByProjectAndEpicWithUserStoriesRelation/",
    GetById: "api/Sections/",
    Update:  "api/Sections/",
    Delete:  "api/Sections/",
    Create:  "api/Sections/Create",
    CreateWithUserStory:  "api/Sections/CreateRemoveRelWithUserStory",
    UpdateOrder: "api/Sections/UpdateOrder/"
  },
  activities: {
    GetAll: 'api/Activities/GetAll',
    GetByIdSection: 'api/Activities/GetBySection/',
    Act_GetByUserStoryAndSection: 'api/Activities/GetByUserStoryAndSection/',
    GetById: 'api/Activities/',
    GetStatusActivities: 'api/Activities/GetStatusActivities',
    act_GetAllOriginActivities: 'api/Activities/GetAllOriginActivities',
    Update: 'api/Activities/',
    Delete: 'api/Activities/',
    Create: 'api/Activities/Create',
    UpdateStatus: 'api/Activities/UpdateOrder/',
    UpdateOriginActivity: 'api/Activities/UpdateOriginActivity',
    act_GetActivitiesFilters: 'api/Activities/GetSectionWithFilters'
  },
  sprints: {
    spr_AddOrUpdateWithActivities: 'api/Sprints/AddOrUpdateWithActivities',
    spr_CloseSprint: 'api/Sprints/Close',
    spr_SetOrRemovePreSelectedActivity: 'api/Sprints/SetOrRemovePreSelectedActivity',
    spr_SetOrRemovePreSelectedActivities: 'api/Sprints/SetOrRemovePreSelectedActivities',
    spr_GetHistorySprints: 'api/Sprints/GetByProject/'
  },
  calculations: {
    cal_Recalculation: 'api/Calculations/RecalculateProject/'
  }
};