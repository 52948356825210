import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SectionsType } from "../../ui/types/SectionsType";
import { CleanProjectAndTree } from "../project/slices";
import { Projects } from "./components";
import { Resources } from '../../ui/components/Resources/Resources';

export const DashboardPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(CleanProjectAndTree());
	}, [dispatch])


	return (
		<>
			<div className="container-main-wrap d-flex align-items-center">
				<div className="container-max">
					<div className="container-fluid">
						<div className="dashboard-listprojects">
							<div className="row">
								<Projects />

								<div className="col-lg-3 col-xxxl-2 mb-3">
									<Resources resourceSection={SectionsType.Dashboard} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
