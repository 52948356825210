import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const GetEpics = () => {
    return APIHandler.get(URLs.epics.epi_GetAll, null);
}

const GetEpicsByIdProject = (IdProject) => {
    return APIHandler.get(`${URLs.epics.epi_GetByIdProject}${IdProject}`, null);
}

const GetAllTreeData = (IdProject) => {
    return APIHandler.get(`${URLs.epics.epi_GetAllTreeData}${IdProject}`)
}

const GetEpicById = (Id) => {
    return APIHandler.get(`${URLs.epics.epi_GetById}${Id}`);

}

const UpdateEpic = (id, epic) => {
    console.log(epic);
    return APIHandler.put(`${URLs.epics.epi_Update}${id}`, epic)
}

const DeleteEpic = (epicId) => {
    return APIHandler.delete(`${URLs.epics.epi_Delete}${epicId}`);
}

const SaveEpic = (newEpic) => {
    return APIHandler.post(URLs.epics.epi_Create, newEpic);
}

const UpdateStatusEpic = (idProject, newOrden) => {
    return APIHandler.put(`${URLs.epics.epi_UpdateStatus}${idProject}`, newOrden);
}


export {
    GetEpics,
    GetEpicsByIdProject,
    GetEpicById,
    GetAllTreeData,
    UpdateEpic,
    DeleteEpic,
    SaveEpic,
    UpdateStatusEpic
}