import { URLs } from "../../../ui/constants"
import APIHandler from "../../../ui/helpers/APIHandler"

const GetSections = () => {
    return APIHandler.get(URLs.sections.GetAll);
}

const GetSectionsByIdProject = (IdProject) => {
    return APIHandler.get(`${URLs.sections.GetByIdProject}${IdProject}`);
}

const GetSectionsByIdUserStory = (IdUserStory) => {
    return APIHandler.get(`${URLs.sections.GetByIdUserStory}${IdUserStory}`);
}

const GetSectionsByIdProjectRelation = (IdProject) => {
    return APIHandler.get(`${URLs.sections.GetByIdProjectRelation}${IdProject}`);
}

const onGetByProjectAndEpicWithUserStoriesRelation = (projectId, epicId) => {
    return APIHandler.get(`${URLs.sections.sc_GetByProjectAndEpicWithUserStoriesRelation}${projectId}/${epicId}`);
}

const GetSectionById = (Id) => {
    return APIHandler.get(`${URLs.sections.GetById}${Id}`);

}

const UpdateSection= (id, section) => {
    return APIHandler.put(`${URLs.sections.Update}${id}`, section)
}

const DeleteSection= (idSection) => {
    return APIHandler.delete(`${URLs.sections.Delete}${idSection}`);
}

const SaveSection= (newSection) => {
    return APIHandler.post(URLs.sections.Create, newSection);
}

const SaveSectionWithUserStory = (newSection) => {
    return APIHandler.post(URLs.sections.CreateWithUserStory, newSection);
}

const UpdateOrderSections = (idProject, newOrden) => {
    return APIHandler.put(`${URLs.sections.UpdateOrder}${idProject}`, newOrden);
}


export {
    GetSections,
    GetSectionsByIdProject,
    GetSectionsByIdUserStory,
    GetSectionsByIdProjectRelation,
    GetSectionById,
    UpdateSection,
    DeleteSection,
    SaveSection,
    SaveSectionWithUserStory,
    UpdateOrderSections,
    onGetByProjectAndEpicWithUserStoriesRelation,
}