import { useSelector } from "react-redux";
import { ProjectActivitiesDetails } from "./details/ProjectActivitiesDetails"
import { ProjectEpicDetails } from "../epics/details/ProjectEpicDetails"
import { ProjectSectionDetails } from "../sections/details/ProjectSectionDetails"
import { ProjectUserStoryDetails } from "../userstories/details/ProjectUserStoryDetails"
import { HeaderTitleWithOptions } from "../shared/Project/HeaderTitleWithOptions";
import { useValidationState } from "../../hooks/useValidationState";
import { useEffect } from "react";
import { GetCatalogs } from "../../slices/Activities/ActivitiesTrunk";
import { useDispatch } from "react-redux";

export const ProjectActivitiesBase = ({section}) => {

	const dispatch = useDispatch();
	const { IdProject } = useSelector(state => state.project);
    const { isLoadingComponents } = useValidationState(section)

	useEffect(() => {
        if (IdProject !== 0){
            dispatch(GetCatalogs());
		}

    }, [IdProject, dispatch])

	return (
		<>
			{/* <div className="new-project-body-top mb-4">
				<div className="row">
					<div className="col-md-7">
						<div className="dato-name color-font lh-1 mb-2 mb-md-0">
							<article className="font-18 mb-2">
								Planeación de proyecto
							</article>
							<span>
								Define el alcance y estructura
							</span>
						</div>
					</div>
					<div className="col-md-5">
						<div className="d-flex gap-10">
							<span className="flex-fill">
								<input type="text" className="form-control" placeholder="Buscar" />
							</span>
							<button className="button grey-light font-20">
								<i className="icon-filtro-icon_v2"></i>
							</button>
						</div>
					</div>
				</div>
			</div> */}

			<HeaderTitleWithOptions section={section}/>

			<div className='row overflowwrap'>
				{
					IdProject !== 0 && (
						<>
							<ProjectEpicDetails section={section}/>
							<ProjectUserStoryDetails section={section}/>
							<ProjectSectionDetails section={section}/>

							<ProjectActivitiesDetails section={section}/>
						</>)
				}


			</div>
		</>
	)
}
