import { Skeleton } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { HavePermission } from "../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../ui/types/RolesType";
import { cleanUserStories } from "../../slices";
import { GetUserStoriesByProject } from "../../slices/UserStories/UserStoriesTrunk";
import { DroppableUserStories } from "./DroppableUserStories";
import { ProjectUserStoryNew } from "./ProjectUserStoryNew";
import { useValidationState } from "../../hooks/useValidationState";
import { SectionsType } from "../../../../ui/types";
import { useEffectOnce } from "usehooks-ts";
import Mousetrap from "mousetrap";
import { shortcutKeys } from "../../../../ui/constants/shortcutKeysConfig";
import { DraggingButtonHandle } from "../shared/DraggingButtonHandle";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { usePermission } from "../../../../ui/hooks/usePermission";

export const ProjectUserStory = ({ validateTree, section }) => {
  const dispatch = useDispatch();
  const { roleCanCreate } = usePermission(ModuleType.UserStories);
  const { IdProject, UsersAdded } = useSelector((state) => state.project);
  const { epics, hasEpicsChanged, epicSelected } = useSelector(state => state.epics);
  const { userstories, totalUserStories } = useSelector((state) => state.userstories);
  const { idRol } = useSelector((state) => state.authorization);
  //const { IdProject, ProjectUsersList } = project || { IdProject: 0, ProjectUsersList: [] };

  const [isNewUserHistory, setIsNewUserHistory] = useState(false);

  const { isLoadingComponents, isLoadingUserStoriesComponents } = useValidationState(section)

  // const [totalUserStories, setTotalUserStories] = useState(0);
  //const [userStories, setUserStories] = useState([]);

  // const GetUserStories = () => {
  //   if (IdProject)
  //     GetByIdProjectRelation(IdProject).then((response) => {
  //       const {
  //         status,
  //         data: { Items },
  //       } = response;

  //       if (status === 200) {
  //         setUserStories(Items);
  //         setTotalUserStories(Items.length + 1);
  //         setHandlerUserStories(Items);
  //         checkRelationshipCompleted(Items);
  //       }
  //     });
  // };

  // const checkRelationshipCompleted = (Items = []) => {
  //   setIsValidUserStories(true);

  //   if (Items.find(({ EpicsUserStory }) => EpicsUserStory.filter((eus) => eus.IsSelected).length === 0) !== undefined) {
  //     setIsValidUserStories(false);
  //   }
  // }
  useEffect(() => {
    Mousetrap.bind(shortcutKeys.newUserStory, (e) => {
      if (canEnable()) {
        setIsNewUserHistory(true);
        e.preventDefault();
      }
    });
  },[UsersAdded]);

  useEffect(() => {
    if (hasEpicsChanged) {
      dispatch(GetUserStoriesByProject(true));

    }
  }, [hasEpicsChanged, dispatch])

  useEffect(() => {
    if (IdProject && IdProject !== 0) {
      dispatch(GetUserStoriesByProject());
    }
    else dispatch(cleanUserStories());

    //GetUserStories();
  }, [IdProject, epicSelected, dispatch]);

  const onCreateUserHistory = (e) => {
    //setTotalUserStories(userStories.length + 1);
    setIsNewUserHistory(true);
  };

  const onCancelUserStory = () => {
    //setTotalUserStories(userStories.length - 1);
    setIsNewUserHistory(false);
  };

  const canEnable = () => (UsersAdded?.length >= 1 && epics?.length >= 1) && roleCanCreate;
  // const onUserStorySaved = () => {
  //   GetUserStories();
  // };

  return (
    <>
      <div className="col-md-4">
        <article className="color-dark font-16 mb-3 ms-4">
          User Stories de Proyecto
        </article>
        <div className="add-plans-items">
          {
            !isNewUserHistory && (
              <div className="add-item">
                <span className="icon">
                  <DraggingButtonHandle hasPointer={false} />
                </span>
                <PermissionGate module={ModuleType.UserStories} scopes={[SCOPES.CanCreate]} errorProps={{ disabled: true }}>
                  <button
                    className="button border-grey-dot flex-fill"
                    onClick={onCreateUserHistory}
                  >
                    <span className="me-2">
                      <i className="bi bi-plus"></i>
                    </span>
                    <span>Agregar</span>
                  </button>
                </PermissionGate>
              </div>
            )
          }

          {isNewUserHistory && (
            <ProjectUserStoryNew
              userStoryNum={totalUserStories + 1}
              idProject={IdProject}
              onCancelUserStory={onCancelUserStory}
              onChangeItems={() => dispatch(GetUserStoriesByProject(true))}
            />
          )}

          <DroppableUserStories
            idProject={IdProject}
            validateTree={validateTree}
            canAddUserStory={canEnable()}
            getUserStories={() => dispatch(GetUserStoriesByProject(true))}
            section={section}
          />
        </div>
      </div>
    </>
  );
}
