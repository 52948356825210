import { useSelector } from "react-redux";
import { SCOPES } from "../../types/ScopesType";
import { cloneElement } from "react";
import { Tooltip } from "@mui/material";

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions?.some((permission) => scopesMap[permission])
};

export const PermissionGate = ({ children, module, scopes = [], errorProps = null }) => {
    const { permissions: userPermission } = useSelector((state) => state.authorization);

    const _permissions = userPermission.find(item => item.IdModule === module);
    const { CanCreate, CanSee, CanUpdate, CanDelete } = _permissions;

    let permissions = [];

    if (CanCreate)
        permissions.push(SCOPES.CanCreate);
    if (CanSee)
        permissions.push(SCOPES.CanSee);
    if (CanUpdate)
        permissions.push(SCOPES.CanUpdate);
    if (CanDelete)
        permissions.push(SCOPES.CanDelete);

    const permissionGranted = hasPermission({permissions, scopes});

    if (!permissionGranted && !errorProps) return <></>

    if(!permissionGranted && errorProps)
        return cloneElement(children, {...errorProps})

    return (
        <> {children}</>
    )
}
