import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import imagenes from "../../assets/imgs/imagenes";
import { Logout } from "../../sections/auth/slice/thunks";
import { CleanProjectAndTree } from "../../sections/project/slices";
import { HavePermission } from "../helpers/PermissionsHelper";
import { RolesType } from "../types/RolesType";

export const Navbar = () => {

    const dispatch = useDispatch();
    const { user, idRol } = useSelector(state => state.authorization);

    const navigate = useNavigate();

    const onLogout = () => {
        dispatch(Logout());
        navigate('/login', {
            replace: true
        });
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid">
                    <Link to="/dashboard" className="navbar-brand">
                        <img src={imagenes.img1} alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            {/* <li className="nav-item">
                                <Link to="" className="nav-link active" aria-current="page">Proyectos</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="" className="nav-link">Actividad</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="" className="nav-link">Meet</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="" className="nav-link">Equipo</Link>
                            </li> */}
                            {
                                HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) && (
                                    <li className="nav-item" onClick={() => dispatch(CleanProjectAndTree())}>
                                        <Link to="/project/new" className="nav-link button dark-1 font-14">
                                            <i className='bi bi-plus'></i>
                                            Nuevo Proyecto
                                        </Link>
                                    </li>
                                )
                            }

                        </ul>
                        <span className="navbar-text icons p-0 ms-2">
                            <Link to="" className="font-24 mx-2">
                                <i className="icon-ichead_alert"></i>
                            </Link>
                            <Link to="/useraccount" className="font-24 mx-2">
                                <i className="icon-ichead_profile"></i>
                            </Link>

                            <span className="nav-item text-primary">
                                {user?.name}
                            </span>

                            <span role="button"
                                className="icon font-24 mx-2"
                                onClick={onLogout}
                            >
                                <i className="icon-logout_ic"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </nav>
        </>
    )
}
