export const ConversationMessages = () => {
    return (
        <>
            <div className="conver-messages pt-4">
                <div className="conver-content">
                    <article className="title-line-middle grey">
                        <span>
                            <small>28 de Agosto 2022</small>
                        </span>
                    </article>
                    <div className="rows-main px-3 py-2 bg-grey-1">
                        <div>
                            <div className="user-circle sm bg-gradient-4">ME</div>
                        </div>
                        <div className="flex-fill">
                            <article>
                                <span className="fw-bold me-2">María Escalante</span>
                                <span className="color-font">28/08/22, 15:51</span>
                            </article>
                            <p className="m-0">
                                ¿Nos pueden compartir el wireframe del proyecto?
                            </p>
                        </div>
                        <div>
                            <span className="mx-1"><i className="icon-cnv-clip"></i></span>
                            <span className="mx-1"><i className="icon-cnv-mark"></i></span>
                        </div>
                    </div>
                    <div className="rows-main px-3 py-2">
                        <div>
                            <div className="user-circle sm bg-gradient-1">SP</div>
                        </div>
                        <div className="flex-fill">
                            <article>
                                <span className="fw-bold me-2">Sofía Padilla</span>
                                <span className="color-font">28/08/22, 16:03</span>
                            </article>
                            <p className="m-0">
                                Aún estamos trabajando en eso...
                            </p>
                            <div className="d-inline-block color-font bg-grey-1 p-2">
                                <span>María Escalante</span>
                                <p className="m-0">
                                    ¿Nos pueden compartir el wireframe del proyecto?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="rows-main px-3 py-2">
                        <div>
                            <div className="user-circle sm bg-gradient-3">SM</div>
                        </div>
                        <div className="flex-fill">
                            <article>
                                <span className="fw-bold me-2">Sergio Martínez</span>
                                <span className="color-font">Hoy, 14:08</span>
                            </article>
                            <p className="m-0">
                                OK!
                            </p>
                        </div>
                    </div>
                    
                    {/* ----- */}
                    <article className="title-line-middle grey">
                        <span>
                            <small>Hoy</small>
                        </span>
                    </article>
                    <div className="rows-main px-3 py-2">
                        <div>
                            <div className="user-circle sm bg-gradient-1">SP</div>
                        </div>
                        <div className="flex-fill">
                            <article>
                                <span className="fw-bold me-2">Sofía Padilla</span>
                                <span className="color-font">Hoy, 15:51</span>
                            </article>
                            <p className="m-0">
                                Buenos días, ya están listos los wireframes de la App; pueden verlos en https://xd.adobe.com/view/ad548315-7d91-4d57-80bd-e4596497ae92-f807/
                            </p>
                        </div>
                        <div>
                            <span className="mx-1"><i className="icon-mark_active d-inline-block rotate-90"></i></span>
                        </div>
                    </div>
                    <div className="rows-main px-3 py-2">
                        <div>
                            <div className="user-circle sm bg-gradient-2">SP</div>
                        </div>
                        <div className="flex-fill">
                            <article>
                                <span className="fw-bold me-2">Sergio Martínez</span>
                                <span className="color-font">Hoy, 14:08</span>
                            </article>
                            <p className="m-0">
                                Excelente noticia Sofía!
                            </p>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="conver-content">
                    <div className="px-3 py-2 mb-3">
                        <textarea className="form-control p-0 border-0" rows="1" placeholder="Escribir nuevo mensaje..."></textarea>
                    </div>
                    <div className="rows-main px-3 py-2">
                        <div className="d-flex flex-fill">
                            <div className="mx-1"><i className="icon-cnv-clip"></i></div>
                            <div className="mx-1"><i className="icon-cnv-emotion"></i></div>
                            <div className="mx-1">
                                <div className="dropdown dropdown-main arrow-none">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="icon-cnv-mencion"></i>
                                    </span>
                                    <div className="dropdown-menu">
                                        <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                            <span className="flex-fill font-14">Mencionar</span>
                                            <span><i className="icon-cerrar_icon"></i></span>
                                        </article>
                                        <hr className="" />

                                        <div className="list-main back-none px-3">
                                            <ul className="font-14">
                                                <li className="color-font">
                                                    María Escalante
                                                </li>
                                                <li className="color-font">
                                                    Sergio Martínez
                                                </li>
                                                <li className="color-font">
                                                    Sofía Padilla
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="button blue-2">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
