import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const SaveResourcesToProject = (newResources) => {
  return APIHandler.post(URLs.projects.pro_AddProjectUsers, newResources)
};


export {
    SaveResourcesToProject,
}