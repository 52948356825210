import { Tooltip } from "@mui/material";
import { useState } from "react";
import { QuestionDialog } from "../../../../ui/components/Dialogs";

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from "react-redux";
import { DeleteSection, SaveSectionWithUserStory, UpdateSection } from "../../services/sectionService";
import { HavePermission } from "../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../ui/types/RolesType";
import { DraggableHandle } from "../epics/ListTst";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { RecalculationProjectData } from "../../slices";
import { useDispatch } from "react-redux";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { MessageType } from "../../../../ui/types";

export const ProjectSectionItem = ({
    position,
    item,
    validateTree,
    CanAddSections,
    GetSections,
}) => {

    const { idRol } = useSelector((state) => state.authorization);
    const dispatch = useDispatch();
    useSelector((state) => state.sections);

    const [statusEdition, setStatusEdition] = useState(false);
    const { IdSection, Name, Description, UserStoriesSection, IdStatusSection, } = item;

    const [sectionDescription, setSectionDescription] = useState(Description);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item });

    const onRemoveItem = () => {
        QuestionDialog(`¿Esta seguro que desea eliminar ${Description}?`, Name).then((resp) => {
            if (resp.isConfirmed) {
                dispatch(RecalculationProjectData());
                DeleteSection(IdSection).then((response) => {
                    const { status } = response;

                    if (status === 200) GetSections();
                }).catch(error => {
                    const { status, data } = error.response;

                    if (status === 400) {
                        const { Message } = data;
                        dispatch(
                            setMessageError({
                                message: Message,
                                errorType: MessageType.error,
                                status: status
                            })
                        );
                    }
                });
            }
        });
    };

    const onEditItem = () => {
        if (CanAddSections) setStatusEdition(true);
    };

    const onSectionItem = ({ canUpdate = true, IdUserStory, IsSelected }) => {
        if (!canUpdate) return;

        const sectionEdit = {
            IdUserStory: IdUserStory,
            IdSection: IdSection,
            IsSelected: !IsSelected,
        };

        SaveSectionWithUserStory(sectionEdit)
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    GetSections();
                }
            })
            .catch((error) => {
                const { status, data } = error.response;

                if (status === 400) {
                    const { Message } = data;
                    dispatch(
                        setMessageError({
                            message: Message,
                            errorType: MessageType.error,
                            status: status
                        })
                    );
                }
            });
    };

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (sectionDescription !== "") {
                UpdateSection(IdSection, {
                    Name: Name,
                    Description: sectionDescription,
                    Orden: position,
                    IdStatusSection: IdStatusSection,
                })
                    .then((response) => {
                        const { status } = response;

                        if (status === 200) {
                            setStatusEdition(false);
                            GetSections();
                        }
                    })
                    .catch((error) => console.log(error));
            }
        } else if (e.key === "Escape") {
            setSectionDescription(Description);
            setStatusEdition(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            setSectionDescription(Description);
            setStatusEdition(false);
        }
    };


    const onBlurInput = () => {
        setStatusEdition(false);
    };

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <>
            <div className="plans-items d-flex" ref={setNodeRef} style={style} {...attributes}>
                <span className="icon flex-none mt-2" >
                    <PermissionGate module={ModuleType.Sections} scopes={[SCOPES.CanUpdate]} errorProps={{ handleListeners: {} }}>
                        <DraggableHandle handleListeners={listeners} />
                    </PermissionGate>
                </span>
                <div className={validateTree ? (UserStoriesSection?.filter(item => item.IsSelected).length >= 1 ? "plan-item flex-fill" : "plan-item flex-fill border-danger animate__zoomIn") : "plan-item flex-fill"}
                    onBlur={onBlurInput}
                    onKeyDown={handleKeyDown}>
                    <div className="names">
                        <span className="plan-tit color-font-dark flex-none">
                            {Name}
                        </span>
                        {
                            statusEdition ? (
                                <PermissionGate module={ModuleType.Sections} scopes={[SCOPES.CanUpdate]} >
                                    <ControllerInput
                                        value={sectionDescription}
                                        onChange={({ target: { value } }) => setSectionDescription(value)}
                                        onKeyDown={onKeyPress}
                                        className={"form-control"}
                                    />
                                </PermissionGate>
                                // <input
                                //     ref={(ref) => ref && ref.focus()}
                                //     className="form-control"
                                //     type="text"
                                //     value={sectionDescription}
                                //     onChange={({ target: { value } }) => {
                                //         setSectionDescription(value);
                                //     }}
                                //     onKeyDown={onKeyPress}
                                //     onFocus={(e) =>
                                //         e.currentTarget.setSelectionRange(
                                //             e.currentTarget.value.length,
                                //             e.currentTarget.value.length
                                //         )
                                //     }
                                // />
                            ) : (
                                <>
                                    <div className="d-flex flex-fill">
                                        <PermissionGate module={ModuleType.Sections} scopes={[SCOPES.CanUpdate]} errorProps={{ title: '' }}>
                                            <Tooltip title={HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) ? "Editar Sección" : ""}>
                                                <span
                                                    className="plan-name flex-fill"
                                                    onDoubleClick={onEditItem}
                                                >
                                                    {Description}
                                                </span>
                                            </Tooltip>
                                        </PermissionGate>
                                    </div>
                                    {
                                        <PermissionGate module={ModuleType.Sections} scopes={[SCOPES.CanDelete]} errorProps={{ hidden: !CanAddSections }}>
                                            <span
                                                className="font-18"
                                                onClick={onRemoveItem}
                                            >
                                                <i className="icon-rc_btnn-quitar"></i>
                                            </span>
                                        </PermissionGate>
                                    }

                                </>
                            )}
                    </div>
                    <div >
                        <div className="sub-names">
                            <small className="plan-tit color-font font-12 flex-none">
                                Enlazar US:
                            </small>
                            <div className="plan-badges-list flex-fill">
                                {UserStoriesSection?.map((item) => (
                                    <PermissionGate key={item.IdUserStory} module={ModuleType.Sections} scopes={[SCOPES.CanUpdate]} errorProps={{ onClick: onSectionItem({ canUpdate: false }) }}>
                                        <span
                                            className={item.IsSelected ? "badges back-dark" : "badges border-color-dark"}
                                            onClick={() => onSectionItem(item)}
                                        >
                                            {item.UserStoryShortName}
                                        </span>
                                    </PermissionGate>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
