import { Skeleton } from '@mui/material';
import { Fragment, useState } from 'react';
import { SkeletonResourceItem } from '../../../../ui/components/Skeletons/SkeletonResourceItem';
import { HavePermission } from '../../../../ui/helpers/PermissionsHelper';
import { SectionsType } from '../../../../ui/types';
import { RolesType } from '../../../../ui/types/RolesType';
import { useValidationState } from '../../hooks/useValidationState';
import { EstimateTabs } from '../shared/Project/Tabs/EstimateTabs';
import { ProjectResourceItemAdded } from './ProjectResourceItemAdded';
import { PermissionGate } from '../../../../ui/components/PermissionGate/PermissionGate';
import { ModuleType } from '../../../../ui/types/ModuleType';
import { SCOPES } from '../../../../ui/types/ScopesType';

export const ProjectResourceDetailsAdded = ({ ...rest }) => {
    const { UsersAdded, section, idRol, setIsAddResources, StartDate, PlannedEndDate, goals, planning, planningsprint, sprint } = rest;

    const { isLoadingComponents } = useValidationState(section)
    const [tabSelected, setTabSelected] = useState((section === SectionsType.ProjectInHold) ? 1 : section === SectionsType.ProjectInSprint ? 1 : 0);


    return (
        <>
            <EstimateTabs goals={goals} section={section} StartDate={StartDate} PlannedEndDate={PlannedEndDate} planning={planning} planningsprint={planningsprint} sprint={sprint} setTabSelected={setTabSelected} />

            <div className="d-flex mb-2">
                {
                    isLoadingComponents ? (<span className="flex-fill font-20 me-2"><Skeleton sx={{ bgcolor: '#3E3E3E' }} /></span>) : (
                        <span className="flex-fill font-20 me-2">Recursos</span>
                    )
                }
                {
                    isLoadingComponents ? (<Skeleton sx={{ bgcolor: '#3E3E3E' }} width={25} />) : (
                        <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]}>
                            <span className="font-20" onClick={() => setIsAddResources(true)}>
                                <i className="icon-ic_editar"></i>
                            </span>
                        </PermissionGate>

                    )
                }
            </div >

            <div className="overflowresources sm">
                <div className="list-main borders-bottom">
                    <ul>
                        {(isLoadingComponents ? Array.from(new Array(4)) : UsersAdded)?.map((resource, index) => (
                            <Fragment key={index}>
                                {
                                    (resource ? <ProjectResourceItemAdded key={resource.Id} {...resource} {...rest} showName={resource.Name} tabSelected={tabSelected} /> : <SkeletonResourceItem />)
                                }
                            </Fragment>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
