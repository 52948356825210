import { StatusActivitiesType } from "../types";

export const getActivitiesBySelectedStatus = (activities, status) => {
  return activities.filter((act) => act.IsSelectedForSprint === status);
};

export const getActivitiesBySelectedAndNotFinished = (activities, status) => {
  return activities.filter(
    (act) =>
      act.IsSelectedForSprint === status &&
      act.IdStatusActivity !== parseInt(StatusActivitiesType.Finalizada)
  );
};

export const getActivitiesByStatusActivity = (activities, statusActivity) => {
  return activities.filter(
    (act) => !act.IsSelectedForSprint && act.IdStatusActivity === statusActivity
  );
};

export const getTaskById = (activities, id) => {
  return activities.find((act) => act.IdActivity === id);
};
