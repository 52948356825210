import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import imagenes from "../../../assets/imgs/imagenes";
import { SectionsType } from "../../../ui/types";
import { useValidationState } from "../../project/hooks/useValidationState";
import { CleanProjectAndTree } from "../../project/slices";
import { ProjectList } from "./ProjectList";
import { PermissionGate } from "../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../ui/types/ModuleType";
import { SCOPES } from "../../../ui/types/ScopesType";

export const Projects = () => {
	const dispatch = useDispatch();
	const { isLoadingComponents } = useValidationState(SectionsType.Dashboard)

	return (
		<>
			<div className="col-lg-9 col-xxxl-10 mb-3">
				<div className="d-flex align-items-center gap-10">
					<div className="flex-fill">
						<div className="logo font-24 fw-bold">
							{isLoadingComponents ? <Skeleton sx={{ bgcolor: '#3E3E3E' }} width={200} height={100} /> : <img src={imagenes.img1} alt="" />}
						</div>
					</div>
					{
						isLoadingComponents ? (
							<Skeleton sx={{ bgcolor: '#3E3E3E' }} width={150} height={100} />
						) : (
							<div className="buttons" onClick={() => dispatch(CleanProjectAndTree())}>
								<Link to="/project/new">
									<PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanCreate]}>
										<button className="button large dark">
											+ Nuevo Proyecto
										</button>
									</PermissionGate>
								</Link>
							</div>
						)

					}
				</div>
				<ProjectList />
			</div>
		</>
	);
};
