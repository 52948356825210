import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Suspense } from 'react';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { HavePermission } from '../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../ui/types/RolesType';
import { setEpicSelected, UpdateEpicsOrden } from '../../slices';
import { SkeletonEpicItem } from '../skeletons/SkeletonEpicItem';
import { ProjectEpicItem } from './ProjectEpicItem';

export const DroppableEpics = ({ ...rest }) => {
    const [records, setRecords] = useState([]);

    const dispatch = useDispatch();
    const { UsersAdded } = useSelector(state => state.project);
    const { idRol } = useSelector((state) => state.authorization);

    const { epics } = rest;


    useEffect(() => {
        setRecords(epics);
    }, [epics])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setRecords((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                const newOrderItems = arrayMove(items, oldIndex, newIndex);
                dispatch(UpdateEpicsOrden(newOrderItems, items));
                return newOrderItems;
            });
        }

    }

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;
        const { IdEpic } = id;

        dispatch(setEpicSelected(IdEpic));
    }


    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            handle
        >
            <SortableContext
                items={records}
                strategy={verticalListSortingStrategy}
            >
                {
                    epics.map((epic, index) => (
                        <ProjectEpicItem
                            key={epic.IdEpic}
                            item={epic}
                            position={index}
                            canAddEpics={(UsersAdded?.length >= 1)}
                        />
                    ))
                }
            </SortableContext>
        </DndContext>
    )
}
