import { Skeleton } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useValidationState } from '../../../hooks/useValidationState'
import { RecalculationData } from '../../../slices'
import { FilterComponent } from '../Filter/FilterComponent'
import { MenuFilter } from '../Filter/MenuFilter'

export const HeaderTitleWithOptions = ({ section }) => {
    const dispatch = useDispatch();
    
    const { isLoadingComponents } = useValidationState(section)

    return (
        <>
            {
                isLoadingComponents ? (
                    <div className="new-project-body-top mb-4">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                                    <article className="font-18 mb-2">
                                        <Skeleton />
                                    </article>
                                    <span>
                                        <Skeleton />
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex gap-10">
                                    <span className="flex-fill">
                                        <Skeleton />
                                    </span>
                                    <Skeleton className="button grey-light font-20" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="new-project-body-top mb-4">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                                    <article className="font-18 mb-2">
                                        Planeación de proyecto
                                    </article>
                                    <span>
                                        Define el alcance y estructura
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex gap-10">
                                    <span className="flex-fill">
                                        <input type="text" className="form-control" placeholder="Buscar" />
                                    </span>
                                    <FilterComponent />
                                    {/* <MenuFilter /> */}
                                    <div className="dropdown dropdown-main arrow-none" onClick={() => dispatch(RecalculationData(true))}>
                                    <span className="button d-flex align-items-center grey-light font-20 px-3" type="button">
                                        <span><i className="bi bi-arrow-repeat"></i></span>
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}
