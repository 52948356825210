import logotipo from './logotipo.svg';
import logotipo_bco from './logotipo_bco.svg';
import imgPassword from './img-password.svg';
import imgAvatar from './img_avatar.png';
import imgDeliveries from './img-deliveries.png'

const XScrumImgs = {
    "img1": logotipo,
    "img2": logotipo_bco,
    "imgPass": imgPassword,
    "imgAvatar": imgAvatar,
    "imgDeliveries": imgDeliveries
}

export default XScrumImgs;