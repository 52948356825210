import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { QuestionDialog } from '../../../../ui/components/Dialogs';
import { ResourceGridInformationItem } from '../../../../ui/components/Resources/ResourceGridInformationItem';
import { ResourceInformationItem } from '../../../../ui/components/Resources/ResourceInformationItem';
import { ResourceItemBasic } from '../../../../ui/components/Resources/ResourceItemBasic';
import { GoalsType } from '../../../../ui/types/GoalsType';
import { DeleteProjectUsers } from '../../services/projectService';
import { GetProject } from '../../slices';

export const ProjectResourceItemAdded = ({...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { UsersAdded, IdProject, goals } = rest;

  const onDeleteItem = () => {

    if (UsersAdded.length === 1)
      enqueueSnackbar('No es posible eliminar el recurso, se requiere por lo menos uno asigando al proyecto.', { variant: 'info', preventDuplicate: true, autoHideDuration: 4000 });
    else
      QuestionDialog("¿Desea eliminar el recurso del proyecto?", "Recursos").then((result) => {
        if (result.isConfirmed) {

          DeleteProjectUsers({ IdProject: IdProject, IdUser: rest.Id }).then(response => {

            const { status } = response;

            if (status === 200)
              enqueueSnackbar('Se ha eliminado correctamente el recurso.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });

            dispatch(GetProject(IdProject, false, true));
          })
        }
      });
  }

  return (goals === GoalsType.None ? <ResourceItemBasic {...rest} onActionHandler={onDeleteItem} /> : <ResourceGridInformationItem {...rest} onActionHandler={onDeleteItem} />)
}
