import { Skeleton } from "@mui/material";
import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HavePermission } from "../../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../../ui/types/RolesType";
import { useValidationState } from "../../../hooks/useValidationState";
import { onUpdateProjectStatus } from "../../../services/projectService";
import { GetEpics, GetEpicsByProject, GetProject, UpdateUrl } from "../../../slices";
import { projectStatusType } from "../../../types/projectStatusType";
import { SkeletonDetailItem } from "../../skeletons/SkeletonDetailItem";
import { ProjectEpicDetailsItem } from "./ProjectEpicDetailsItem"
import { PermissionGate } from "../../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../../ui/types/ScopesType";

export const ProjectEpicDetails = ({ section }) => {

    const dispatch = useDispatch();

    const { IdProject } = useSelector(state => state.project);
    const { epics } = useSelector(state => state.epics);
    const { idRol } = useSelector((state) => state.authorization);
    const { hasActivitiesChanged } = useSelector((state) => state.activities);

    const { isLoadingComponents } = useValidationState(section)

    console.log(isLoadingComponents, section, epics);

    // Load Epics
    useEffect(() => {
        if (IdProject !== 0) {
            dispatch(GetEpicsByProject(false, true));
        }
    }, [dispatch, IdProject])

    useEffect(() => {
        console.log(hasActivitiesChanged);

        if (hasActivitiesChanged) {
            dispatch(GetEpics());
        }
    }, [hasActivitiesChanged, dispatch])

    const onBackPlanning = () => {
        onUpdateProjectStatus(IdProject, projectStatusType.IN_PLANNING).then(() => {
            //navigate(`/project/${IdProject}/planning/definitions`)
            dispatch(UpdateUrl(`/project/${IdProject}/planning/definitions`))
            dispatch(GetProject(IdProject, false))
        });
    }

    return (
        <>
            <div className="col-md flex-xl-grow-0">
                <article className="color-dark font-16 mb-3">
                    Epicas
                </article>

                <div className="add-plans-items">
                    {
                        (isLoadingComponents ? Array.from(new Array(5)) : epics).map((epic, index) => (
                            <Fragment key={index}>
                                {
                                    epic ? (
                                        <ProjectEpicDetailsItem key={epic.IdEpic} {...epic} index={index} />
                                    ) : (
                                        <SkeletonDetailItem />
                                    )
                                }
                            </Fragment>
                        ))

                        // epics.map(item => (
                        //     <ProjectEpicDetailsItem key={item.IdEpic} {...item} />
                        // ))
                    }

                    <div className="add-item">
                        {
                            isLoadingComponents ? (
                                <Skeleton sx={{ bgcolor: '#3E3E3E' }} >
                                    <button disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                                        <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                                    </button>
                                </Skeleton>
                            ) : (
                                <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]}>
                                    <button  className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                                        <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                                    </button>
                                </PermissionGate>
                            )
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
