import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { HavePermission } from '../../../../../ui/helpers';
import { onlyNumber } from '../../../../../ui/helpers/NumberHelper';
import { RolesType } from '../../../../../ui/types';
import { UpdateActivity, UpdateActivityNew } from '../../../slices/Activities/ActivitiesTrunk';

export const DropdownPointsCell = ({ value: initPoints, row, canEditBySection }) => {
    const dispatch = useDispatch();

    const { original } = row;
    const { idRol } = useSelector((state) => state.authorization);
    const { IdUser, IdActivity } = original;


    const optionsNumber = [{ value: 0, label: '0' }, { value: 1, label: '1' }, { value: 3, label: '3' }, { value: 5, label: '5' }, { value: 8, label: '8' }, { value: 13, label: '13' },];

    const [points, setPoints] = useState(optionsNumber.find(item => item.value === initPoints) !== undefined ? optionsNumber.find(item => item.value === initPoints) : optionsNumber[0]);

    const onChangeHandler = ({ value }) => {
        // const ActivityEdited = {
        //     IdStatusActivity: IdStatusActivity,
        //     IdUser: IdUser,
        //     Name: Name,
        //     Description: Description,
        //     Points: value,
        //     Priority: Priority,
        //     Orden: Orden
        // };

        //  onUpdateActivity(IdActivity, ActivityEdited).then(() => {
        //     dispatch(GetActivitiesByUpdated());
        // }).catch(error => console.log(error));

        dispatch(UpdateActivityNew({ ...original, Points: value, showMessage: false }));
    }

    const onSaveAction = () => {
        // const ActivityEdited = {
        //     IdStatusActivity: IdStatusActivity,
        //     IdUser: IdUser,
        //     Name: Name,
        //     Description: Description,
        //     Points: 10,
        //     Priority: Priority,
        //     Orden: Orden
        // };

        // UpdateActivity(IdActivity, ActivityEdited).then(() => {
        //     dispatch(GetActivities(true));
        // }).catch(error => console.log(error));
    }

    const onKeyHandler = (e) => {
        const { keyCode } = e;
        // console.log(keyCode);
        // console.log(onlyNumber(keyCode));
        if (!onlyNumber(keyCode)) {
            e.preventDefault();
            return false;
        }
    }

    useEffect(() => {
        setPoints(initPoints)
    }, [initPoints])

    const PaintBorderByNoPoint = () => {
        if (initPoints === 0 && IdUser !== 0 && IdActivity !== 0) {
            return 'border-danger';
        }
        return '';
    }



    return (
        <Select
            className={`react-select-container ${PaintBorderByNoPoint()}`}
            placeholder=''
            noOptionsMessage={() => 'Puntaje no válido'}
            isSearchable={true}
            options={optionsNumber}
            instanceId={'number'}
            isDisabled={!((HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource]) && original.IdActivity !== 0) && canEditBySection)}
            onChange={onChangeHandler}
            onKeyDown={onKeyHandler}
            defaultValue={points}
        />
    )
}
