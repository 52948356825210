import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSortBy, useTable } from 'react-table';
import { AvisoEmpty } from '../../shared/AvisoEmpty';
import { EditableNumberCell, EditableTextCell, ExtraTaskCell, MenuResources, MenuStatusCell, TableGeneric } from '../../shared/Tables';
import { DropdownPointsCell } from '../../shared/Tables/DropdownPointsCell';
import { Board } from '../Board/Board';

export const TableActivitiesInSprint = ({ onDeleteActivity }) => {

  const [records, setRecords] = useState([]);
  const { activities, canAddActivities } = useSelector((state) => state.activities);
  const { sectionSelected } = useSelector((state) => state.sections);


  const columns = useMemo(() => [
    {
      accessor: 'IdActivity',
      Cell: (cell) => <ExtraTaskCell {...cell} />
    },
    {
      Header: 'IdSection',
      accessor: 'IdSection',
    },
    {
      Header: 'Tarea(s)',
      accessor: 'Name',
      style: 'text-start w-75',
      Cell: (cell) => <EditableTextCell {...cell} />
    },
    {
      Header: 'Puntos',
      accessor: 'Points',
      Cell: (cell) => <DropdownPointsCell {...cell} />
    },
    {
      Header: 'Recurso',
      accessor: 'IdUser',
      Cell: (cell) => <MenuResources canShowMenu={true} {...cell} />

    },
    {
      Header: 'Estado',
      accessor: 'IdStatusActivity',
      Cell: (cell) => <MenuStatusCell
        canShowMenu={true}
        {...cell} />

    },
    {
      Header: 'Prioridad',
      accessor: 'Priority',
      Cell: (cell) => <EditableNumberCell {...cell} />
    },
    {
      Header: 'Orden',
      accessor: 'Orden',
    },
  ], []);

  const initialState = {
    hiddenColumns: ['IdSection', 'Orden']
  }

  const getRowId = useCallback(row => {
    return row.IdActivity
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: records, initialState, getRowId }, useSortBy);

  useEffect(() => {
    setRecords(activities);
  }, [activities])

  return (
    <Board IsSprint={true} />

    // <TableGeneric
    //   IsInSrint={true}
    //   IdSection={sectionSelected}
    //   setRecords={setRecords}
    //   records={records}
    //   getTableProps={getTableProps}
    //   getTableBodyProps={getTableBodyProps}
    //   headerGroups={headerGroups}
    //   rows={rows}
    //   prepareRow={prepareRow}
    // />
  )
}
