import { Tooltip } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { ChangeSectionSelected, setSectionSelected } from '../../../slices';
import { setAddActivities } from '../../../slices/Activities/ActivitiesSlice';
import { CancelOperation } from '../../../slices/Activities/ActivitiesTrunk';
import { projectStatusType } from '../../../types/projectStatusType';

export const ProjectSectionDetailsItem = ({ ...rest }) => {
    const dispatch = useDispatch();

    const { IdProjectStatus } = useSelector((state) => state.project);
    const { sectionSelected, sectionswithwarnings } = useSelector(state => state.sections);
    const { userstories, userstorySelected, checkSectionRelated } = useSelector(state => state.userstories);
    const { activities } = useSelector((state) => state.activities);

    const [record, setRecord] = useState(0);
    const [isRelational, setIsRelational] = useState(false);

    const { IdSection, Name, Description, CurrentPoints } = rest;

    useEffect(() => {
        // if (sectionSelected !== 0 && userstories.length >= 1) {

        //     const related = userstories?.find(item => item.IdUserStory === userstorySelected)

        //     if (related) {
        //         const { SectionsRelated } = userstories?.find(item => item.IdUserStory === userstorySelected)

        //         if (SectionsRelated && SectionsRelated.length === 0) { dispatch(setAddActivities(false)) }

        //         console.log(SectionsRelated);
        //         if (SectionsRelated?.find(item => item.IdSection === IdSection) !== undefined) {
        //             setIsRelational(true);
        //         }
        //         else setIsRelational(false);
        //     } else {
        //         setIsRelational(false);
        //     }
        // }
        // else {
        //     setIsRelational(false);
        // }

        // if(UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory) !== undefined){
        //     const { IsSelected } = UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory);
        //     setIsRelational(IsSelected);
        //   }
        // } else{
        //   setIsRelational(false);
        // }


        //   if(UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory) !== undefined){
        //     const { IsSelected } = UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory);
        //     setIsRelational(IsSelected);
        //   }
    
    }, [sectionSelected, userstorySelected, userstories, IdSection, dispatch])

    // const GetPointByStatus = () => {
    //     switch (IdProjectStatus) {
    //         case projectStatusType.IN_PLANNING:
    //         case projectStatusType.IN_ACTIVITY_DEFINITION:
    //             return PlanningPoints;
    //         case projectStatusType.IN_HOLD:
    //             return PlanningSprintPoints;
    //         default:
    //             return '0.0.0'
    //     }
    // }

    useEffect(() => {
        setRecord(sectionSelected);
    }, [sectionSelected])

    const CheckValidSection = () => {
        return sectionswithwarnings?.filter(sec => sec.IdSection === IdSection)?.length >= 1 ? 'border-danger' : '';
    }

    const onClickHandler = () => {
        if(activities?.filter(act => act.IdActivity === 0).length > 0){
            QuestionDialog('Hay actividades con cambios pendientes de guardar, ¿Desea descartar los cambios?', 'Actividades').then(result => {
                if(result.isConfirmed){
                    dispatch(CancelOperation());
                    dispatch(ChangeSectionSelected(IdSection));
                }
            });
        }else{
            dispatch(ChangeSectionSelected(IdSection));
        }
    }

return (
    <>
        <div className="plans-items">
            <Tooltip title={Description} placement="right" arrow>
                <div className={`plan-item ${IdSection === record ? "plan-item selected" : isRelational ? " selected-relation" : "plan-item"} ${CheckValidSection()}`}
                    onClick={onClickHandler}>
                    <div className="names">
                        <span className=""><i className='bi bi-clock'></i></span>
                        <span className="plan-tit text-nowrap w-auto">
                            {Name}
                            <br />
                            <small className="d-none d-xl-inline-block">{ CurrentPoints } p</small>
                        </span>
                        <span className="plan-name flex-fill d-inline-block d-xl-none">
                            {Description}
                        </span>
                    </div>
                </div>
            </Tooltip>
        </div>
    </>
)
}
