import { useSortBy, useTable } from 'react-table'
import { useCallback, useState, useEffect } from 'react';
import { TableGeneric } from '../../shared/Tables/TableGeneric';
import { useMemo } from 'react';
import { EditableNumberCell } from '../../shared/Tables/EditableNumberCell';
import { EditableTextCell } from '../../shared/Tables/EditableTextCell';
import { MenuResources } from '../../shared/Tables/MenuResources';
import { useSelector } from 'react-redux';
import { HasLoadingAllWithActivities } from '../../../../../ui/helpers';
import { DropdownPointsCell } from '../../shared/Tables/DropdownPointsCell';
import { Board } from '../Board/Board';

export const TableActivities = () => {
    const [records, setRecords] = useState([]);

    const { activities } = useSelector((state) => state.activities);

    const columns = useMemo(() => [
        {
            Header: 'IdActivity',
            accessor: 'IdActivity',
        },
        {
            Header: 'IdSection',
            accessor: 'IdSection',
        },
        {
            Header: 'IdStatusActivity',
            accessor: 'IdStatusActivity',
        },
        {
            Header: 'Tarea(s)',
            accessor: 'Name',
            style: 'text-start w-75',
            Cell: (cell) => <EditableTextCell {...cell} />
        },
        {
            Header: 'Recurso',
            accessor: 'IdUser',
            Cell: (cell) => <MenuResources canShowMenu={true} {...cell} />

        },
        {
            Header: 'Puntos',
            accessor: 'Points',
            Cell: (cell) => <DropdownPointsCell {...cell} />
        },
        {
            Header: 'Prioridad',
            accessor: 'Priority',
            Cell: (cell) => <EditableNumberCell {...cell} />
        },
        {
            Header: 'Orden',
            accessor: 'Orden',
        },
    ], []);

    const initialState = {
        hiddenColumns: ['IdActivity', 'IdSection', 'IdStatusActivity', 'Orden']
    }

    useEffect(() => {
        setRecords(activities);
    }, [activities])

    const getRowId = useCallback(row => {
        return row.IdActivity
    }, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable({ columns, data: records, initialState, getRowId }, useSortBy);

    console.log(state);
    return (
        <Board IsSprint={false} />
        // < TableGeneric
        //     setRecords={setRecords}
        //     records={records}
        //     isLoadingReady={HasLoadingAllWithActivities()}
        //     getTableProps={getTableProps}
        //     getTableBodyProps={getTableBodyProps}
        //     headerGroups={headerGroups}
        //     rows={rows}
        //     prepareRow={prepareRow}
        // />
    )
}