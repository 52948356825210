import Swal from "sweetalert2";

export const ErrorDialog = (msg, title = 'Error') => {
    return (
        Swal.fire({  
          icon: 'error',  
          title: `${title}`,  
          text: msg,  
          // footer: '<i>Exagono Software - 2022</i>'  
        })
      );
}

export const SuccessDialog = (msg, title = 'Error') => {
  return (
      Swal.fire({  
        title: `¡${title}!`,
        allowOutsideClick : false,
        icon: 'success',  
        text: msg,  
        // footer: '<i>Exagono Software - 2022</i>'  
      })
    );
}

export const QuestionDialog = (texto, title) => {
  return Swal.fire({
    allowOutsideClick : false,
    title: title,
    text : texto,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar',
  })
}
