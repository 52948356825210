import { Skeleton } from "@mui/material";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { QuestionDialog, SuccessDialog } from "../../../ui/components/Dialogs";
import { ResourceItem } from "../../../ui/components/Resources/ResourceItem";
import { ResourceTitle } from "../../../ui/components/Resources/ResourceTitle";
import { SkeletonResourceItem } from "../../../ui/components/Skeletons/SkeletonResourceItem";
import { HavePermission } from "../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../ui/types/RolesType";
import { useFetchUsers } from "../hooks/useFetchUsers";
import { deleteResource } from "../services/userService";
import { useValidationState } from "../../project/hooks/useValidationState";
import { SectionsType } from "../../../ui/types";
import { PermissionGate } from "../../../ui/components/PermissionGate/PermissionGate";
import { SCOPES } from "../../../ui/types/ScopesType";
import { ModuleType } from "../../../ui/types/ModuleType";

export const ResourceList = ({ ...rest }) => {

  const { isLoadingComponents } = useValidationState(SectionsType.Dashboard)

  const { setIsNewResource } = rest;
  const { IsLoading, users, setUsers } = useFetchUsers();

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const onClick = (e) => {
    setIsNewResource(true);
  };

  const onDeleteItem = (id) => {
    QuestionDialog("¿Desea eliminar el recurso de la plataforma?", "Recursos").then((result) => {
      if (result.isConfirmed) {
        deleteResource(id).then(response => {
          const {
            data: { Message },
          } = response;

          SuccessDialog(Message, "Eliminar Recurso").then(() => {
            const usersNew = users.filter((i) => i.Id !== id);
            setUsers(usersNew);
          });
        }).catch(error => {
          console.log(error);
        });
      }
    });
  };


  const searchResources = ({ target: { value } }) => {
    setSearchInput(value);
    if (searchInput !== "") {
      const filteredData = users.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(users);
    }
  };

  return (
    <>
      <ResourceTitle isLoading={isLoadingComponents} totalItems={searchInput.length ? filteredResults.length : users.length} />

      <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]}>
        <Link onClick={onClick} to="" className="color-grey-font">
          {
            isLoadingComponents ? <Skeleton sx={{ bgcolor: '#3E3E3E' }} /> : "+ Agregar recurso"
          }
        </Link>
      </PermissionGate>

      {
        isLoadingComponents ? (
          <Skeleton className="form-control my-3" sx={{ bgcolor: '#3E3E3E' }} variant="rectangular" height={40} />
        ) : (
          <input
            className="form-control my-3"
            type="text"
            placeholder="Buscar"
            name="searchText"
            autoComplete="off"
            onChange={searchResources}
          />
        )
      }
      <div className="dashboard-overflow-list-resources pe-1">
        <div className="list-main borders-bottom">
          <ul>
            {
              searchInput.length > 1
                ? filteredResults.map((user, index) => (
                  <ResourceItem
                    key={user.Id}
                    {...user}
                    showName={user.Name}
                    onActionHandler={onDeleteItem}
                  />
                ))
                :
                (IsLoading ? Array.from(new Array(5)) : users).map((user, index) => (
                  <Fragment key={index}>
                    {
                      user ? (
                        <ResourceItem
                          key={user.Id}
                          {...user}
                          showName={user.Name}
                          onActionHandler={onDeleteItem} />
                      ) : <SkeletonResourceItem />
                    }
                  </Fragment>
                ))
            }
          </ul>
        </div>
      </div>
    </>
  );
};
