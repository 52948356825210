import { Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonEpicItem = () => {
    return (
        <div className="plans-items d-flex">
            <span className="icon flex-none mt-2" >
                <i className="bi bi-three-dots-vertical"></i>
            </span>
            <div className="plan-item flex-fill">


                <div className="names">
                    <div className="d-flex flex-fill" >
                        <span className="plan-tit color-font-dark flex-none">
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </span>
                        <span className="plan-name flex-fill">
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
