import { useState } from "react";
import { ProjectEpicNew } from "./ProjectEpicNew";
import { useEffect } from "react";
import { DroppableEpics } from "./DroppableEpics";
import { useDispatch, useSelector } from "react-redux";
import { GetEpicsByProject } from "../../slices/Epics/EpicsTrunk";
import { cleanEpics } from "../../slices";
import { usePermission } from "../../../../ui/hooks/usePermission";
import { useEffectOnce } from "usehooks-ts";
import Mousetrap from "mousetrap";
import { shortcutKeys } from "../../../../ui/constants/shortcutKeysConfig";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { DraggingButtonHandle } from "../shared/DraggingButtonHandle";

export const ProjectEpic = ({ section }) => {

  const dispatch = useDispatch();
  const { IdProject, UsersAdded } = useSelector(state => state.project);
  const { epics, totalEpics } = useSelector(state => state.epics);
  const { roleCanCreate } = usePermission(ModuleType.Epics);
  const [isNewEpic, setIsNewEpic] = useState(false);

  useEffect(() => {
    if (IdProject && IdProject !== 0)
      dispatch(GetEpicsByProject(false, true));
    else
      dispatch(cleanEpics());

  }, [IdProject, dispatch]);


  const onCreateEpic = () => setIsNewEpic(true);
  const onCancelNewEpic = () => setIsNewEpic(false);
  const canEnable = () => UsersAdded?.length >= 1 && roleCanCreate;

  useEffect(() => {
    console.log(canEnable());
    Mousetrap.bind(shortcutKeys.newEpic, (e) => {
      if (canEnable()) {
        setIsNewEpic(true);
        e.preventDefault();
      }
    });
  }, [UsersAdded]);

  return (
    <>
      <div className="col-md-4">
        <article className="color-dark font-16 mb-3 ms-4">
          Epicas de Proyecto
        </article>
        <div className="add-plans-items">
          {
            !isNewEpic && (
              <div className="add-item">
                <span className="icon">
                  <DraggingButtonHandle hasPointer={false} />
                </span>
                <PermissionGate module={ModuleType.Epics} scopes={[SCOPES.CanCreate]} errorProps={{ disabled: true }}>
                  <button
                    className="button border-grey-dot flex-fill"
                    onClick={onCreateEpic}
                  >
                    <span className="me-2">
                      <i className="bi bi-plus"></i>
                    </span>
                    <span>Agregar</span>
                  </button>
                </PermissionGate>
              </div>
            )
          }

          {
            isNewEpic && (
              <ProjectEpicNew
                EpicNum={totalEpics}
                onCancelNewEpic={onCancelNewEpic}
              />
            )}

          <DroppableEpics section={section} epics={epics} />
        </div>
      </div>
    </>
  );
}
