import React from 'react'

export const HistoryListItems = () => {
    return (
        <>
            <div className="history-list">
                <ul>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-ic_exito"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            Sofía Padilla ha finalizado Descripción de actividad
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-ic_exito"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            María Escalante agregó 3 actividades extras
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-rc_btnn-quitar"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            María Escalante canceló Descripción de actividad
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-meet-default"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            Reunión finalizada 01:06:45
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-meet-default"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            Sergio Martínez ha creado una reunión
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                    <li className="color-font lh-1">
                        <div>
                            <span className="font-18">
                                <i className="icon-ic_exito"></i>
                            </span>
                        </div>
                        <div className="flex-fill">
                            Sergio Martínez ha iniciado el sprint 2
                        </div>
                        <div className='flex-none'>
                            28 Feb 2022
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
