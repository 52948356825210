import { Link } from "react-router-dom";
import imagenes from "../../../assets/imgs/imagenes";

export const LoginCreatePassPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="row justify-content-center pt-md-5">
                    <div className="col-md-5 col-xl-2">
                        <div className="d-flex align-items-center h-100">
                            <span className="image-login-pass mx-auto mb-3 mb-xl-0">
                                <img src={imagenes.imgPass} alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <hr className="vert" />
                    </div>
                    <div className="col-md-5 col-xl-3">
                        <div className="login-wrap login-change-pass py-4 m-auto">
                            <div className="logo mb-4">
                                <img src={imagenes.img1} alt="" />
                            </div>
                            
                            <article className="font-24 fw-bold mb-3">
                                Te damos la bienvenida
                            </article>
                            <p>
                                Por favor crea una contraseña personal para mantener la seguridad de tu cuenta.
                            </p>

                            <div className="mb-3">
                                <label className="form-label">Crear contraseña</label>
                                <input type="password" className="form-control" />
                            </div>
                            <div className="mb-2">
                                <label className="form-label">Repetir contraseña</label>
                                <input type="password" className="form-control" />
                            </div>
                            <p className="d-flex">
                                <input className="form-check-input me-2" type="checkbox" value="" id="checkChangePass" />
                                <label className="form-check-label" for="checkChangePass">
                                    Estoy de acuerdo con los 
                                    <Link to="" className="color-blue-2 ms-1">Términos y condiciones</Link>
                                </label>
                            </p>

                            <button className="button dark w-100 mt-4">Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
