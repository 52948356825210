import { Tooltip } from "@mui/material"

export const ActivityEpicItem = () => {
    return (
        <>
            <div className="plans-items">
                <Tooltip title="Planeación" placement="top" arrow>
                    <div className="plan-item selected">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 1</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Planeación</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Maquetación" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 2</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Maquetación</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Funcionalidad" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 3</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Demo QA</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Desarrollo" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 4</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Demo QA</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Demo QA" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 5</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Demo QA</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Producción" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 6</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Correciones generales</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Producción" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 7</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Revision</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Producción" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 8</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Produccion</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Producción" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit color-font-dark flex-none w-auto">EP 9</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">Produccion</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            
            <div className="add-item">
                <button className='button border-grey-dot flex-fill'>
                    <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                    {/* <span>Agregar</span> */}
                </button>
            </div>
        </>
    )
}
