import { Link } from "react-router-dom";

export const NavReportsLinks = () => {
  return (
    <>
      <div className="new-project-top d-flex flex-column flex-md-row d-lg-block gap-3">
        <div className="d-flex d-lg-block d-xl-flex gap-3 w-100">
          <button className="button large grey-light w-100 p-2 mb-0 mb-lg-3">
            <span className="flex-fill text-start">Entregas</span>
            <span>
              <i className="bi bi-arrow-right"></i>
            </span>
          </button>
          <button className="button large grey-light w-100 p-2 mb-0 mb-lg-3">
            <span className="flex-fill text-start">Incidencias</span>
            <span>
              <i className="bi bi-arrow-right"></i>
            </span>
          </button>
        </div>
        <div className="d-flex justify-content-between gap-3 w-100">
          <button className="button large grey-light w-100 px-3">
            <span className="color-font font-24">
              <i className="icon-meet-default"></i>
            </span>
          </button>
          <Link
            to="/conversation"
            className="button large grey-light w-100 px-3"
          >
            <span className="color-font font-24">
              <i className="icon-ichead_message"></i>
            </span>
          </Link>
          <button className="button large grey-light w-100 px-3">
            <span className="color-font font-24">
              <i className="icon-history-ic"></i>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
