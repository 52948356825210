import { Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonResourceTitle = ({ showDescription}) => {
    return (
        <>
            <div className="d-flex">
                <span className="flex-fill font-20"><Skeleton sx={{ bgcolor: '#3E3E3E' }}/></span>
                <span className="font-20"> <Skeleton sx={{ bgcolor: '#3E3E3E' }}/> </span>
            </div>
            {showDescription && (<p className="lh-1"> <Skeleton sx={{ bgcolor: '#3E3E3E' }}/> </p>)}
        </>
    )
}
