import { useSelector, useDispatch } from "react-redux";
import { onUpdateProjectStatus } from "../../services/projectService";
import { GetProject } from "../../slices";
import { projectStatusType } from "../../types/projectStatusType";

export const AvisoEmpty = () => {
    const dispatch = useDispatch();

    const { IdProject } = useSelector((state) => state.project);
    const { activities } = useSelector((state) => state.activities);
    const { sectionSelected } = useSelector((state) => state.sections);
    const { userstorySelected } = useSelector((state) => state.userstories);
    // const navigate = useNavigate();

    const onBackPlanning = () => {
        onUpdateProjectStatus(IdProject, projectStatusType.IN_PLANNING).then(() => {
            // navigate(`/project/${IdProject}/planning/definitions`)
            //dispatch(UpdateUrl(`/project/${IdProject}/planning/definitions`))
            dispatch(GetProject(IdProject, true))
        }
        );
    }

    return (
        <>
            {
                (userstorySelected === 0 || sectionSelected === 0) &&
                    (
                        <div className="table-activities-empty">
                            <article className='fw-bold mb-2'>
                                Falta algo aquí
                            </article>

                            <p>
                                Aún no puedes agregar actividades a esta User Story por que no tiene Secciones relacionadas
                            </p>

                            <button className="button grey w-100" onClick={() => onBackPlanning()}>
                                Agregar secciones
                            </button>
                        </div>
                    )
            }
        </>
    )
}

