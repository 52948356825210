import { NamesProjectsCharts } from "../../ui/components/NamesProjectsCharts";
import { NavReportsLinks } from "../../ui/components/NavReportsLinks";
import { ConversationMessages } from "./components/ConversationMessages";
import { FiltersList } from "./components/FiltersList";
import { ResourcesList } from "./components/ResourcesList";

export const ConversationPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="container-max">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 col-xxxl-10">
                                <div className="card-main mb-3">
                                    <NamesProjectsCharts />
                                </div>
                            </div>
                            <div className="col-lg-3 col-xxxl-2">
                                <div className="card-main mb-3">
                                    <NavReportsLinks />
                                </div>
                            </div>
                        </div>

                        <div className="card-main">
                            <div className="new-project-body-top border-bottom-grey-3 pb-3">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="dato-name color-font">
                                            <article className="font-18 mb-2">
                                                Conversación
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex gap-10">
                                            <span className="flex-fill">
                                                <input type="text" className="form-control" placeholder="Buscar" />
                                            </span>
                                            <button className="button grey-light font-20">
                                                <i className="icon-cerrar_icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="conver-list-menu">
                                        <FiltersList />

                                        <hr />

                                        <ResourcesList />
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <ConversationMessages />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
