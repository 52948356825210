import { Skeleton } from "@mui/material";
import { Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ResourceItemAdd } from "../../../../ui/components/Resources/ResourceItemAdd";
import { SkeletonResourceItem } from "../../../../ui/components/Skeletons/SkeletonResourceItem";
import { useFetchUsers } from "../../../dashboard/hooks/useFetchUsers";
import { SaveResourcesToProject } from "../../services/resourcesService";
import { GetProject } from "../../slices";

export const ProjectResourceAdd = ({ setIsAddResources }) => {
  const { IsLoading, userStatus, setUserStatus } = useFetchUsers();
  //const { IsLoading, userswithstatus} = useFetchUserStatus();
  //console.log(userswithstatus);

  const dispatch = useDispatch();
  const { IdProject } = useSelector((state) => state.project);


  // const { IdProject, ProjectUsersList } = project || {
  //   IdProject: 0,
  //   ProjectUsersList: [],
  // };

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  //const [userStatus, setUserStatus] = useState(userswithstatus);

  const searchResources = ({ target: { value } }) => {
    setSearchInput(value);
    if (searchInput !== "") {
      const filteredData = userStatus.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(userStatus);
    }
  };

  const onHandlerClick = () => {
    setIsAddResources(false);
    const IdsActives = (userStatus.filter((user) => user.Status)).map((ra) => ra.Id);

    SaveResourcesToProject({ IdProject: IdProject, ProjectUsersIds: [...IdsActives],}).then((response) => {
      const { status } = response;

      if (status === 200) {
        //setIsResourcesSaved(true);
        //setCanShowEpics(true);
        dispatch(GetProject(IdProject, false, true));
      }
    });
  };

  // useEffect(() => {
  //   setUserStatus(userswithstatus);
  // }, [userswithstatus])


  return (
    <>
      <div className="d-flex">
        <span className="flex-fill font-20">Recursos XScrum</span>
        <span className="font-20"> {userStatus.filter((user) => user.Status).length} </span>
      </div>
      <p className="lh-1"> Crea tu equipo de trabajo para el proyecto. </p>

      {
        IsLoading ? (
          <Skeleton className="form-control my-3" sx={{ bgcolor: '#3E3E3E' }} variant="rectangular" height={40} />
        ) : (
          <input
            className="form-control my-3"
            type="text"
            placeholder="Buscar"
            name="searchText"
            autoComplete="off"
            onChange={searchResources}
          />
        )
      }
      <div className="overflowresources md">
        <div className="list-main borders-bottom">
          <ul>
            {
              searchInput.length > 1
                ? filteredResults.map((user) => (
                  <ResourceItemAdd
                    key={user.Id}
                    UserAdded={userStatus}
                    onActionHandler={setUserStatus}
                    {...user}
                  />
                ))
                :
                (
                  IsLoading ? Array.from(new Array(5)) : userStatus).map((user, index) => (
                    <Fragment key={index}>
                      {
                        user ? (
                          <ResourceItemAdd
                            key={user.Id}
                            UserAdded={userStatus}
                            onActionHandler={setUserStatus}
                            {...user}
                          />
                        ) : (
                            <SkeletonResourceItem />
                        )
                      }
                    </Fragment>
                  )
                  )
            }
          </ul>
        </div>
      </div>

      <div className="add-item">
        {
          IsLoading ? (
            <Skeleton sx={{ bgcolor: '#3E3E3E' }} variant="rectangular" height={40} width={150} />
          ) : (
            <button className="button dark w-100" onClick={onHandlerClick}>
              <span>Confirmar</span>
            </button>
          )
        }
      </div >
    </>
  );
};
