import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ControllerInput } from "../../../../../ui/components/ControllerInput";
import { isEmptyOrSpaces } from "../../../../../ui/helpers/StringHelper";
import { usePermission } from "../../../../../ui/hooks/usePermission";
import { ModuleType } from "../../../../../ui/types/ModuleType";
import { onUpdateActivity } from "../../../services/activityService";
import { CancelOperation, CreateActivity, GetActivitiesByUpdated, UpdateActivity, UpdateActivityNew, UpdateActivityTemp } from "../../../slices/Activities/ActivitiesTrunk";
import { StatusActivitiesType } from "../../activities/Board/types";

export const EditableTextCell = ({ value: initialDescription, row, canEditBySection = true }) => {

    const dispatch = useDispatch();

    const [description, setDescription] = useState(initialDescription);
    const [isEditMode, setIsEditMode] = useState(false);

    const { original } = row;
    const { IdActivity } = original;

    const onChange = e => {
        setDescription(e.target.value)
    }

    useEffect(() => {
        setDescription(initialDescription)
    }, [initialDescription])

    const onBlur = () => {
        // console.log();
        // if (IdActivity === 0) {
        //     if(!isEmptyOrSpaces(description))
        //         dispatch(UpdateActivityTemp({ ...original, Name: description}))
        //     else
        //         dispatch(CancelOperation());
        // } else {
        //     setDescription(initialDescription);
        //     setIsEditMode(false);
        // }
        

        if (IdActivity === 0) {
            if (!isEmptyOrSpaces(description)) {
                onSaveHandler();
            }
            else {
                dispatch(CancelOperation());
            }
        } else {
            setDescription(initialDescription);
            setIsEditMode(false);
        }
    }

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (!isEmptyOrSpaces(description)) {
                if (IdActivity === 0) {
                    onSaveHandler();
                }
                else {
                    // const ActivityEdited = {
                    //     IdStatusActivity: IdStatusActivity,
                    //     IdUser: IdUser,
                    //     Name: description,
                    //     Description: Description,
                    //     Points: Points,
                    //     Priority: Priority,
                    //     Orden: Orden
                    // };

                    dispatch(UpdateActivityNew({...original, Name: description, showMessage: false}));

                    // onUpdateActivity(IdActivity, ActivityEdited).then(response => {
                    setIsEditMode(false);
                    //     //onItemChanged(IdSection, false);
                    //     // dispatch(GetActivitiesByUserStoryAndSection(true));
                    //     dispatch(GetActivitiesByUpdated());

                    // });
                }
            }
        } else if (e.key === "Escape") {
            e.preventDefault();
            //onItemChanged(IdSection, false);
            setIsEditMode(false);
            //onCancelNewItem();
            setDescription(initialDescription);

            dispatch(CancelOperation());

        }
    };

    const onSaveHandler = () => {
        // let NewActivity = {
        //     IdEpic: IdEpic,
        //     IdSection: IdSection,
        //     IdStatusActivity: IdStatusActivity,
        //     IdUserStory: IdUserStory,
        //     IdUser: IdUser,
        //     Name: description,
        //     Description: 'Prueba',
        //     Points: Points,
        //     Priority: Priority,
        //     Orden: Orden
        // };

        // SaveActivity(NewActivity).then(() => {
        //     //onItemChanged(IdSection, false);
        //     setDescription('');
        //     dispatch(GetActivities(true, true));
        // });
        dispatch(CreateActivity({...original, Name: description}));
        setIsEditMode(false);
        setDescription('');
    }

    // const canEditField = useCallback(() => { return (isEditMode && roleCanUpdate && (IdStatusActivity !== StatusActivitiesType.Finalizada && IdStatusActivity !== StatusActivitiesType.Cancelada)) }, [],)

    
    useEffect(() => {
        setDescription(initialDescription)
    }, [initialDescription])

    return (
        <>
            {(isEditMode && canEditBySection) ?
                (
                    <ControllerInput
                        className={"input-table-activities w-100"}
                        value={description}
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyDown={onKeyPress}
                    />
                ) : (
                    <div onDoubleClick={() => setIsEditMode(true)}>
                        {
                            description === '' ? setIsEditMode(true) : description
                        }
                    </div>
                )
            }
        </>
    )
}
