import { useEffect } from "react";
import { useState } from "react";
import APIHelper from "../helpers/APIHandler";
import * as Global from "../constants";

const { users } = Global.URLs;

export const useFetchCatalogs = () => {
    const [catalogs, setCatalogs] = useState([]);

    const getCatalogs = async() => {
        await APIHelper.get(users.use_GetCatalogues, null).then(response => {
            const { data: { Item } } = response;
            setCatalogs( Item );
        });
    }

    useEffect(() => {
        getCatalogs();
    }, [])
    

  return {
    catalogs,
  }
}
