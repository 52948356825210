import React from 'react'
import { FilterItemResources } from './FilterItemResources'
import { FilterItemStatus } from './FilterItemStatus'
import { FilterItemTasks } from './FilterItemTasks'

export const FilterComponent = () => {
    
    return (
        <>
            <div className="dropdown dropdown-main arrow-none">
                <span
                    className="button d-flex align-items-center grey-light font-20 px-3 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-auto-close="outside"
                >
                    <span><i className="icon-filtro-icon_v2"></i></span>
                </span>
                <div className="dropdown-menu w-75">
                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                        <span className="flex-fill font-14">Filtrar</span>
                    </article>

                    <hr className="" />

                    <FilterItemStatus />

                    <hr className="" />

                    <FilterItemResources />

                    <hr className="" />

                    <FilterItemTasks />

                </div>

            </div>
        </>
    )
}
