

export const ActivityTableItem = () => {
    return (
        <>
            <div className="table-responsive-md table-simple">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" className="text-start w-75">Tarea</th>
                            <th scope="col">Puntos</th>
                            <th scope="col">Recurso</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Prioridad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="dropdown dropdown-main arrow-none">
                                    <span className="dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span>
                                            <i className="icon-mark_active"></i>
                                        </span>
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                            <span className="flex-fill font-14">Tarea Extra</span>
                                            <span><i className="icon-cerrar_icon"></i></span>
                                        </article>
                                        <hr className="" />

                                        <div className="list-main back-none px-3">
                                            <ul className="color-font font-14">
                                                <li>
                                                    <div className="wrap">
                                                        <div className="flex-fill">
                                                            <span className="flex-fill">Interna</span>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="wrap">
                                                        <div className="flex-fill">
                                                            <span className="flex-fill">Externa</span>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>15</td>
                            <td>
                                <div className="user-circle xs bg-gradient-1">SP</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle text-nowrap" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm back-warning me-2"></i></span>
                                        En proceso
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm back-blue-2 me-2"></i></span>
                                                <span className="flex-fill font-14">En espera</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm back-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm back-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm back-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">1</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>10</td>
                            <td>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                        En espera
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">2</td>
                        </tr>
                        <tr>
                            <th scope="row"></th>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>20</td>
                            <td>
                                <div className="user-circle xs bg-gradient-3">SM</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-success me-2"></i></span>
                                        Finalizada
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                                <span className="flex-fill font-14">En espera</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">3</td>
                        </tr>
                        <tr>
                            <td>
                                <div className="dropdown dropdown-main arrow-none">
                                    <span className="dropdown-toggle mt-1" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span>
                                            <i className="icon-mark_active"></i>
                                        </span>
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                            <span className="flex-fill font-14">Tarea Extra</span>
                                            <span><i className="icon-cerrar_icon"></i></span>
                                        </article>
                                        <hr className="" />

                                        <div className="list-main back-none px-3">
                                            <ul className="color-font font-14">
                                                <li>
                                                    <div className="wrap">
                                                        <div className="flex-fill">
                                                            <span className="flex-fill">Interna</span>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="wrap">
                                                        <div className="flex-fill">
                                                            <span className="flex-fill">Externa</span>
                                                        </div>
                                                        <div>
                                                            <span>
                                                                <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>5</td>
                            <td>
                                <div className="user-circle xs bg-gradient-1">SP</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-danger me-2"></i></span>
                                        Detenida
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                                <span className="flex-fill font-14">En espera</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">4</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>10</td>
                            <td>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                        En espera
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">2</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>10</td>
                            <td>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                        En espera
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">2</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>10</td>
                            <td>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                        En espera
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">2</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className="text-start">Descripcion de actividad</td>
                            <td>10</td>
                            <td>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </td>
                            <td>
                                <div className="dropdown dropdown-main">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <span><i className="dot-sm bg-blue-2 me-2"></i></span>
                                        En espera
                                    </span>
                                    
                                    <div className="dropdown-menu">
                                        <div className="px-3">
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-warning me-2"></i></span>
                                                <span className="flex-fill font-14">En proceso</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-success me-2"></i></span>
                                                <span className="flex-fill font-14">Finalizada</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1">
                                                <span><i className="dot-sm bg-danger me-2"></i></span>
                                                <span className="flex-fill font-14">Detenida</span>
                                            </p>
                                            <p className="d-flex align-items-center gap-10 lh-1 m-0">
                                                <span><i className="dot-sm bg-grey-5 me-2"></i></span>
                                                <span className="flex-fill font-14">Cancelada</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center">2</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="activity-add-row">
                <div>
                    <span className="w-sm"></span>
                </div>
                <div className="flex-fill">
                    tarea
                </div>
                <div>
                    <span className="w-punt">15</span>
                </div>
                <div>
                    <span className="w-rec">SP</span>
                </div>
                <div>
                    <span className="w-est">estado</span>
                </div>
                <div>
                    <span className="w-prio">5</span>
                </div>
            </div>
            
            <span role="button" className="d-inline-flex align-items-center color-font lh-1">
                <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                Agregar Actividad
            </span>
        </>
    )
}
