import { Skeleton } from '@mui/material'
import React, { memo } from 'react'
import { useValidationState } from '../../../hooks/useValidationState'

export const HeaderTitle = ({ section }) => {
    const { isLoadingComponents } = useValidationState(section)

    return (
        <>
            {
                isLoadingComponents ? (
                    <div className="new-project-body-top mb-4">
                        <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                            <article className="font-18 mb-2"><Skeleton width={250} /></article>
                            <span className="d-block"><Skeleton width={250} /></span>
                        </div>
                    </div>
                ) : (
                    <div className="new-project-body-top mb-4">
                        <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                            <article className="font-18 mb-2">
                                Planeación de Proyecto
                            </article>
                            <span className="d-block">
                                Define el alcance y estructura
                            </span>
                        </div>
                    </div>
                    
                )
            }
        </>
    )
}
