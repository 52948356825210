import React from 'react'

export const TabItem = ({ title, ...rest }) => {

  return (
    <>
      <li className="nav-item" role="presentation">
        <button data-bs-toggle="tab" type="button" role="tab"  aria-selected="true" {...rest}>
          { title }
        </button>
      </li>
    </>
  )
}
