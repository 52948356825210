import styled from "styled-components";
import { Grid, Paper } from "@mui/material";

export const Item = styled(Paper)(({ textalign, paddingr }) => {
  return {
    textAlign: `${textalign === undefined ? "start" : textalign}`,
    alignContent: "center",
    boxShadow: "none",
    fontSize: "smaller",
  };
});

export const GridCustomized = styled(Grid)(() => ({
  fontSize: "small",
  alignItems: "baseline",
}));

export const GridMain = styled(Grid)(() => ({
  paddingTop: "5px",
}));
