import React from 'react'

export const UserAvatar = ({ Id, Alias, ImgSrc, Skill: { ImgClassName } }) => {
    return (
        <>
            {
                ImgSrc !== null && ImgSrc !== "" ?
                    (
                        <div key={Id} className="user-circle xs">
                            <img src={ImgSrc} alt="" />
                        </div>
                    ) : (
                        <div key={Id} className={`user-circle xs ${ImgClassName}`}>
                            {Alias}
                        </div>
                    )
            }
        </>
    )
}
