import { useSnackbar } from 'notistack';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { cleanMessageError } from '../store/slices/ErrorSlice/ErrorSlice';
import { clearMessage } from '../store/slices/MessageSlice/MessageSlice';

export const SnackBarDialogs = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { haveError, error, errorType } = useSelector((state) => state.errors);
    const { haveMessage, message, messageType } = useSelector((state) => state.messages);

    useEffect(() => {
        if (haveError) {
            enqueueSnackbar(error, { variant: errorType, preventDuplicate: true, autoHideDuration: 2000 });

            setTimeout(() => {
                dispatch(cleanMessageError());
            }, 1500);
        }
    }, [haveError, error, errorType, dispatch, enqueueSnackbar])

    useEffect(() => {
        if (haveMessage) {
            enqueueSnackbar(message, { variant: messageType, preventDuplicate: true, autoHideDuration: 2000 });

            setTimeout(() => {
                dispatch(clearMessage());
            }, 1500);
        }
    }, [haveMessage, message, messageType, dispatch, enqueueSnackbar])


    return (<></>)
}
