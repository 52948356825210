import { useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { original } from '@reduxjs/toolkit';
import React from 'react'
import { CSS } from '@dnd-kit/utilities';

import { DraggableHandle } from '../../../epics/ListTst';
import { EditableNumberCell, EditableTextCell, MenuResources, MenuStatusCell } from '../../../shared/Tables';
import { DropdownPointsCell } from '../../../shared/Tables/DropdownPointsCell';
import { Card, CardContent, Grid, TableCell, TableRow } from '@mui/material';
import { QuestionDialog } from '../../../../../../ui/components/Dialogs';
import { Item } from '../../../shared/Project/Tabs/Styles';
import { useSelector } from 'react-redux';
import { DeleteActivity } from '../../../../services/activityService';
import { GetActivitiesByUpdated } from '../../../../slices/Activities/ActivitiesTrunk';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export const ActivityCard = ({ item, IsSprint = false }) => {

    // const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item });
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { IdActivity, Name, Points, IdUser, IdStatusActivity, Priority } = item;

    const onDeleteActivity = (IdActivity) => {
        QuestionDialog("¿Desea eliminar la actividad del proyecto?", "Actividad").then((result) => {
            if (result.isConfirmed) {
                DeleteActivity(IdActivity).then(response => {
                    const { status } = response;

                    if (status === 200) {
                        enqueueSnackbar('Se ha eliminado la actividad correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                        //dispatch(GetActivitiesByUserStoryAndSection(true, false));
                        dispatch(GetActivitiesByUpdated());

                    }
                });
            }
        });
    }

    return (
        <TableRow className='back-white'>
            <TableCell className='text-start w-75'>
                <EditableTextCell value={Name} row={{ original: { ...item } }} />
            </TableCell>
            <TableCell>
                <DropdownPointsCell value={Points} row={{ original: { ...item } }} />
            </TableCell>
            <TableCell>
                <MenuResources value={IdUser} canShowMenu={true} row={{ original: { ...item } }} />
            </TableCell>
            <TableCell>
                <MenuStatusCell value={IdStatusActivity}
                    canShowMenu={true}
                    row={{ original: { ...item } }} />
            </TableCell>
            <TableCell>
                <EditableNumberCell value={Priority} row={{ original: { ...item } }} />
            </TableCell>
            <TableCell>
                <span className="font-20">
                    <i className='bi bi-trash'></i>
                </span>
            </TableCell>
        </TableRow>
    );
}