import { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce } from 'usehooks-ts'
import { NavReportsLinks } from './../../ui/components/NavReportsLinks'
import { isEmptyOrSpaces } from './../../ui/helpers'
import { SectionsType } from './../../ui/types/SectionsType'
import { ActivitiesInHold } from './components/activities/inhold/ActivitiesInHold'
import { ActivitiesInSprint } from './components/activities/insprint/ActivitiesInSprint'
import { ProjectPlanning } from './components/planning/ProjectPlanning'
import { ProjectHeader } from './components/shared/Project/ProjectHeader'
import { GetProject } from './slices'
import { setStatusProject } from './slices/Project/ProjectSlice'

export const ProjectPage = memo(function Project({ section }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const { url, IsProjectUpdated, IdProject } = useSelector(state => state.project);

    useEffectOnce(() => {
        // if (!IsProjectUpdated && id !== undefined) {
        if (id !== undefined) {
            dispatch(GetProject(id, true, false))
        } else {
            dispatch(setStatusProject(false));
        }
    }, [id, dispatch])


    // useEffect(() => {
    //     window.addEventListener("beforeunload", alertUser);
    //     return () => {
    //       window.removeEventListener("beforeunload", alertUser);
    //     };
    //   }, []);
    //   const alertUser = (e) => {
    //     e.preventDefault();
    //     e.returnValue = "";
    //   };

    // useEffect(() => {
    //     if (hasActivitiesChanged)
    //         dispatch(GetProjectByUpdated())
    // }, [hasActivitiesChanged, dispatch])

    useEffect(() => {
        if (!isEmptyOrSpaces(url)) {
            navigate(url);
        }
    }, [url, navigate])


    const showSection = () => {
        switch (section) {
            case SectionsType.NewProject:
            case SectionsType.Project:
            case SectionsType.ProjectDetails:
                return <ProjectPlanning section={section} />
            case SectionsType.ProjectInHold:
                return <ActivitiesInHold section={section} />
            case SectionsType.ProjectInSprint:
                return <ActivitiesInSprint section={section} />
            default:
                break;
        }
    }

    return (
        <div className="container-main-wrap">
            <div className="container-max">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-9 col-xxxl-10">
                            <div className="card-main mb-3">
                                <ProjectHeader section={section} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-xxxl-2">
                            <div className="card-main mb-3">
                                <NavReportsLinks />
                            </div>
                        </div>
                    </div>
                    {
                        showSection()
                    }
                </div>
            </div>
        </div>
    )
})
