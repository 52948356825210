import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const GetAllProjects = () => {
  return APIHandler.get(URLs.projects.pro_GetAll);
};

const GetProjectById = (IdProject) => {
  return APIHandler.get(`${URLs.projects.pro_Delete_Update_GetById}${IdProject}`);
};

const GetLastStatusProject = (IdProject) => {
  return APIHandler.get(`${URLs.projects.pro_GetLastStatusProject}${IdProject}`);
};

const updateProject = (IdProject, ProjectData) => {
  return APIHandler.put(`${URLs.projects.pro_Delete_Update_GetById}${IdProject}`, ProjectData);
};

const onUpdateProjectStatus = (IdProject, IdStatus) => {
  return APIHandler.put(`${URLs.projects.pro_UpdateStatus}${IdProject}/${IdStatus}`);
};

const DeleteProject = (IdProject) => {
  return APIHandler.delete(`${URLs.projects.pro_Delete_Update_GetById}${IdProject}`);
};

const CreateProject = (newProject) => {
  return APIHandler.post(URLs.projects.pro_Create, newProject);
};

const AddProjectUsers = (IdProject, Resources) => {
  return APIHandler.post(URLs.projects.pro_AddProjectUsers, Resources);
};

const DeleteProjectUsers = (Resource) => {
  return APIHandler.post(URLs.projects.pro_RemoveProjectUser, Resource);
};

const onRecalculationData = (projectId) => {
  return APIHandler.get(`${URLs.calculations.cal_Recalculation}${projectId}`)
}

export {
  GetAllProjects,
  GetProjectById,
  GetLastStatusProject,
  updateProject,
  onUpdateProjectStatus,
  DeleteProject,
  AddProjectUsers,
  CreateProject,
  DeleteProjectUsers,
  onRecalculationData
};
