import { GetErrorResponse } from "../../../../ui/helpers/ErrorHelper";
import { setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { setMessage } from "../../../../ui/store/slices/MessageSlice/MessageSlice";
import { MessageType } from "../../../../ui/types/MessageType";
import {
  CreateProject,
  GetProjectById,
  onRecalculationData,
  onUpdateProjectStatus,
  updateProject,
} from "../../services/projectService";
import { onGetHistorySprints } from "../../services/sprintService";
import { projectStatusType } from "../../types/projectStatusType";
import { cleanActivities, startChangeActivities } from "../Activities/ActivitiesSlice";
import { cleanEpics,  } from "../Epics/EpicsSlice";
import { cleanSections, setSectionsWithWarnings } from "../Sections/SectionsSlice";
import { cleanUserStories } from "../UserStories/UserStoriesSlice";
import {
  cleanProject,
  EndLoadingProject,
  EndProjectStatusChanged,
  setHistorySprints,
  setLastProjectStatus,
  setProject,
  setProjectName,
  setStatusProject,
  setUrl,
  startLoadingProject,
  startProjectStatusChanged,
} from "./ProjectSlice";

const DEFAULT_ROUTE = '/project/ID/planning/definitions';

const ROUTES_OPTIONS = {
  1 : '/project/ID/planning/definitions',
  2 : '/project/ID/planning/activities',
  3 : '/project/ID/hold/activities',
  4 : '/project/ID/sprint/activities',
  5 : '/project/ID/sprint/activities'
}

const GetProjectByUpdated = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject, url, lastStatus } } = getState();

    dispatch(startChangeActivities())


    await GetProjectById(IdProject).then((response) => {
        const { data: { Item }, } = response;
        dispatch(setProject({ Item, url, lastStatus }));
      })
      .catch((error) => {
        const { status } = error.response;
        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
        dispatch(EndLoadingProject());
      }).finally(() => {
        dispatch(EndProjectStatusChanged());
      });
  };
}

const GetProject = (idProject, withRedirection = false, IsRefresh = false) => {
  return async (dispatch, getState) => {

    console.log(getState());

    const { IsProjectUpdated } = getState();
    if(IsProjectUpdated) return;

    // dispatch(RecalculationData(false))
    if (!IsRefresh) dispatch(startLoadingProject());

    await GetProjectById(idProject).then((response) => {
        const { data: { Item }, } = response;

        console.log(idProject, withRedirection, IsRefresh);
        console.log(response);

        let url = '';
        if (withRedirection) 
          url = (ROUTES_OPTIONS[Item.IdStatusProject] || DEFAULT_ROUTE).replace('ID', `${idProject}`);

        
        console.log(url);
        dispatch(setProject({ Item, url }));
      })
      .catch((error) => {
        const { status } = error.response;
        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
        dispatch(EndLoadingProject());
      }).finally(() => {
        dispatch(EndProjectStatusChanged());
      });
  };
};

const GetProjectWithRecalculationData = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject }} = getState();

    if(IdProject === 0) return;

    await onRecalculationData(IdProject).then(() => {
      dispatch(GetProject(IdProject, true));
    });
  }
}

const UpdateUrl = (newUrl) => {
  return async (dispatch) => {
    dispatch(setUrl(newUrl));
  };
};

const UpdateLastStatus = (status) => {
  return async (dispatch) => {
    dispatch(setLastProjectStatus(status));
  };
};

const CleanProjectAndTree = () => {
  return async (dispatch) => {
    dispatch(cleanProject());
    dispatch(cleanEpics());
    dispatch(cleanUserStories());
    dispatch(cleanSections());
    dispatch(cleanActivities());
  };
};

const AddProject = (name, description) => {
  return async (dispatch, getstate) => {
    const project = {
      Name: name,
      Description: description,
    };

    CreateProject(project)
      .then((response) => {
        const {
          status,
          data: { AffectedId },
        } = response;

        if (status === 200) {
          dispatch(GetProject(AffectedId, true, true));
          dispatch(
            setMessage({
              message: "Se ha creado el proyecto correctamente",
              messageType: MessageType.success,
              status: status,
            })
          );
        }
      })
      .catch((error) => {
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
      });
  };
};

const UpdateProject = (name, description) => {
  return async (dispatch, getState) => {
    const {
      project: { IdProject },
    } = getState();

    const project = {
      Name: name,
      Description: description,
    };

    updateProject(IdProject, project)
      .then((response) => {
        const { status } = response;

        if (status === 200) {
          dispatch(setProjectName(name));
          dispatch(
            setMessage({
              message: "Se ha editado el proyecto correctamente",
              messageType: MessageType.success,
              status: status,
            })
          );
        }
      })
      .catch((error) => {
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
      });
  };
};

const RecalculationData = (refreshProject = false) => {
  return async (dispatch, getState) => {
    const { project: { IdProject }} = getState();

    if(IdProject === 0) return;

    await onRecalculationData(IdProject).then(() => {
      dispatch(startChangeActivities())
      if(refreshProject) dispatch(GetProject(IdProject, false, true));
    });
  }
}

const RecalculationProjectData = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject }} = getState();

    if(IdProject === 0) return;

    await onRecalculationData(IdProject).then(() => {
      dispatch(GetProjectByUpdated());
    });
  }
}

const UpdateProjectStatus = (statusprojectId, withCallback = false, setShowDialog ) => {
  return async (dispatch, getState) => {
    const { project: { IdProject }} = getState();

    //dispatch(startProjectStatusChanged());
    //dispatch(RecalculationData(false))
    onUpdateProjectStatus(IdProject, statusprojectId).then((response) => {

      if(withCallback) setShowDialog(true);
      // const {data: { Message } } = response;

      // dispatch(setMessage({ message: Message, messageType: MessageType.success }));
		}).catch(error => {
      dispatch(EndProjectStatusChanged());
        const { status } = error.response;
        console.log(error);

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status
          })
        );

        if(status === 400){
          const { data: { Item: { Message, NotValidSections} } } = error.response;

          dispatch(setSectionsWithWarnings(NotValidSections));
          dispatch(
            setMessageError({
              message: Message,
              errorType: MessageType.error,
              status: status
            })
          );
        }
		}).finally(() =>{
      // dispatch(EndProjectStatusChanged());
    });
  }
}

const LoadProject = (projectData) => {
  return async (dispatch) => {
    dispatch(startLoadingProject());
    dispatch(setStatusProject(true));

    const url = (ROUTES_OPTIONS[projectData?.IdStatusProject] || DEFAULT_ROUTE).replace('ID', `${projectData?.IdProject}`);
    dispatch(setProject({ Item: { ...projectData } , url }));
    dispatch(RecalculationData(true));
    dispatch(EndProjectStatusChanged());
  }
}

const GetHistorySprints = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject } } = getState();
    onGetHistorySprints(IdProject).then((result) => {
      const { data: { Items } } = result;
      console.log(Items);
      dispatch(setHistorySprints(Items));
    });
  }
}


export {
  GetProject,
  GetProjectByUpdated,
  UpdateUrl,
  UpdateLastStatus,
  CleanProjectAndTree,
  AddProject,
  UpdateProject,
  RecalculationData,
  UpdateProjectStatus,
  LoadProject,
  GetProjectWithRecalculationData,
  GetHistorySprints,
  RecalculationProjectData,
};
