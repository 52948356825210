import styled from 'styled-components';
import { Grid, Paper } from '@mui/material';


export const Item = styled(Paper)(({color}) => ({
    textAlign: 'start',
    alignContent: 'center',
    boxShadow: 'none',
    color: color
  }));

  export const GridCustomized = styled(Grid)(() => ({
    fontSize:'small',
    alignItems: 'baseline'
  }));
