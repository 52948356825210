const round =(value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

function addZeroes(num) {
    // Cast as number
    var num = Number(num);
    // If not a number, return 0
    if (isNaN(num)) {
        return 0;
    }
    // If there is no decimal, or the decimal is less than 2 digits, toFixed
    if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2 ){
        num = num.toFixed(2);
    }
    // Return the number
    return num;
}

const onlyNumber = (input) => {
    if ( ((input < 48 || input > 57) && (input < 96 || input > 105)) && input !== 8 && input !== 27 && input !== 37 && input !== 39 && input !== 13 && input !== 46) {
        return false;
    }
    return true;
}

export {
    round,
    onlyNumber,
    addZeroes
}