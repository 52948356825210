import { GetErrorResponse } from "../../../../ui/helpers/ErrorHelper";
import {
  cleanMessageError,
  setMessageError,
} from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import {
  clearMessage,
  setMessage,
} from "../../../../ui/store/slices/MessageSlice/MessageSlice";
import { MessageType } from "../../../../ui/types";
import {
  GetSectionsByIdProjectRelation,
  GetSectionsByIdUserStory,
  onGetByProjectAndEpicWithUserStoriesRelation,
  SaveSection,
} from "../../services/sectionService";
import {
  cleanActivities,
  EndLoadingActivities,
  setAddActivities,
} from "../Activities/ActivitiesSlice";
import { EndEpicSelectedChanged, startEpicsChanges } from "../Epics/EpicsSlice";
import { setCanContinueProject } from "../Project/ProjectSlice";
import {
  EndUserStorySelectedChange,
  setUserStories,
} from "../UserStories/UserStoriesSlice";
import {
  checkSectionsRelationshipCompleted,
  endLoadingSections,
  EndSectionChanged,
  EndSectionSelectedChanged,
  refreshSections,
  setRefreshSectionSelected,
  setSections,
  setSectionsBackup,
  setSectionSelected,
  setSectionsWithWarnings,
  startSections,
  startSectionsChanges,
  startSectionSelectedChanged,
} from "./SectionsSlice";

export const GetSectionsByProject = (IsRefresh = false) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage());
    dispatch(cleanMessageError());

    if (IsRefresh) dispatch(startSectionsChanges());
    else dispatch(startSections());
    const {
      project: { IdProject },
      epics: { epicSelected },
    } = getState();

    await GetSectionsByIdProjectRelation(IdProject).then((response) => {
      //  await onGetByProjectAndEpicWithUserStoriesRelation(IdProject, epicSelected).then((response) => {
      const {
        data: { Items },
      } = response;

      console.log(response);

      if (IsRefresh) dispatch(refreshSections(Items));
      else dispatch(setSections(Items));

      dispatch(checkSectionsRelationshipCompleted(Items));

      if (Items.length >= 1) {
        dispatch(setCanContinueProject(true));
      } else {
        dispatch(setCanContinueProject(false));
        dispatch(setSectionSelected(0));
        dispatch(setSections([]));
      }
    });
  };
};

const GetSectionsByUserStory = (EndLoading = false, ActivitiesChanged = false) => {
  return async (dispatch, getState) => {
    // dispatch(clearMessage());
    // dispatch(cleanMessageError());
    //dispatch(startSections());

    const {
      project: { IdProject },
      epics: { epicSelected },
      sections: { sectionSelected },
    } = getState();

    //GetSectionsByIdUserStory(userStorySelected)
    await onGetByProjectAndEpicWithUserStoriesRelation(IdProject, epicSelected)
      .then((response) => {
        const {
          data: { Items },
        } = response;

        console.log(Items);
        dispatch(setSectionsWithWarnings([]));
        dispatch(setSections(Items));
        dispatch(setSectionsBackup(Items))

        if (Items?.length >= 1) {
          if (!ActivitiesChanged)
            dispatch(setSectionSelected(Items[0]?.IdSection));
          dispatch(setAddActivities(true));
        } else {
          dispatch(setUserStories([]));
          dispatch(setAddActivities(false));
        }

        // if (Items?.length >= 1 && userStorySelected !== userStorySelectedBack) {
        //   setSectionSelected(Items[0]?.IdSection);
        //   setUserStorySelectedBack(userStorySelected);
        // }
        //if (sectionSelected === 0) setSectionSelected(Items[0]?.IdSection);

        // if (Items?.length === 0) dispatch(setSectionSelected(0));
        //if (EndLoading) dispatch(endLoadingSections());
      })
      .catch((error) => {
        dispatch(setAddActivities(false));
      })
      .finally(() => {
        dispatch(endLoadingSections());
        dispatch(EndLoadingActivities());
      });
  };
};

const GetSections = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject }, epics: { epicSelected } } = getState();

    await onGetByProjectAndEpicWithUserStoriesRelation(IdProject, epicSelected).then((response) => {
        const { data: { Items } } = response;

        console.log(response);

        dispatch(setSections(Items));
        dispatch(setSectionsBackup(Items))

      })
      .catch(() => {
        dispatch(setAddActivities(false));
      })
      .finally(() => {
        dispatch(endLoadingSections());
        dispatch(EndLoadingActivities());
      });
  };
};

const NewSection = (description, orden) => {
  return async (dispatch, getState) => {
    dispatch(startSectionsChanges());

    const {
      project: { IdProject },
    } = getState();

    const sectionNew = {
      IdProject: IdProject,
      Name: `SEC ${orden}`,
      Description: description,
      Orden: orden,
    };

    SaveSection(sectionNew)
      .then((response) => {
        const {
          data: { Message },
        } = response;

        dispatch(GetSectionsByProject(true));
        dispatch(
          setMessage({
            message: `Section: ${Message}`,
            messageType: MessageType.success,
          })
        );
      })
      .catch((error) => {
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status
          })
        );
        dispatch(EndSectionChanged());
      });
  };
};

const ChangeSectionSelected = (idSection) => {
  return async (dispatch, getState) => {
    const {
      sections: { sectionSelected },
    } = getState();

    if (sectionSelected !== idSection) {
      dispatch(EndEpicSelectedChanged());
      dispatch(EndUserStorySelectedChange());
      dispatch(startSectionSelectedChanged());
      dispatch(cleanActivities());

      setTimeout(() => {
        dispatch(setRefreshSectionSelected(idSection));
        dispatch(EndSectionSelectedChanged());
      }, 100);
    }
  };
};

export { GetSectionsByUserStory, GetSections, ChangeSectionSelected, NewSection };
