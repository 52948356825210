import { projectStatusType } from "../../../../types/projectStatusType";
import { BOARD_SECTIONS } from "../constants";
import { StatusActivitiesType } from "../types";
import { getActivitiesBySelectedAndNotFinished, getActivitiesBySelectedStatus, getActivitiesByStatusActivity } from "./activities";

export const initializeBoard = (activities, IdProjectStatus) => {
  const boardSections = {};

  for (var section in BOARD_SECTIONS) {
    if (BOARD_SECTIONS.hasOwnProperty(section)) {

      if(BOARD_SECTIONS[section] === BOARD_SECTIONS["En proceso"]){
        boardSections[section] = getActivitiesBySelectedStatus(
          activities,
          true,
        );
      }
      else if(BOARD_SECTIONS[section] === BOARD_SECTIONS.Backlog){
        boardSections[section] = getActivitiesBySelectedAndNotFinished(
          activities,
          false,
        );
      }
      else {
        boardSections[section] = getActivitiesByStatusActivity(
          activities,
          StatusActivitiesType.Finalizada
        );
      }

      // if(BOARD_SECTIONS[section] === BOARD_SECTIONS.Backlog || BOARD_SECTIONS[section] === BOARD_SECTIONS["En proceso"]) {
      //     boardSections[section] = getActivitiesBySelectedStatus(
      //     activities,
      //     BOARD_SECTIONS[section] === BOARD_SECTIONS.Backlog ? false : true,
      //     IdProjectStatus
      //   );
      // }
      // else{
      //   boardSections[section] = getActivitiesByStatusActivity(
      //     activities,
      //     StatusActivitiesType.Finalizada
      //   );
      // }
      
    }
  }

  return boardSections;
};

export const findBoardSectionContainer = (boardSections, id) => {

  if (id in boardSections) {
    return id;
  }

  const container = Object.keys(boardSections).find((key) =>
    boardSections[key].find((item) => item.IdActivity === id)
  );
  return container;
};
