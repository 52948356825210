import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const Spinner = () => {

  const { IsLoading} = useSelector( state => state.loading );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={IsLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};