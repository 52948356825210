import { Grid, Switch } from '@mui/material';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: ${props => props.disabled ? '' : '1px solid #000'};
  border-radius: 4px;
  color: ${props => props.disabled ? '' : '#000'};
  cursor: pointer;
  font-size: 15px;
`;

export default Button;

export const GridCustomized = styled(Grid)(({top}) => ({
    paddingTop: top !== undefined ? top : 0
  }));

export const GreenSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#31c12a',
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor:'#31c12a',
        opacity: 1
      },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FFFFFF'
    }
}));
