import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell, TableRow } from '@mui/material';
import { EditableNumberCell, EditableTextCell, ExtraTaskCell, MenuResources, MenuStatusCell } from '../../../shared/Tables';
import { DropdownPointsCell } from '../../../shared/Tables/DropdownPointsCell';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { QuestionDialog } from '../../../../../../ui/components/Dialogs';
import { DeleteActivity } from '../../../../services/activityService';
import { CancelOperation, CreateActivity, GetActivitiesByUpdated, UpdateActivityNew } from '../../../../slices/Activities/ActivitiesTrunk';
import { DraggableHandle } from '../../../epics/ListTst';
import { BOARD_SECTIONS } from '../constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { isEmptyOrSpaces } from '../../../../../../ui/helpers';
import { setMessageError } from '../../../../../../ui/store/slices/ErrorSlice/ErrorSlice';
import { MessageType } from '../../../../../../ui/types';
import { Fragment } from 'react';
import { PermissionGate } from '../../../../../../ui/components/PermissionGate/PermissionGate';
import { ModuleType } from '../../../../../../ui/types/ModuleType';
import { SCOPES } from '../../../../../../ui/types/ScopesType';

export const SortableCard = ({ children, id, item, IsSprint = false, boardID }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [canEditCell, setCanEditCell] = useState(true);

    const { IdActivity, Name, Points, IdUser, IdStatusActivity, Priority } = item;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
    };

    useEffect(() => {
        if (boardID === BOARD_SECTIONS.Finalizadas) setCanEditCell(false);
        // else if (boardID === BOARD_SECTIONS['En proceso'] && IdStatusActivity === 3) setCanEditCell(false);
        else setCanEditCell(true);

    }, [boardID, IdStatusActivity])

    const onDeleteActivity = (IdActivity) => {
        if (!canEditCell || IdActivity === 0) return;

        QuestionDialog("¿Desea eliminar la actividad del proyecto?", "Actividad").then((result) => {
            if (result.isConfirmed) {
                DeleteActivity(IdActivity).then(response => {
                    const { status } = response;

                    if (status === 200) {
                        enqueueSnackbar('Se ha eliminado la actividad correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                        //dispatch(GetActivitiesByUserStoryAndSection(true, false));
                        dispatch(GetActivitiesByUpdated());

                    }
                });
            }
        });
    }

    const onSaveActivity = () => {
        const { Name } = item;
        if (!isEmptyOrSpaces(Name))
            dispatch(CreateActivity({ ...item }));
        else
            dispatch(
                setMessageError({
                    message: 'Es necesario ingresar una descripción de la actividad.',
                    errorType: MessageType.error,
                    status: 400,
                })
            );
    }


    return (
        <TableRow ref={setNodeRef} style={style} {...attributes} >
            {/* {children} */}
            <TableCell>
                <span className="icon flex-none mt-2" >
                    <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ handleListeners: {} }}>
                        <DraggableHandle handleListeners={boardID !== BOARD_SECTIONS.Finalizadas ? listeners : {}} />
                    </PermissionGate>
                </span>
            </TableCell>
            <TableCell className='text-start w-75'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canEditBySection: false }}>
                    <EditableTextCell value={Name} row={{ original: { ...item } }} canEditBySection={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell className='text-center'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canEditBySection: false }}>
                    <ExtraTaskCell row={{ original: { ...item } }} canEditBySection={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell className='text-center'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canEditBySection: false }}>
                    <DropdownPointsCell value={Points} row={{ original: { ...item } }} canEditBySection={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell className='text-center'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canShowMenu: false }}>
                    <MenuResources value={IdUser} row={{ original: { ...item } }} canShowMenu={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canShowMenu: false }}>
                    <MenuStatusCell value={IdStatusActivity} row={{ original: { ...item } }} canShowMenu={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell className='text-center'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ canEditBySection: false }}>
                    <EditableNumberCell value={Priority} boardID={boardID} row={{ original: { ...item } }} canEditBySection={canEditCell} />
                </PermissionGate>
            </TableCell>
            <TableCell className='text-center'>
                <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanDelete]}>
                    <span className="font-20"
                        onClick={() => onDeleteActivity(IdActivity)}>
                        <i className={`bi bi-trash`}></i>
                    </span>
                </PermissionGate>
            </TableCell>
        </TableRow>
    )
}
