import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { QuestionDialog } from "../../../../ui/components/Dialogs";

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetEpicsByProject, RecalculationProjectData, RemoveEpic, setEpicSelected, UpdateEpicItem } from "../../slices";
import { DeleteEpic, UpdateEpic } from "../../services/epicService";
import { HavePermission } from "../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../ui/types/RolesType";
import { useEffect } from "react";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { isEmptyOrSpaces } from "../../../../ui/helpers/StringHelper";
import { DraggableHandle } from "./ListTst";
import { useCallback } from "react";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { MessageType } from "../../../../ui/types";


export const ProjectEpicItem = ({ position, canAddEpics, item }) => {
  const { listeners, setNodeRef, transform, transition } = useSortable({ id: item });

  const { IdEpic, IdProject, Name, Description, Orden } = item;

  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const [EpicDescription, setEpicDescription] = useState('');


  useEffect(() => {
    setEpicDescription(Description)
  }, [Description])

  const dispatch = useDispatch();
  const { epicSelected } = useSelector(state => state.epics);
  const { idRol } = useSelector((state) => state.authorization);

  const onRefreshData = useCallback(
    () => {
      dispatch(GetEpicsByProject(true))
    }, [dispatch])


  const onRemoveItem = () => {
    QuestionDialog(`¿Esta seguro que desea eliminar ${Description}?`, 'Épicas').then((resp) => {
      if (resp.isConfirmed) {
        dispatch(RecalculationProjectData());
        DeleteEpic(IdEpic).then((response) => {
          const { status } = response;

          if (status === 200) {
            onRefreshData();
            //dispatch(setEpicSelected(0));
            enqueueSnackbar(`Se ha eliminado la épica correctamente.`, { variant: 'warning', preventDuplicate: true, autoHideDuration: 4000 });
          }
        }).catch(error => {
          const { status, data } = error.response;

          if (status === 400) {
            const { Message } = data;
            dispatch(
              setMessageError({
                message: Message,
                errorType: MessageType.error,
                status: status
              })
            );
          }
        });
        //dispatch(RemoveEpic(IdEpic));
      }
    });
  };

  const onEditItem = () => {
    if (canAddEpics) {
      setEditMode(true);
    }
  };

  const onKeyHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!isEmptyOrSpaces(EpicDescription)) {
        UpdateEpic(IdEpic, {
          IdProject: IdProject,
          Name: Name,
          Description: EpicDescription,
          Orden: Orden,
        }).then((response) => {
          const {
            status,
            data: { AffectedId },
          } = response;

          if (status === 200) {
            setEpicDescription(EpicDescription);
            setEditMode(false);
            setEpicSelected(AffectedId);
            onRefreshData();
          }
        }).catch(error => {
          console.log(error);
        });

        //dispatch(UpdateEpicItem(IdEpic, Name, EpicDescription, Orden));
        setEditMode(false);
      }

    } else if (e.keyCode === 27) {
      setEpicDescription(Description);
      setEditMode(false);
    }
  }

  // const updateEpicDescription = ({ target: { value } }) => {
  //   setEpicDescription(value);
  // };

  // const onKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (EpicDescription !== "") {
  //       UpdateEpic(IdEpic, {
  //         IdProject: IdProject,
  //         Name: Name,
  //         Description: EpicDescription,
  //         Orden: Orden,
  //       }).then((response) => {
  //         const {
  //           status,
  //           data: { AffectedId },
  //         } = response;

  //         if (status === 200) {
  //           setEpicDescription(e.target.value);
  //           setStatus(false);
  //           setEpicSelected(AffectedId);
  //           onChangeItems();
  //         }
  //       }).catch(error => {
  //         console.log(error);
  //       });

  //       //dispatch(UpdateEpicItem(IdEpic, Name, EpicDescription, Orden));
  //       setStatus(false);
  //     }
  //   } else if (e.key === "Escape") {
  //     setEpicDescription(Description);
  //     setStatus(false);
  //   }
  // };

  // const onSave = () => {
  //   UpdateEpic(IdEpic, {
  //     IdProject: IdProject,
  //     Name: Name,
  //     Description: EpicDescription,
  //     Orden: Orden,
  //   }).then((response) => {
  //     const {
  //       status,
  //       data: { AffectedId },
  //     } = response;

  //     if (status === 200) {
  //       setEpicDescription(EpicDescription);
  //       setStatus(false);
  //       setEpicSelected(AffectedId);
  //       onChangeItems();
  //     }
  //   }).catch(error => {
  //     console.log(error);
  //   });

  //   //dispatch(UpdateEpicItem(IdEpic, Name, EpicDescription, Orden));
  //   setStatus(false);
  // }

  // const onCancel = () => {
  //   setEpicDescription(Description);
  //   setStatus(false);
  // }

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setEpicDescription(Description);
      setEditMode(false);
    }
  };

  const onClickItemEpic = () => {
    dispatch(setEpicSelected(IdEpic));
  };

  const onBlurInput = () => {
    setEpicDescription(Description);
    setEditMode(false);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Fragment key={IdEpic}>
      {

        // (editMode && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])) ? (
        <div className="plans-items d-flex" ref={setNodeRef} style={style}>
          <span className="icon flex-none mt-2" >
            <PermissionGate module={ModuleType.Epics} scopes={[SCOPES.CanUpdate]} errorProps={{ handleListeners: {} }}>
              <DraggableHandle handleListeners={listeners} />
            </PermissionGate>
          </span>
          <div className={epicSelected === IdEpic ? "plan-item flex-fill selected" : "plan-item flex-fill"}
            onKeyDown={handleKeyDown}
            onClick={onClickItemEpic}
            onBlur={onBlurInput}
          >
            <div className="names">
              <span className="plan-tit color-font-dark flex-none">
                {`Épica ${Orden}`}
              </span>
              {
                editMode ? (
                  <PermissionGate module={ModuleType.Epics} scopes={[SCOPES.CanUpdate]} >
                    <ControllerInput
                      value={EpicDescription}
                      onChange={({ target: { value } }) => setEpicDescription(value)}
                      onKeyDown={onKeyHandler}
                      className={"form-control"}
                    />
                  </PermissionGate>

                ) : (
                  <>
                    <PermissionGate module={ModuleType.Epics} scopes={[SCOPES.CanUpdate]} errorProps={{ title: '' }}>
                      <Tooltip title={"Editar Épica"}>
                        <div className="d-flex flex-fill">
                          <span
                            className="plan-name flex-fill"
                            onDoubleClick={onEditItem}
                          >
                            {EpicDescription}
                          </span>
                        </div>
                      </Tooltip>
                    </PermissionGate>
                    {
                      <PermissionGate module={ModuleType.Epics} scopes={[SCOPES.CanDelete]} errorProps={{ hidden: !canAddEpics }}>
                        <span
                          tabIndex={500}
                          className="font-18"
                          onClick={onRemoveItem}
                        >
                          <i className="icon-rc_btnn-quitar"></i>
                        </span>
                      </PermissionGate>
                    }
                  </>
                )
              }
            </div>

          </div>
        </div>
        // ) : (
        //   <div className="plans-items d-flex" ref={setNodeRef} style={style}>
        //     <span className="icon flex-none mt-2" >
        //       <DraggableHandle handleListeners={listeners} />
        //     </span>
        //     <div className={epicSelected === IdEpic ? "plan-item flex-fill selected" : "plan-item flex-fill"}
        //       onKeyDown={handleKeyDown}
        //       onClick={onClickItemEpic}
        //       onBlur={onBlurInput}
        //     >
        //       <div className="names">
        //         <Tooltip title={HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) ? "Editar Épica" : ""}>
        //           <div className="d-flex flex-fill">
        //             <span className="plan-tit color-font-dark flex-none">
        //               {`Épica ${position}`}
        //             </span>
        //             <span
        //               className="plan-name flex-fill"
        //               onDoubleClick={onEditItem}
        //             >
        //               {EpicDescription}
        //             </span>
        //           </div>
        //         </Tooltip>

        //         {
        //           HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) && (
        //             <span
        //               tabIndex={500}
        //               className="font-18"
        //               hidden={!canAddEpics}
        //               onClick={onRemoveItem}
        //             >
        //               <i className="icon-rc_btnn-quitar"></i>
        //             </span>
        //           )
        //         }
        //       </div>
        //     </div>
        //   </div>
        // )
      }
    </Fragment>
  );
};
