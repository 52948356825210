import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { isEmptyOrSpaces } from "../../../../ui/helpers/StringHelper";
import { SaveSection } from "../../services/sectionService";
import { NewSection } from "../../slices";
import { SkeletonButtonAddItem } from "../skeletons/SkeletonButtonAddItem";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

export const ProjectSectionNew = ({ sectionNum, idProject, onCancelSection, onChangeItems }) => {
    const ref = useRef(null);

    const dispatch = useDispatch();

    const { hasSectionsChanged } = useSelector((state) => state.sections);
    const { message } = useSelector((state) => state.messages);

    const [sectionDescription, setsectionDescription] = useState("");

    useEffect(() => {
        if (message && message !== '')
            setsectionDescription('');
    }, [message])

    // const onKeyPress = (e) => {
    //     if (e.keyCode === 13) {
    //         e.preventDefault();
    //         if (sectionDescription !== "") {
    //             onSaveSection();
    //         }
    //     } else if (e.keyCode === 27) {
    //         onCancelSection();
    //     }
    // };

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) onCancelSection();
    };

    // const onSaveSection = () => {
    //     const newSection = {
    //         IdProject: idProject,
    //         Name: `SEC ${sectionNum}`,
    //         Description: sectionDescription,
    //         Orden: sectionNum,
    //     };

    //     SaveSection(newSection)
    //         .then((response) => {
    //             const { status } = response;

    //             console.log(response);

    //             if (status === 200) {
    //                 setsectionDescription("");
    //                 onChangeItems();
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    // const onBlurFocus = () => {
    //     onCancelSection();
    // };

    const onKeyHandler = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (!isEmptyOrSpaces(sectionDescription)) {
                dispatch(NewSection(sectionDescription, sectionNum));
            }

        } else if (e.keyCode === 27) {
            setsectionDescription('');
            onCancelSection();
        }
    }

    const handleClickOutside = () => {
        onCancelSection();
    
      }
    
      useOnClickOutside(ref, handleClickOutside)

    return (
        <>
            <div ref={ref} className="plans-items d-flex" onKeyDown={handleKeyDown}>
                <span className="icon flex-none mt-2">
                    <i className="bi bi-three-dots-vertical"></i>
                </span>
                {
                    hasSectionsChanged ? (<SkeletonButtonAddItem />) : (
                        <div className="plan-item flex-fill">
                            <div className="names">
                                <span className="plan-tit color-font-dark flex-none">
                                    {`SEC ${sectionNum}`}
                                </span>
                                {/* <input
                            ref={(ref) => ref && ref.focus()}
                            className="form-control"
                            type="text"
                            placeholder="Descripción"
                            onChange={({ target: { value } }) => setsectionDescription(value)}
                            value={sectionDescription}
                            onKeyDown={onKeyPress}
                            onFocus={(e) =>
                                e.currentTarget.setSelectionRange(
                                    e.currentTarget.value.length,
                                    e.currentTarget.value.length
                                )
                            }
                        /> */}

                                <ControllerInput
                                    value={sectionDescription}
                                    onChange={({ target: { value } }) => setsectionDescription(value)}
                                    onKeyDown={onKeyHandler}
                                    className={"form-control"}
                                />
                            </div>
                        </div >
                    )
                }

            </div >
        </>
    )
}
