import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return {
    logged: !!user,
    user: user,
    idRol: user?.IdRol,
    // idRol: 3,
    idUser: user?.Id,
    permissions: user?.RolePermissions
  };

};

export const AuthSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    LoginUser: (state, action) => {
      state.logged = true;
      state.user = action.payload;
      state.idRol = action.payload.IdRol;
      state.idUser = action.payload.Id;
      // state.idRol = 3;
      state.permissions = action.payload.RolePermissions;
    },
    LogoutUser: (state) => {
      localStorage.removeItem("user");
      state.logged = false;
      state.user = {};
      state.idRol = 0;
      state.idUser = 0;
      state.permissions = [];
    },
  },
});

export const { LoginUser, LogoutUser } = AuthSlice.actions;
