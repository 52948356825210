import { Link } from "react-router-dom"

export const NamesProjectsCharts = () => {
    return (
        <>
            <div className="new-project-top">
                <div className="namesprojects-charts d-flex align-items-center gap-20 flex-wrap flex-xxl-nowrap">
                    <div className="flex-fill names-projects">
                        <Link to="/dashboard" className="d-inline-block color-font mb-1">
                            <span className="me-1">
                            <i className="icon-arrow_back"></i>
                            </span>
                            Reporte de Actividades
                        </Link>
                        <br />
                        <span className="font-24 lh-1 me-2">Ford Landing</span>
                        <span><i className="icon-ic_editar"></i></span>
                    </div>
                    <div className="chart-list py-2">
                        <div className="d-flex">
                            {/* <div className="pie-wrap me-3">
                            <div className="pie animate" style={{"--p":85,"--c":"#2DC427"}}>
                                <div className="line-back"></div>
                                <span className="font-18">0</span>
                                <small>Puntos</small>
                            </div>
                            </div> */}
                            <div className="circle-datos flex-none me-3">
                                <article className="text-center lh-1">
                                    <span className="font-18">0</span>
                                    <br />
                                    <small>Puntos</small>
                                </article>
                            </div>
                            <div className="list-main back-grey color-font flex-fill">
                                <ul>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill me-4">Extras</div>
                                        <div>0</div>
                                    </div>
                                    </li>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill me-4">Realizados</div>
                                        <div>0</div>
                                    </div>
                                    </li>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill me-4">Pendientes</div>
                                        <div>0</div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="chart-list py-2">
                        <div className="d-flex">
                            {/* <div className="pie-wrap me-3">
                            <div className="pie animate" style={{"--p":65,"--c":"#70CAFA"}}>
                                <div className="line-back"></div>
                                <span className="font-18">0.0</span>
                                <small>Semanas</small>
                            </div>
                            </div> */}
                            <div className="circle-datos flex-none me-3">
                                <article className="text-center lh-1">
                                    <span className="font-18">0.0</span>
                                    <br />
                                    <small>Semanas</small>
                                </article>
                            </div>
                            <div className="list-main back-grey color-font flex-fill">
                                <ul>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill">Inicio</div>
                                        <div>dd/mm/aaaa</div>
                                    </div>
                                    </li>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill">Entrega</div>
                                        <div>dd/mm/aaaa</div>
                                    </div>
                                    </li>
                                    <li>
                                    <div className="wrap">
                                        <div className="flex-fill">Fecha Real</div>
                                        <div>dd/mm/aaaa</div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
