import { Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonButtonAddItem = () => {
    return (
        <div className="plan-item flex-fill">
            <div className="names">
                <span className="plan-tit color-font-dark flex-none">
                    <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                </span>
                <Skeleton sx={{ bgcolor: '#3E3E3E' }} className="plan-item flex-fill" />
            </div>
        </div>
    )
}
