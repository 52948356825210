import { useSelector } from "react-redux"

const HasLoadingAll = () => {
    const {isLoading: epicsLoading } = useSelector((state) => state.epics)
    const { isLoading: sectionsLoading } = useSelector((state) => state.sections);
    const { isLoading: userstoriesLoading } = useSelector((state) => state.userstories);
    const { IsLoading: projectLoading } = useSelector((state) => state.project);

    // console.log(projectLoading);
    // console.log(epicsLoading);
    // console.log(sectionsLoading);
    // console.log(userstoriesLoading);

    return projectLoading || epicsLoading || sectionsLoading || userstoriesLoading;
}

const HasLoadingAllInDashboard = () => {
    const { IsLoading: projectsLoading } = useSelector(state => state.projects);
    const { IsLoading: usersLoading } = useSelector(state => state.users);

    return projectsLoading || usersLoading;
}

const HasLoadingAllWithActivities = () => {
    const {isLoading: epicsLoading } = useSelector((state) => state.epics)
    const { isLoading: sectionsLoading } = useSelector((state) => state.sections);
    const { isLoading: userstoriesLoading } = useSelector((state) => state.userstories);
    const { IsLoading: projectLoading } = useSelector((state) => state.project);
    const { isLoading: activititesLoading} = useSelector((state) => state.activities);

    return projectLoading || epicsLoading || sectionsLoading || userstoriesLoading || activititesLoading;
}


const HasLoadingAllWithActivitiesAndStatus = () => {
    const {isLoading: epicsLoading } = useSelector((state) => state.epics)
    const { isLoading: sectionsLoading } = useSelector((state) => state.sections);
    const { isLoading: userstoriesLoading } = useSelector((state) => state.userstories);
    const { IsLoading: projectLoading } = useSelector((state) => state.project);
    const { isLoading: activititesLoading, isLoadingCatalogStatus: catalogStatus } = useSelector((state) => state.activities);

    return projectLoading || epicsLoading || sectionsLoading || userstoriesLoading || activititesLoading || catalogStatus;
}



export{
    HasLoadingAll,
    HasLoadingAllInDashboard,
    HasLoadingAllWithActivities,
    HasLoadingAllWithActivitiesAndStatus
}
