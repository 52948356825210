import React from 'react'
import { ModuleType } from '../../types/ModuleType'
import { UserAvatar } from '../User/UserAvatar'
import { PermissionGate } from '../PermissionGate/PermissionGate'
import { SCOPES } from '../../types/ScopesType'

export const ResourceItem = ({ showStatistics, onActionHandler, ...rest }) => {

    const { Id, showName, Skill } = rest || {};

    return (
        <>
            <li key={Id} className={showStatistics ? 'mb-2' : ''}>
                <div className="wrap">
                    <div>
                        <UserAvatar {...rest} />
                    </div>
                    <div className="flex-fill">
                        <p className="font-18 mb-1">{showName}</p>
                        <span className="d-inline-block color-font mb-1">{Skill.NameToShow}</span>
                    </div>
                    <PermissionGate module={ModuleType.Users} scopes={[SCOPES.CanDelete]}>
                        <div>
                            <span className="font-18 color-grey-6" onClick={() => onActionHandler(Id)}>
                                <i className="icon-rc_btnn-quitar"></i>
                            </span>
                        </div>
                    </PermissionGate>
                </div>
            </li>
        </>
    )
}
