import { Grid, Typography } from '@mui/material'
import React from 'react'
import { LinearChart } from '../../../../sections/project/components/shared/LinearChart'
import { addZeroes, round } from '../../../helpers/NumberHelper'
import { GoalsType } from '../../../types/GoalsType'
import { UserAvatar } from '../../User/UserAvatar'
import { GridCustomized, GridMain, Item } from './styles'


export const ResourceGridInformationItem = ({ showStatistics, onActionHandler, ...rest }) => {

    const { showName, UserPlaningData, UserPlaningSprintData, UserSprintData, tabSelected } = rest || {};


    const PaintCellInfoCharts = ({ percent }) => {
        return (
            <>
                <Grid item container xs={6} justifyContent={'flex-end'}>
                    <Item>
                        {`${round(percent)}%`}
                    </Item>
                </Grid>
                <Grid item xs>
                    <Item>
                        <LinearChart percent={round(percent)} />
                    </Item>
                </Grid>
            </>
        );
    }

    const PaintRow = ({ title, value, meditionType, percent, diffValue, priority, valueStatus }) => {
        return (
            <>
                <Grid item xs={2.5}>
                    <Item>
                        {title}
                    </Item>
                </Grid>
                <Grid item container xs={3.5}>
                    <Grid item xs className='d-flex gap-1'>
                        <Item>
                            {`${value}${meditionType}`}
                        </Item>
                        <Item>{`${diffValue < 0 ? '-' : '+'}${round(Math.abs(diffValue))}%`}</Item>
                    </Grid>
                </Grid>
                <GridCustomized container item xs={6} className='flex-fill gap-2'>
                    <PaintCellInfoCharts percent={percent} />
                </GridCustomized>
            </>
        );
    }

    return (
        <>
            <li className="mb-2">
                <div className="d-flex align-items-center gap-10">
                    <div>
                        <UserAvatar {...rest} />
                    </div>
                    <div className='flex-fill'>
                        <Typography>
                            {showName}
                        </Typography>
                    </div>
                    <div>
                        <article className='mb-1'>
                            {/* <i className="dot-sm blue-4 me-2"></i> 
                            {/* {`${round((tabSelected === GoalsType.Planning ? UserPlaningData?.AssignedPointsInCurrentProject : tabSelected === GoalsType.PlanningSprint ? UserPlaningSprintData?.AssignedPointsInCurrentProject : UserSprintData?.AssignedPointsInCurrentProject), 2)} pts`}
                            {`(${round(tabSelected === GoalsType.Planning ? UserPlaningData?.CapacityUsedInCurrentproject : tabSelected === GoalsType.PlanningSprint ? UserPlaningSprintData?.CapacityUsedInCurrentproject : UserSprintData?.CapacityUsedInCurrentproject)}%)`} */}

                            {/* {`${round((tabSelected === GoalsType.Planning ? UserPlaningData?.PointsIndexPerWeek : UserPlaningSprintData?.PointsIndexPerWeek), 2)} p/s ${addZeroes(round((tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.DiffOfPointsIndexPerWeekAccordInitValue : UserSprintData?.UserPlanningSprintComparison?.DiffOfPointsIndexPerWeekAccordInitValue, 2)))}%`} */}

                            {`${round((tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.CurrentPointsIndexPerWeek : UserSprintData?.UserPlanningSprintComparison?.CurrentPointsIndexPerWeek), 2)} p/s`}

                        </article>
                    </div>
                </div>
                <GridMain container spacing={1} direction="row">
                    <GridCustomized item xs={12} lg={12} container direction={'column'} >
                        <Grid container item xs className='justify-content-between gap-1 flex-nowrap'>
                            <PaintRow title={`Trabajo`}
                                value={(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.CurrentPoints : UserSprintData?.UserPlanningSprintComparison?.CurrentPoints)}
                                meditionType={'p'}
                                diffValue={(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.DiffOfPointsAccordInitValue : UserSprintData?.UserPlanningSprintComparison?.DiffOfPointsAccordInitValue)}
                                percent={(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.AdvancedPointsPercent : UserSprintData?.UserPlanningSprintComparison?.AdvancedPointsPercent)} />
                        </Grid>
                        <Grid container item xs className='justify-content-between'>
                            <PaintRow title={`Costo`}
                                value={addZeroes(round(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.CurrentCost : UserSprintData?.UserPlanningSprintComparison?.CurrentCost, 2))}
                                meditionType={'h'}
                                diffValue={(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.DiffOfCostAccordInitValue : UserSprintData?.UserPlanningSprintComparison?.DiffOfCostAccordInitValue)}
                                percent={(tabSelected === GoalsType.Planning ? UserSprintData?.UserPlanningComparison?.AdvancedCostPercent : UserSprintData?.UserPlanningSprintComparison?.AdvancedCostPercent)} />
                        </Grid>
                    </GridCustomized>


                </GridMain>
            </li>
        </>
    )
}
