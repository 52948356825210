import { Tooltip } from "@mui/material";

export const ActivitySecItem = () => {
    return (
        <>
            {/* <div className="plans-items">
                <article className="d-flex align-items-center min-height-40 color-dark font-16 lh-1">
                    <strong className="flex-none me-2">US 1</strong>
                    <span>Brief del proyecto</span>
                </article>
            </div> */}
            <div className="plans-items">
                <Tooltip title="Landing page home" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 1
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Landingpage home
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Diseño" placement="top" arrow>
                    <div className="plan-item selected">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 2
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Diseño
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 3
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 4
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 5
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 6
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 7
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Registro" placement="top" arrow>
                    <div className="plan-item">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                SEC 8
                                <br />
                                <small className="d-none d-xl-inline-block">0 pts.</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Registro
                            </span>
                        </div>
                        <div className="d-inline-block d-xl-none pl-ext pb-1">
                            <small className="">Puntos 0</small>
                        </div>
                    </div>
                </Tooltip>
            </div>
            {/* <div className="plans-items">
                <div className="plan-item">
                    <div className="names" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSec-uno" aria-expanded="false" aria-controls="collapseSec-uno">
                        <span className="plan-tit color-font-dark flex-none">SEC 1</span>
                        <span className="plan-name flex-fill">Landing page home</span>
                        <small className="flex-none">0.0 semanas</small>
                    </div>
                    <div className="collapse show" id="collapseSec-uno">
                        <div className="sub-names d-flex">
                            <small className="plan-tit color-font font-12 flex-none">US:</small>
                            <div className="plan-badges-list flex-fill">
                                <span className="badges border-color-dark">US 1</span>
                                <span className="badges back-grey">US 2</span>
                                <span className="badges back-dark">US 3</span>
                                <span className="badges border-color-dark">US 4</span>
                                <span className="badges border-color-dark">US 5</span>
                            </div>
                            <small className="flex-none">Puntos 0</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="plans-items">
                <div className="plan-item">
                    <div className="names" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSec-dos" aria-expanded="false" aria-controls="collapseSec-dos">
                        <span className="plan-tit color-font-dark flex-none">SEC 2</span>
                        <span className="plan-name flex-fill">Diseño</span>
                        <small className="flex-none">0.0 semanas</small>
                    </div>
                    <div className="collapse show" id="collapseSec-dos">
                        <div className="sub-names d-flex">
                            <small className="plan-tit color-font font-12 flex-none">US:</small>
                            <div className="plan-badges-list flex-fill">
                                <span className="badges border-color-dark">US 1</span>
                                <span className="badges back-grey">US 2</span>
                                <span className="badges back-dark">US 3</span>
                            </div>
                            <small className="flex-none">Puntos 0</small>
                        </div>
                    </div>
                </div>
            </div>

            <div className="plans-items">
                <div className="plan-item">
                    <div className="names" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSec-tres" aria-expanded="false" aria-controls="collapseSec-tres">
                        <span className="plan-tit color-font-dark flex-none">SEC 3</span>
                        <span className="plan-name flex-fill">Registro</span>
                        <small className="flex-none">0.0 semanas</small>
                    </div>
                    <div className="collapse show" id="collapseSec-tres">
                        <div className="sub-names d-flex">
                            <small className="plan-tit color-font font-12 flex-none">US:</small>
                            <div className="plan-badges-list flex-fill">
                                <span className="badges border-color-dark">US 1</span>
                                <span className="badges back-grey">US 2</span>
                                <span className="badges back-dark">US 3</span>
                            </div>
                            <small className="flex-none">Puntos 0</small>
                        </div>
                    </div>
                </div>
            </div> */}
            
            <div className="add-item">
                <button className='button border-grey-dot flex-fill'>
                    <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                    {/* <span>Agregar</span> */}
                </button>
            </div>
        </>
    )
}
