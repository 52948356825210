import { createSlice } from "@reduxjs/toolkit";

export const UserStoriesSlice = createSlice({
  name: "userstories",
  initialState: {
    isLoading: true,
    hasUserStoriesChanged: false,
    userstories: [],
    totalUserStories: 0,
    isUserStoriesRelationshipCompleted: false,
    userstorySelected: 0,
    userStorySelectedChanged: false,
    userStorySelectedChangedByIndex: false,
    checkSectionRelated: false,
    canLoadSections: false,
  },
  reducers: {
    startUserStories: (state) => {
      state.isLoading = true;
    },
    startUserStoriesChanges: (state) => {
      state.hasUserStoriesChanged = true;
    },
    startUserStorySelectedChange: (state) => {
      state.userStorySelectedChanged = true;
    },
    startUserStorySelectedChangeByIndex: (state) => {
      state.s = true;
    },
    EndUserStorySelectedChange: (state) => {
      state.userStorySelectedChanged = false;
    },
    EndUserStorySelectedChangeByIndex: (state) => {
      state.userStorySelectedChangedByIndex = false;
    },
    setUserStories: (state, action) => {
      state.isLoading = false;
      state.userstories = action.payload;
      state.totalUserStories = action.payload?.length;
    },
    cleanUserStories: (state) => {
      state.isLoading = true;
      state.hasUserStoriesChanged = false;
      state.userstories = [];
      state.totalUserStories = 0;
      state.isUserStoriesRelationshipCompleted = false;
      state.userstorySelected = 0;
      state.userStorySelectedChanged = false;
    },
    checkUserStoriesRelationshipCompleted: (state, action) => {
      state.isUserStoriesRelationshipCompleted = true;

      if (action.payload.find(({ EpicsUserStory }) => EpicsUserStory.filter((relation) => relation.IsSelected).length === 0) !== undefined) {
        state.isUserStoriesRelationshipCompleted = false;
      }
    },
    setUserStorySelected: (state, action) => {
      state.userstorySelected = action.payload;
    },
    refreshUserStories: (state, action) => {
      state.hasUserStoriesChanged = false;
      state.userstories = action.payload;
      state.totalUserStories = action.payload?.length;
    },
    endLoadingUserStories: (state) => {
      state.isLoading = false;
    },
    setRefreshUserStorySelected: (state, action) => {
      state.userstorySelected = action.payload;
      //state.hasUserStoriesChanged = false;
    },
    setUserStorySelectedByIndex: (state, action) => {
      state.userstorySelected = action.payload;
    },
    EndLoadingUserStoriesChanges: (state) => {
      state.hasUserStoriesChanged = false;
    },
    startCheckSectionRelated: (state) => {
      state.checkSectionRelated = true;
    },
    EndCheckSectionRelated: (state) => {
      state.checkSectionRelated = false;
    },
    setCanLoadSections: (state, action) => {
      state.canLoadSections = action.payload;
    }

    
  },
});

export const {
  startUserStories,
  startUserStoriesChanges,
  startUserStorySelectedChange,
  startUserStorySelectedChangeByIndex,
  startCheckSectionRelated,

  setUserStories,
  setUserStorySelectedByIndex,
  setCanLoadSections,

  cleanUserStories,
  checkUserStoriesRelationshipCompleted,
  setUserStorySelected,
  refreshUserStories,
  endLoadingUserStories,

  EndUserStorySelectedChange,
  EndUserStorySelectedChangeByIndex,
  EndLoadingUserStoriesChanges,
  EndCheckSectionRelated,

  setRefreshUserStorySelected,
} = UserStoriesSlice.actions;
