import { List, ListItem, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { HavePermission } from "../../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../../ui/types/RolesType";
import { UpdateActivity, UpdateActivityNew } from "../../../slices/Activities/ActivitiesTrunk";

export const MenuResources = ({ value, row, canShowMenu }) => {
  const dispatch = useDispatch();

  const { UsersAdded } = useSelector(state => state.project);
  const { idRol } = useSelector((state) => state.authorization);
  const { activities } = useSelector((state) => state.activities);

  const { original } = row;
  const { IdActivity, IdUser } = original;

  const [anchorEl, setAnchorEl] = useState(null);

  console.log(UsersAdded, value);

  const [selectedIndex, setSelectedIndex] = useState(UsersAdded?.findIndex(item => item.Id === value));
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(canShowMenu ? event.currentTarget : '');
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    dispatch(UpdateActivityNew({ ...original, IdUser: UsersAdded[index]?.Id, showMessage: false }));

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PaintBorderByNoResource = () => {
    if (activities?.filter((act) => act.IdUser === IdUser && act.IdUser === 0 && IdActivity !== 0)?.length >= 1) {
      return 'border-danger';
    }
    return '';
  }

  console.log(UsersAdded);
  return (
    <>
      <div className={`user-circle xs ${UsersAdded && UsersAdded[selectedIndex] ? UsersAdded[selectedIndex]?.Skill?.ImgClassName : 'bg-grey-3'} ${PaintBorderByNoResource()}`}>
        <List
          component="nav"
          aria-label="Device settings"
          sx={{ bgcolor: 'background.paper' }}
        >
          <Tooltip title={PaintBorderByNoResource() !== '' ? 'Recurso no asignado' : ''} placement="bottom" arrow>

            <ListItem
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-expanded={open ? 'true' : undefined}
              className={`${UsersAdded && UsersAdded[selectedIndex] ? UsersAdded[selectedIndex]?.Skill?.ImgClassName : ' bg-grey-3'}`}
              onClick={handleClickListItem}
            >
              <ListItemText
                secondary={UsersAdded && UsersAdded[selectedIndex] ? UsersAdded[selectedIndex]?.Alias : '?'}
              />

            </ListItem>
          </Tooltip>

        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource]) && IdActivity !== 0}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {UsersAdded?.map((option, index) => (
            <MenuItem
              disabled={!canShowMenu}
              key={option.Id}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option.Name}
            </MenuItem>
          ))}
        </Menu>
      </div>

    </>
  )
}
