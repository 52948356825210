import { useSnackbar } from 'notistack';
import React from 'react'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { HasLoadingAllWithActivities } from '../../../../../ui/helpers/LoadingStatus';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { useValidationState } from '../../../hooks/useValidationState';
import { DeleteActivity } from '../../../services/activityService';
import { GetActivitiesByUserStoryAndSection, NewActivityTemp } from '../../../slices/Activities/ActivitiesTrunk';
import { AvisoEmpty } from '../../shared/AvisoEmpty';
import { SkeletonTable } from '../../skeletons/SkeletonTable';
import { TableActivitiesInHold } from './TableActivitiesInHold';

export const ActivitiesInHoldDetails = ({ section }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();


    const { idRol } = useSelector((state) => state.authorization);
    const { sectionSelected } = useSelector((state) => state.sections);
    const { userstorySelected } = useSelector((state) => state.userstories);
    const { isNewActivity, activities, canAddActivities } = useSelector((state) => state.activities);

    const { isLoadingComponents, isLoadingActivitiesComponents } = useValidationState(section)



    // const [isNewActivity, setIsNewActivity] = useState(false);
    // const [activities, setActivities] = useState([]);
    // const [activitiesSelected, setActivitiesSelected] = useState({})

    // const GetActivitiesByIdSection = (IdSection) => {
    //     if (IdSection !== 0) {
    //         GetByIdSection(IdSection).then(response => {
    //             const { status, data: { Items } } = response;

    //             const obj = {};

    //             Items.forEach((item) => {
    //                 if(item.IsPreSelected){
    //                     const key = item.IdActivity;
    //                     obj[key] = true;
    //                 }
    //             });
    //             setActivitiesSelected(obj);
    //             if (status === 200) setActivities(Items);
    //             setIsNewActivity(false);
    //             setChangedActivities(true);
    //         });
    //     }
    // }

    // useEffect(() => {
    //     if (sectionSelected && sectionSelected !== 0) {
    //         GetByIdSection(sectionSelected).then(response => {
    //             const { status, data: { Items } } = response;

    //             const obj = {};

    //             Items.forEach((item) => {
    //                 if(item.IsPreSelected){
    //                     const key = item.IdActivity;
    //                     obj[key] = true;
    //                 }
    //             });
    //             setActivitiesSelected(obj);

    //             if (status === 200) {
    //                 setActivities(Items);
    //             }
    //         });
    //     } else {
    //         setActivities([]);
    //     }
    // }, [IdSection])

    useEffect(() => {
        if (sectionSelected !== 0 && userstorySelected !== 0){
            dispatch(GetActivitiesByUserStoryAndSection(false, false));
        }
    }, [sectionSelected, userstorySelected, dispatch])


    const onNewActivity = () => {
        // setIsNewActivity(true);
        // setActivities([...activities, {
        //     IdSection: IdSection,
        //     IdActivity: 0,
        //     IdStatusActivity: 2,
        //     IdUser: 0,
        //     Name: '',
        //     Description: '',
        //     Points: 0,
        //     Priority: 0,
        //     Orden: activities.length + 1
        // }])
        dispatch(NewActivityTemp());

    }

    const onCancelNewItem = () => {
        // setIsNewActivity(false);

        // const news = activities.filter(item => item.IdActivity !== 0);
        // setActivities(news);
    }

    const onDeleteActivity = (IdActivity) => {
        QuestionDialog("¿Desea eliminar la actividad de la plataforma?", "Actividad").then((result) => {
            if (result.isConfirmed) {
                DeleteActivity(IdActivity).then(response => {
                    const { status } = response;

                    if (status === 200) {
                        enqueueSnackbar('Se ha eliminado la actividad correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                        //GetActivitiesByIdSection(sectionSelected);
                        dispatch(GetActivitiesByUserStoryAndSection(true, false, true));
                    }
                });
            }
        });
    }

    return (
        <>
            <div id='boardActivities' className="col-xl-4 flex-grow-1">
                <article className="color-dark font-16 mb-3">
                    Actividades
                </article>

                {/* {
                    (IdSection !== 0) ? (
                        <div className="table-responsive table-simple">
                            <TableActivitiesInHold
                                activities={activities}
                                activitiesSelected={activitiesSelected}
                                IdSection={IdSection}
                                onDeleteActivity={onDeleteActivity}
                                GetActivitiesByIdSection={GetActivitiesByIdSection}
                                onCancelNewItem={onCancelNewItem}
                                setIsNewActivity={setIsNewActivity}
                            />
                        </div>

                    ) : (
                        <AvisoEmpty />
                    )
                } */}
                {/* {
                    (!isNewActivity && IdSection !== 0 && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])) && (
                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={onNewActivity}>
                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                            Agregar Actividad
                        </span>)
                } */}
                {
                    (isLoadingComponents || isLoadingActivitiesComponents) ? <SkeletonTable /> : <TableActivitiesInHold />
                }

                {/* {
                    (!isNewActivity && (activities.length !== 0 || sectionSelected !== 0) && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])) && (
                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={onNewActivity}>
                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                            Agregar Actividad
                        </span>)
                } */}
            </div>
        </>
    )
}