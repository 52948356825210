import { ProjectEpicDetails } from "../epics/details/ProjectEpicDetails"
import { ProjectSectionDetails } from "../sections/details/ProjectSectionDetails"
import { ProjectUserStoryDetails } from "../userstories/details/ProjectUserStoryDetails"

export const ProjectPlanningDetailsBase = ({ section }) => {
    return (
        <>
            <ProjectEpicDetails section={section}/>
            <ProjectUserStoryDetails section={section}/>
            <ProjectSectionDetails section={section} />
        </>
    )
}
