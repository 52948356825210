import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { isEmptyOrSpaces } from "../../../../ui/helpers/StringHelper";
import { SaveUserStory } from "../../services/userStoryService";
import { NewUserStory } from "../../slices";
import { SkeletonButtonAddItem } from "../skeletons/SkeletonButtonAddItem";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";

export const ProjectUserStoryNew = ({ userStoryNum, idProject, onCancelUserStory, onChangeItems }) => {
  const [userStoryDescription, setUserStoryDescription] = useState("");
  const { message } = useSelector((state) => state.messages);
  const { hasUserStoriesChanged } = useSelector(state => state.userstories);

  const dispatch = useDispatch();
  const ref = useRef(null);


  useEffect(() => {
    console.log(message);
    if (message && message !== '') {
      setUserStoryDescription('');
    }

  }, [message])


  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!isEmptyOrSpaces(userStoryDescription)) {
        //onSaveUserStory();
        dispatch(NewUserStory(userStoryDescription, userStoryNum));
      }
    } else if (e.keyCode === 27) {
      setUserStoryDescription("");
      onCancelUserStory();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setUserStoryDescription("");
      onCancelUserStory();
    }
  };

  const onSaveUserStory = () => {
    const userStory = {
      IdProject: idProject,
      Name: `US ${userStoryNum}`,
      Description: userStoryDescription,
      Orden: userStoryNum,
    };

    SaveUserStory(userStory).then((response) => {
      const { status } = response;

      console.log(response);

      if (status === 200) {
        setUserStoryDescription("");
        onChangeItems();
      }
    });
  };

  // const onBlurFocus = () => {
  //   onCancelUserStory();
  // };

  const handleClickOutside = () => {
    // if (!isEmptyOrSpaces(epicName)) {
    //   QuestionDialog('Existe información no guardado en Épicas, ¿Desea guardarlo?', 'Épicas').then(result => {
    //     if (result.isConfirmed) {
    //       dispatch(NewEpic(epicName, EpicNum));
    //   }});
    // }else {
    //   onCancelNewEpic();
    // }
    onCancelUserStory();

  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <>
      <div 
          ref={ref}
          className="plans-items d-flex" 
          onKeyDown={handleKeyDown}
      >
        <span className="icon flex-none mt-2">
          <i className="bi bi-three-dots-vertical"></i>
        </span>
        {
          hasUserStoriesChanged ? (<SkeletonButtonAddItem />) : (
            <div className="plan-item flex-fill">
              <div className="names">
                <span className="plan-tit color-font-dark flex-none">
                  {`US ${userStoryNum}`}
                </span>
                <ControllerInput
                  value={userStoryDescription}
                  onChange={({ target: { value } }) => setUserStoryDescription(value)}
                  className="form-control"
                  onKeyDown={onKeyPress}
                />
                {/* <input
              ref={(ref) => ref && ref.focus()}
              className="form-control"
              type="text"
              placeholder="Descripción"
              onChange={({ target: { value } }) => {
                setUserStoryDescription(value);
              }}
              value={userStoryDescription}
              onKeyDown={onKeyPress}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
            /> */}
              </div>
            </div>
          )
        }

      </div>
    </>
  );
};
