import { Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonDetailItem = () => {
    return (
        <Skeleton sx={{ bgcolor: '#3E3E3E' }} className="plans-items">
            <div className="plans-items">
                <div className="plan-item">
                    <div className="names">
                        <span className=""><i className='bi bi-clock'></i></span>
                        <span className="plan-tit flex-none w-auto">EP 0</span>
                        <span className="plan-name flex-fill d-inline-block d-xl-none">Descripcion</span>
                    </div>
                </div>
            </div>
        </Skeleton>
    )
}
