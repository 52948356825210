import { useSelector } from "react-redux";

export const useProject = () => {
    // const { GetProject, CleanProject, project, saved } = useContext(ProjectContext);
    const { project, saved, sprint } = useSelector((state) => state.project);
    const { IdProject, Name, ProjectUsersList } = project || { IdProject: 0, Name: '', ProjectUsersList: [] };

    return {
        IdProject,
        Saved: saved,
        Name, 
        ProjectUsersList,
        sprint
    }
}
