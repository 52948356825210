import React from 'react'

export const CirculeChart = ({ title = '', points = '' }) => {
    return (
        <div className="circle-datos flex-none me-3">
            <article className="text-center lh-1">
                <span className="font-18">{points}</span>
                <br />
                <small>{title}</small>
            </article>
        </div>
    )
}
