/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-await */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
// @flow
// import { Alert } from 'react-native';
import { Observable, throwError, from, async } from 'rxjs';
import {
  mergeMap, retryWhen, take, delay, catchError, map,
} from 'rxjs/operators';
import axios from 'axios';
import * as Globals from '../constants/globals';

async function handleRequest(req) {

  const user = JSON.parse(localStorage.getItem('user'));

  req.headers.Accept = 'application/json';
  req.headers['Content-Type'] = 'application/json';


  if(user){
    req.headers['Authorization'] = `Bearer ${ user.Token }`;
  }

  return req;
}

/**
 * This is used to manage errors from api calls by checking needed information
 * before responding to the caller.
 * @param err
 * @returns {Observable<never>}
 */
// function errorHandler(err) {
//   // const message = Globals.errorEncountered;
//   // if (err && err.status === 0) Object.assign(err.data, { message });
//   // if (err.code === 'ECONNABORTED') Alert.alert(Globals.timeoutMessage);
//   // console.log({ err });
//   // return throwError(err);
//   console.log(err)
// }

/**
 * This is used to modify the header request and relies on some header constraints
 * to generate some header fields
 */
axios.interceptors.request.use(
  async (req) => await handleRequest(req),
  (error) => Promise.reject(error),
);

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   console.log(response);
//   errorHandler(response);
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });

/**
 * This takes in a promise and convert to an observable
 * then makes the api request, it tries the api call 2 times only if failed
 * before responding to the caller.
 * @param apiCaller
 * @returns {Observable<*>}
 */
function processApiRequest(apiCaller) {
  return from(apiCaller).pipe(
    retryWhen((errors) => errors.pipe(
      mergeMap((err) => errorHandler(err)),
      delay(1000),
      take(2),
    )),
    catchError((err) => errorHandler(err.response)),
    map((res) => res.data),
  );
}

/** *
 * Clase Handler para el manejo de peticiones API con retorno tipo Promises.
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  post: (url, data, options) => 
    axios.post(
      options && options.fullPath ? url : Globals.BaseURL + url,
      data,
      { timeout: Globals.timeoutDuration },
      options && { headers: options },
    ),

  put: (url, data, options) =>
    axios.put(
      options && options.fullPath ? url : Globals.BaseURL + url,
      data,
      { timeout: Globals.timeoutDuration },
      options && { headers: options },
    ),
  delete: (url, data, options) => {
    data = data ? (data instanceof Object && !Object.keys(data).length ? null : data) : null;
    const config = data
      ? { headers: options, data, timeout: Globals.timeoutDuration }
      : { headers: options, data: '', timeout: Globals.timeoutDuration };
    return axios.delete(options && options.fullPath ? url : Globals.BaseURL + url, config);
  },
  get: (url, body, options) => {
    body = body ? (body instanceof Object && !Object.keys(body).length ? null : body) : null;

    const config = body
      ? { headers: options, body }
      : { headers: options, body: '' };

    return axios.get(options && options.fullPath ? url : Globals.BaseURL + url, config);
  },
};