import { createSlice } from '@reduxjs/toolkit';

const initialState = () => {
    return {
        IsLoading: false,
        projects: []
    }
}

export const ProjectsSlice = createSlice({
   name: 'projects',
   initialState,
   reducers: {
      startLoadingProjects: (state) => {
         state.IsLoading = true;
      },
      setProjects: (state, action) => {
        state.IsLoading = false;
        state.projects = action.payload;
      },
      removeProjects: (state) => {
        state.IsLoading = false;
        state.projects = [];
      }
   }
});


export const { startLoadingProjects, setProjects, removeProjects } = ProjectsSlice.actions;