import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RecalculationData } from '../../../slices';
import { GetCatalogs } from '../../../slices/Activities/ActivitiesTrunk';
import { ProjectPlanningDetailsBase } from '../ProjectPlanningDetailsBase';
import { ActivitiesInHoldDetails } from './ActivitiesInHoldDetails';

export const ActivitiesInHoldBase = ({ section }) => {
    const dispatch = useDispatch();

    const { IdProject } = useSelector((state) => state.project);

    useEffect(() => {
        if (IdProject !== 0)
            dispatch(GetCatalogs());

    }, [IdProject, dispatch])


    return (
        <>
            <div className="new-project-body-top mb-4">
                <div className="row">
                    <div className="col-md-7">
                        <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                            <article className="font-18 mb-2">
                                Planeación de proyecto
                            </article>
                            <span>
                                Define el alcance y estructura
                            </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex gap-10">
                            <span className="flex-fill">
                                <input type="text" className="form-control" placeholder="Buscar" />
                            </span>
                            <button className="button grey-light font-20">
                                <i className="icon-filtro-icon_v2"></i>
                            </button>
                            <div className="dropdown dropdown-main arrow-none" onClick={() => dispatch(RecalculationData(true))}>
                                <span className="button d-flex align-items-center grey-light font-20 px-3" type="button">
                                    <span><i className="bi bi-arrow-repeat"></i></span>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='row overflowwrap'>
                <ProjectPlanningDetailsBase section={section} />

                <ActivitiesInHoldDetails section={section} />
            </div>
        </>
    )
}
