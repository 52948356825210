import React from 'react'
import { useSelector } from 'react-redux';

export const FilterItemResources = () => {
    const { UsersAdded } = useSelector(state => state.project);

    return (
        <>
            <div className="list-main back-none font-14 px-3">
                <article className="color-font mb-2">Recurso</article>
                <ul>
                    {
                        UsersAdded?.map((item) => (
                            <li key={item.Id}>
                                <div className="wrap">
                                    <div className="flex-fill">
                                        <span>{item.Name}</span>
                                    </div>
                                    <div>
                                        <span>
                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))
                    }
                    {/* <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>María Escalante</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Sergio Martínez</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Sofía Padilla</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li> */}
                </ul>
            </div>
        </>
    )
}
