import { NamesProjectsCharts } from "../../ui/components/NamesProjectsCharts"
import { NavReportsLinks } from "../../ui/components/NavReportsLinks"

import imagenes from "../../assets/imgs/imagenes";

export const DeliveriesPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="container-max">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 col-xxxl-10">
                                <div className="card-main mb-3">
                                    <NamesProjectsCharts />
                                </div>
                            </div>
                            <div className="col-lg-3 col-xxxl-2">
                                <div className="card-main mb-3">
                                    <NavReportsLinks />
                                </div>
                            </div>
                        </div>

                        <div className="card-main">
                            <div className="new-project-body-top mb-4">
                                <div className="d-flex">
                                    <div className="flex-fill gap-10">
                                        <div className="dato-name color-font">
                                            <article className="color-blue-2 font-18 mb-2">
                                                Planeación de Proyecto Sprint 1
                                            </article>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="button grey-light font-20">
                                            <i className="icon-cerrar_icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <article className="mb-3">Listado de entregas</article>

                                    <div className="deliveries-list-items">
                                        <div className="deliveries-items selected">
                                            <div>
                                                <i className="icon-ic_check font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">1. Wireframe</p>
                                                <small>Ver actualizaciones</small>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">2. Look&Feel</p>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">3. Prototipo</p>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">4. Demo</p>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">5. Sitio Pre-Producción</p>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="m-0">6. Producción</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <article className="mb-3">Historial</article>

                                    <button className="button border-grey-dot w-100 mb-3">
                                        <span className="font-14"><i className="icon-add_ic me-2"></i> Agregar</span>
                                    </button>

                                    <div className="deliveries-list-items">
                                        <div className="deliveries-items selected">
                                            <div>
                                                <i className="icon-ic_check font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="d-flex m-0">
                                                    <span>Wireframe</span>
                                                    <span>06/05/2022</span>
                                                </p>
                                                <small><strong>Nuevo </strong>“Diseño de pantallas principales de…”</small>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="d-flex m-0">
                                                    <span>Wireframe</span>
                                                    <span>06/05/2022</span>
                                                </p>
                                                <small><strong>Nuevo </strong>“Diseño de pantallas principales de…”</small>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="d-flex m-0">
                                                    <span>Wireframe</span>
                                                    <span>06/05/2022</span>
                                                </p>
                                                <small><strong>Nuevo </strong>“Diseño de pantallas principales de…”</small>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="d-flex m-0">
                                                    <span>Wireframe</span>
                                                    <span>06/05/2022</span>
                                                </p>
                                                <small><strong>Nuevo </strong>“Diseño de pantallas principales de…”</small>
                                            </div>
                                        </div>
                                        <div className="deliveries-items">
                                            <div>
                                                <i className="bi bi-clock font-18"></i>
                                            </div>
                                            <div className="flex-fill names">
                                                <p className="d-flex m-0">
                                                    <span>Wireframe</span>
                                                    <span>06/05/2022</span>
                                                </p>
                                                <small><strong>Nuevo </strong>“Diseño de pantallas principales de…”</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <article className="mb-3">Detalle entrega</article>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex color-font mb-3">
                                                <article className="flex-fill">Título de entrega</article>
                                                <small>05 Julio 2022</small>
                                            </div>
                                            <p>Evidencia</p>

                                            <div>
                                                <img src={imagenes.imgDeliveries} alt="" className="w-100" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="d-flex color-font mb-3">
                                                <article className="flex-fill">&nbsp;</article>
                                            </div>
                                            <p>Comentarios</p>

                                            <div className="deliveries-comments mb-3">
                                                Diseño de pantallas principales de flujo 1, falta revisar ajustes que se vieron en la junta del miércoles 13 de mayo.

                                                <br /><br />

                                                http://adobexd.com/239487234-image
                                            </div>

                                            <div className="d-flex mb-3">
                                                <span className="flex-fill">
                                                    Marcar como aprobada
                                                </span>
                                                <span>
                                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                </span>
                                            </div>

                                            <div>
                                                <button className="button dark w-100">Guardar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
