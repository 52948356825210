import { Tooltip } from '@mui/material'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { usePersistData } from '../../../hooks/usePersistData';
import { ChangeEpicSelected } from '../../../slices';
import { CancelOperation } from '../../../slices/Activities/ActivitiesTrunk';
import { projectStatusType } from '../../../types/projectStatusType';

export const ProjectEpicDetailsItem = ({ ...rest }) => {
    const dispatch = useDispatch();

    const { hasActivityNoSaved } = usePersistData();

    const { epicSelected } = useSelector(state => state.epics);
    const { activities } = useSelector((state) => state.activities);
    const { IdProjectStatus } = useSelector((state) => state.project);

    const [record, setRecord] = useState(0);

    const { IdEpic, Description, index, CurrentPoints } = rest;
    console.log(rest);

    // const GetPointByStatus = () => {
    //     switch (IdProjectStatus) {
    //         case projectStatusType.IN_PLANNING:
    //         case projectStatusType.IN_ACTIVITY_DEFINITION:
    //             return CurrentPoints;
    //         case projectStatusType.IN_HOLD:
    //             return PlanningSprintPoints;
    //         default:
    //             return '0.0.0'
    //     }
    // }
    const onClickHandler = () => {
        if(activities?.filter(act => act.IdActivity === 0).length > 0){
            QuestionDialog('Hay actividades con cambios pendientes de guardar, ¿Desea descartar los cambios?', 'Actividades').then(result => {
                if(result.isConfirmed){
                    dispatch(CancelOperation());
                    dispatch(ChangeEpicSelected(IdEpic));
                }
            });
        }else{
            dispatch(ChangeEpicSelected(IdEpic));
        }
    }

    useEffect(() => {
        setRecord(epicSelected);
    }, [epicSelected])

    return (
        <>
            <div className="plans-items" onClick={onClickHandler}>
                <Tooltip title={Description} placement="right" arrow >
                    <div className={IdEpic === record ? "plan-item selected" : "plan-item"}>
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit text-nowrap w-auto">
                                {`EP ${index}`}
                                <br />
                                <small className="d-none d-xl-inline-block">{CurrentPoints} p</small>
                            </span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">{Description}</span>
                        </div>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
