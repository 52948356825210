import { ActivityEpicItem } from "./ActivityEpicItem"
import { ActivitySecItem } from "./ActivitySecItem"
import { ActivityTableItem } from "./ActivityTableItem"
import { ActivityUserStoriesItem } from "./ActivityUserStoriesItem"


export const ActivitiesDetails = () => {
    return (
        <>
            <div className="new-project-body-top mb-4">
                <div className="row">
                    <div className="col-md-7">
                        <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                            <div className="dropdown dropdown-main">
                                <span className="color-blue-2 font-18 dropdown-toggle mb-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    Planeación de Proyecto Sprint 1
                                </span>
                                
                                <div className="dropdown-menu w-75">
                                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                        <span className="flex-fill font-14">Sprints</span>
                                        <span><i className="icon-cerrar_icon"></i></span>
                                    </article>
                                    <hr className="" />

                                    <div className="list-main back-none px-3 mb-3">
                                        <ul className="font-14">
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span className="color-blue-2 flex-fill">Sprint 1</span>
                                                    </div>
                                                    <div>
                                                        <span className="flex-none">
                                                            354 <span className="color-font">/356 pts.</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="color-blue-2">99%</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span className="color-green flex-fill">Sprint 2</span>
                                                    </div>
                                                    <div>
                                                        <span className="flex-none">
                                                            100 <span className="color-font">/356 pts.</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="color-green">70%</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <article className="d-flex align-items-center gap-10 px-3">
                                        <span className="flex-fill font-14">Planear nuevo sprint</span>
                                        <span><i className="icon-rc_btnn-add"></i></span>
                                    </article>
                                </div>
                            </div>
                            <span className="d-block">
                                Define el alcance y estructura
                            </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex gap-10">
                            <span className="flex-fill">
                                <input type="text" className="form-control" placeholder="Buscar" />
                            </span>

                            <div className="dropdown dropdown-main arrow-none">
                                <span className="button d-flex align-items-center grey-light font-20 px-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    <span><i className="icon-filtro-icon_v2"></i></span>
                                </span>
                                
                                <div className="dropdown-menu w-75">
                                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                        <span className="flex-fill font-14">Filtrar</span>
                                        <span><i className="icon-cerrar_icon"></i></span>
                                    </article>
                                    <hr className="" />

                                    <div className="list-main back-none font-14 px-3">
                                        <article className="color-font mb-2">Estado</article>
                                        <ul>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>En espera</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>En proceso</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Detenida</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Finalizada</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Cancelada</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <hr className="" />
                                    
                                    <div className="list-main back-none font-14 px-3">
                                        <article className="color-font mb-2">Recurso</article>
                                        <ul>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>María Escalante</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Sergio Martínez</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Sofía Padilla</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <hr className="" />
                                    
                                    <div className="list-main back-none font-14 px-3 mb-3">
                                        <article className="color-font mb-2">Tareas extras</article>
                                        <ul>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Interno</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap">
                                                    <div className="flex-fill">
                                                        <span>Externa</span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="dropdown dropdown-main arrow-none">
                                <span className="button d-flex align-items-center grey-light font-20 px-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    <span><i className="icon-ic_move-active"></i></span>
                                </span>
                                
                                <div className="dropdown-menu w-75">
                                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                        <span className="flex-fill font-14">Opciones</span>
                                        <span><i className="icon-cerrar_icon"></i></span>
                                    </article>
                                    <hr className="" />

                                    <div className="list-main back-none font-14 px-3">
                                        <ul>
                                            <li>
                                                <div className="wrap color-font">
                                                    <div className="flex-fill">
                                                        <span>Archivo</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap color-font">
                                                    <div className="flex-fill">
                                                        <span>Detener proyecto</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row overflowwrap">
                <div className="col-md flex-xl-grow-0">
                    <article className="color-dark font-16 mb-3">
                        Epicas
                    </article>
                    
                    <div className="overflowarea">
                        <div className="add-plans-items">
                            <ActivityEpicItem />
                        </div>
                    </div>
                </div>

                <div className="col-md flex-xl-grow-0">
                    <article className="color-dark font-16 text-nowrap mb-3">
                        User Stories
                    </article>
                    
                    <div className="overflowarea">
                        <div className="add-plans-items">
                            <ActivityUserStoriesItem />
                        </div>
                    </div>
                </div>

                {/* <div className="col-md col-xl-4"> */}
                <div className="col-md flex-xl-grow-0">
                    <article className="color-dark font-16 mb-3">
                        Secciones
                    </article>
                    
                    <div className="overflowarea">
                        <div className="add-plans-items">
                            <ActivitySecItem />
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 flex-grow-1">
                    <article className="color-dark font-16 mb-3">
                        Actividades
                    </article>

                    <div className="overflowarea">
                        <ActivityTableItem />
                    </div>
                </div>
            </div>
        </>
    )
}
