import { memo } from "react";
import { ProjectChartPoints } from "./ProjectChartPoints";
import { ProjectChartWeeks } from "./ProjectChartWeeks";
import { ProjectName } from "./ProjectName";

export const ProjectHeader = memo(function Header({section}) {

  return (
    <>
      <div className="new-project-top">
        <div className="namesprojects-charts d-flex align-items-center gap-20 flex-wrap flex-xxl-nowrap">
          <ProjectName section={section}/>
          <ProjectChartPoints section={section}/>
          <ProjectChartWeeks section={section}/>
        </div>
      </div>
    </>
  );
});
