import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, Typography } from '@mui/material';
import { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { QuestionDialog } from '../../../../../../ui/components/Dialogs';
import { AddOrRemoveActivitiesSelected, NewActivityTemp } from '../../../../slices/Activities/ActivitiesTrunk';
import { projectStatusType } from '../../../../types/projectStatusType';
import { BOARD_SECTIONS } from '../constants';
import Button, { GreenSwitch, GridCustomized } from './Styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StatusActivitiesType } from '../types';
import { EnhancedTable } from '../Table/EnhancedTable';
import { PermissionGate } from '../../../../../../ui/components/PermissionGate/PermissionGate';
import { SCOPES } from '../../../../../../ui/types/ScopesType';
import { ModuleType } from '../../../../../../ui/types/ModuleType';


export const BoardSections = ({ boardID, activities, IsSprint = false }) => {
    const dispatch = useDispatch();
    // const { setNodeRef } = useDroppable({ id: boardID });
    const { sectionSelected } = useSelector((state) => state.sections);
    const { IdProjectStatus } = useSelector((state) => state.project);
    const { isNewActivity } = useSelector((state) => state.activities);

    const [records, setRecords] = useState([]);
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    const [disabledSwitch, setDisabledSwitch] = useState(false);

    // const IsDisabledSwitch = () => !(activities?.filter(act => act.IdStatusActivity === StatusActivitiesType.Finalizada).length >= 0 && boardID === BOARD_SECTIONS['En proceso'])

    useEffect(() => {
        var haveFinalizadas = activities.filter(act => act.IdStatusActivity === StatusActivitiesType.Finalizada).length > 0 && boardID === BOARD_SECTIONS['En proceso']
        console.log(haveFinalizadas);
        setDisabledSwitch(!(haveFinalizadas));
        setCheckedSwitch(haveFinalizadas);

        setRecords((checkedSwitch || haveFinalizadas || boardID === BOARD_SECTIONS.Finalizadas) ? activities : activities?.filter(act => act.IdStatusActivity !== StatusActivitiesType.Finalizada));

    }, [activities])

    const handlerClick = (from, to) => {
        QuestionDialog(`¿Desea mover todas la actividades de ${from} a ${to}?`, 'Actividades').then(result => {
            if (result.isConfirmed) {
                const ids = activities.map(act => (act.IdActivity));
                dispatch(AddOrRemoveActivitiesSelected(ids, to === BOARD_SECTIONS['En proceso'] ? true : false));
            }
        });
    }

    const handleChange = ({ target: { checked } }) => {
        setCheckedSwitch(checked);
        setRecords((checked || boardID === BOARD_SECTIONS.Finalizadas) ? activities : activities.filter(act => act.IdStatusActivity !== StatusActivitiesType.Finalizada));
    };

    const IsExpandedBoard = () => {
        if (IdProjectStatus === projectStatusType.IN_ACTIVITY_DEFINITION) {
            return boardID === BOARD_SECTIONS.Backlog ? true : false;
        }
        else if (IdProjectStatus === projectStatusType.IN_HOLD) {
            return boardID !== BOARD_SECTIONS.Finalizadas ? true : false;
        }
        else if (IdProjectStatus === projectStatusType.IN_SPRINT) {
            return boardID === BOARD_SECTIONS['En proceso'] ? true : false;
        }
    }
    const [expanded, setExpanded] = useState(IsExpandedBoard());


    const handleExpandedChange = () => {
        setExpanded(!expanded);
    };




    return (
        <Accordion expanded={expanded} onChange={() => handleExpandedChange()}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <article className="color-dark">
                    <Typography>
                        {
                            boardID === BOARD_SECTIONS.Backlog ? BOARD_SECTIONS.Backlog : boardID === BOARD_SECTIONS.Finalizadas ? 'Finalizadas' : 'Sprint'
                        }
                    </Typography>
                </article>

            </AccordionSummary>
            <AccordionDetails>
                <GridCustomized item container alignItems={'baseline'}>
                    <Grid item container xs justifyContent={'flex-end'}>
                        {
                            (IdProjectStatus === projectStatusType.IN_SPRINT && boardID === BOARD_SECTIONS['En proceso']) && <Grid xs item >
                                <Grid item xs sx={{ paddingRight: 5 }} container direction="row" justifyContent='flex-end'>
                                    <FormControlLabel
                                        control={<GreenSwitch disabled={disabledSwitch} checked={(checkedSwitch)} onChange={handleChange} />}
                                        label="Finalizadas"
                                        labelPlacement="start"
                                    />
                                </Grid>

                            </Grid>
                        }

                        <Grid item alignContent={'baseline'} >
                            {
                                (boardID === BOARD_SECTIONS.Backlog) ? (
                                    <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ disabled: true }}>
                                        <Button
                                            disabled={!(activities.length > 0 && !(IdProjectStatus === projectStatusType.IN_ACTIVITY_DEFINITION))}
                                            onClick={() => handlerClick(BOARD_SECTIONS.Backlog, BOARD_SECTIONS['En proceso'])}>
                                            <i className='bi bi-arrow-up'></i> Actividades
                                        </Button>
                                    </PermissionGate>
                                ) : null
                            }
                            {
                                (boardID === BOARD_SECTIONS['En proceso']) ? (
                                    <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanUpdate]} errorProps={{ disabled: true }}>
                                        <Button
                                            disabled={!(activities.length > 0)}
                                            onClick={() => handlerClick(BOARD_SECTIONS['En proceso'], BOARD_SECTIONS.Backlog)}>
                                            <i className='bi bi-arrow-down'></i> Actividades
                                        </Button>
                                    </PermissionGate>
                                ) : null
                            }
                        </Grid>
                    </Grid>

                    <EnhancedTable rows={records} boardID={boardID} IsSprint={IsSprint} />

                    {
                        (IdProjectStatus === projectStatusType.IN_ACTIVITY_DEFINITION && boardID === BOARD_SECTIONS['En proceso']) &&
                        <Grid item container xs direction="row" sx={{ backgroundColor: 'var(--color-grey-2)' }} justifyContent='center' alignItems="center">
                            <Typography variant="h5" sx={{ mb: 2, color: 'var(--color-font)', margin: 5, textAlign: 'center' }}>
                                Confirma la planeación del proyecto para poder agregar actividades.
                            </Typography>


                        </Grid>
                    }
                    {
                        (IdProjectStatus === projectStatusType.IN_HOLD && boardID === BOARD_SECTIONS['En proceso'] && activities?.length === 0) &&
                        <Grid item container xs direction="row" sx={{ backgroundColor: 'var(--color-grey-2)' }} justifyContent='center' alignItems="center">
                            <Typography variant="h5" sx={{ mb: 2, color: 'var(--color-font)', margin: 5, textAlign: 'center' }}>
                                Arrastra actividades del Backlog para agregarlas al Sprint.
                            </Typography>
                        </Grid>
                    }
                    {
                        <Grid item>
                            <PermissionGate module={ModuleType.Activitites} scopes={[SCOPES.CanCreate]}>
                                {
                                    (boardID === BOARD_SECTIONS.Backlog && !isNewActivity && (activities.length !== 0 || sectionSelected !== 0)) && (
                                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={() => dispatch(NewActivityTemp())}>
                                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                                            Agregar Actividad
                                        </span>)
                                }
                            </PermissionGate>
                        </Grid>
                    }
                </GridCustomized>
            </AccordionDetails>
        </Accordion>
    )
}
