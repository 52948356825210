import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';


export const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        {
            id: 'Name',
            numeric: false,
            disablePadding: true,
            class: 'text-start w-75',
            label: 'Actividades',
        },
        {
            id: 'OriginActivity',
            numeric: false,
            disablePadding: true,
            class: 'text-center',
            label: 'Extra',
        },
        {
            id: 'Points',
            numeric: false,
            disablePadding: true,
            class: 'text-center',
            label: 'Puntos',
        },
        {
            id: 'IdUser',
            numeric: false,
            disablePadding: true,
            class: 'text-center',
            label: 'Recurso',
        },
        {
            id: 'IdStatusActivity',
            numeric: false,
            disablePadding: true,
            class: 'text-center',
            label: 'Estado',
        },
        {
            id: 'Priority',
            numeric: false,
            disablePadding: true,
            class: 'text-center',
            label: 'Prioridad',
        },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className={headCell.class ? headCell.class : ''}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};
