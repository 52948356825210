import { Skeleton } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { HasLoadingAll } from '../../../../../ui/helpers/LoadingStatus';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { useValidationState } from '../../../hooks/useValidationState';
import { onUpdateProjectStatus } from '../../../services/projectService';
import { GetProject, GetSections, GetSectionsByUserStory, UpdateUrl } from '../../../slices';
import { projectStatusType } from '../../../types/projectStatusType';
import { SkeletonDetailItem } from '../../skeletons/SkeletonDetailItem';
import { ProjectSectionDetailsItem } from './ProjectSectionDetailsItem'
import { PermissionGate } from '../../../../../ui/components/PermissionGate/PermissionGate';
import { ModuleType } from '../../../../../ui/types/ModuleType';
import { SCOPES } from '../../../../../ui/types/ScopesType';

export const ProjectSectionDetails = ({ section }) => {

    const { idRol } = useSelector((state) => state.authorization);
    const { IdProject } = useSelector(state => state.project);
    const { sections } = useSelector((state) => state.sections);
    const { hasActivitiesChanged } = useSelector((state) => state.activities);
    const { epicSelected } = useSelector((state) => state.epics);

    const { isLoadingSectionsComponents, isLoadingComponents } = useValidationState(section)

    const dispatch = useDispatch();

    const [records, setRecords] = useState([]);

    useEffect(() => {
        if (epicSelected !== 0) {
            dispatch(GetSectionsByUserStory());
        }
    }, [epicSelected, dispatch])

    // useEffect(() => {
    //     if (userstorySelected !== 0 && sectionSelected === 0) {
    //         console.log('Entra qui');
    //         dispatch(GetSectionsByUserStory());
    //     }
    // }, [userstorySelected, sectionSelected, dispatch])


    // useEffect(() => {
    //     if (epicSelected !== 0 && canLoadSections && userstorySelected !== 0) {
    //         dispatch(GetSectionsByUserStory(true));
    //     }
    // }, [epicSelected, canLoadSections, userstorySelected, dispatch])


    useEffect(() => {
        setRecords(sections);
    }, [sections])

    useEffect(() => {
        console.log(hasActivitiesChanged);
        if (hasActivitiesChanged) {
            dispatch(GetSections());
        }
    }, [hasActivitiesChanged, dispatch])

    const onBackPlanning = () => {
        onUpdateProjectStatus(IdProject, projectStatusType.IN_PLANNING).then(() => {
            //navigate(`/project/${IdProject}/planning/definitions`)
            dispatch(UpdateUrl(`/project/${IdProject}/planning/definitions`))
            dispatch(GetProject(IdProject, false))
        });
    }

    console.log(records);

    return (
        <>
            <div className="col-md flex-xl-grow-0">
                <article className="color-dark font-16 mb-3">
                    Secciones
                </article>

                <div className="add-plans-items">
                    {/* {
                        records.map(item => (
                            <ProjectSectionDetailsItem
                                key={item.IdSection}
                                {...item}
                            />
                        ))
                    } */}

                    {
                        ((isLoadingComponents || isLoadingSectionsComponents) ? Array.from(new Array(5)) : records).map((item, index) => (
                            <Fragment key={index}>
                                {
                                    item ? (
                                        <ProjectSectionDetailsItem key={item.IdSection} {...item} />
                                    ) : (
                                        <SkeletonDetailItem />
                                    )
                                }
                            </Fragment>
                        ))

                        // epics.map(item => (
                        //     <ProjectEpicDetailsItem key={item.IdEpic} {...item} />
                        // ))
                    }

                    <div className="add-item">
                        {/* <button disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                            <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                        </button> */}

                        {
                            (isLoadingComponents || isLoadingSectionsComponents) ? (
                                <Skeleton sx={{ bgcolor: '#3E3E3E' }} >
                                    <button disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                                        <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                                    </button>
                                </Skeleton>
                            ) : (
                                <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]}>
                                    <button className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                                        <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                                    </button>
                                </PermissionGate>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}