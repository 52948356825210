import { Link } from "react-router-dom"
import { NamesProjectsCharts } from "../../ui/components/NamesProjectsCharts"
import { NavReportsLinks } from "../../ui/components/NavReportsLinks"
import { ProjectsDetails } from "../new-project/components/ProjectsDetails"
import { ActivitiesDetails } from "./components/ActivitiesDetails"

export const ActivitiesPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="container-max">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 col-xxxl-10">
                                <div className="card-main mb-3">
                                    <NamesProjectsCharts />
                                </div>
                            </div>
                            <div className="col-lg-3 col-xxxl-2">
                                <div className="card-main mb-3">
                                    <NavReportsLinks />
                                </div>
                            </div>
                        </div>
                        
                        <div className="container-plans">
                            <div className="row height-plans">
                                <div className="col-lg-9 col-xxxl-10 mb-3 height-100">
                                    <div className="card-main height-100">
                                        {/* <ProjectsDetails /> */}
                                        <ActivitiesDetails />
                                    </div>
                                </div>
                                
                                <div className="col-lg-3 col-xxxl-2 mb-3 height-100">
                                    <div className="card-main border-dark overflow-card-resources mb-3">
                                        <div className="estimacion">
                                            <article className="color-font text-center mb-3">
                                                <span className="font-16 mb-1">Trabajo Sprint 1</span>
                                                <br />
                                                <small>
                                                    09 Enero - 23 Enero 2022
                                                </small>
                                            </article>

                                            <div className="charts-wrap doble mb-3">
                                                <div className="circle-datos lg">
                                                </div>
                                                <div className="position-absolute top-50 start-50 translate-middle">
                                                    <div className="circle-datos md"></div>
                                                </div>
                                                <div className="position-absolute top-50 start-50 translate-middle">
                                                    <article className="text-center lh-1">
                                                        <span className="font-18">0.0</span>
                                                        <br />
                                                        <small>Semanas</small>
                                                    </article>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mb-3">
                                                <span>
                                                    <i className="dot-sm blue-1 me-2"></i>
                                                    Tiempo
                                                </span>
                                                <span>
                                                    <hr className="vert mx-4" />
                                                </span>
                                                <span>
                                                    <i className="dot-sm blue-4 me-2"></i>
                                                    Puntos
                                                </span>
                                            </div>
                                        </div>


                                        {/* <div className="d-flex">
                                            <span className="flex-fill font-20">Recursos XScrum</span><span className="font-20"> 2 </span>
                                        </div>
                                        <a className="color-font" href="/dashboard">+ Agregar recurso</a>
                                        <input className="form-control mt-3" type="text" placeholder="Buscar" name="searchText"></input> */}



                                        <article className="mb-2">
                                            <span className="flex-fill font-20 me-2">Recursos</span>
                                            <i className="icon-ic_editar"></i>
                                        </article>

                                        <div className="overflowresources sm">
                                            <div className="list-main borders-bottom">
                                                <ul>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-1">SP</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">Sofia Padilla</p>
                                                                <small className="color-font">Diseñador</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">20 pts.</p>
                                                                <small className="color-font">Obj. 25 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-4">ME</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">María Escalante</p>
                                                                <small className="color-font">Desarrollador Back</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">30 pts.</p>
                                                                <small className="color-font">Obj. 30 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-3">SM</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">Sergio Martínez</p>
                                                                <small className="color-font">Desarrollador iOS</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">30 pts.</p>
                                                                <small className="color-font">Obj. 25 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-3">SM</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">Sergio Martínez</p>
                                                                <small className="color-font">Desarrollador iOS</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">30 pts.</p>
                                                                <small className="color-font">Obj. 25 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-3">SM</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">Sergio Martínez</p>
                                                                <small className="color-font">Desarrollador iOS</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">30 pts.</p>
                                                                <small className="color-font">Obj. 25 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="wrap">
                                                            <div>
                                                                <div className="user-circle sm bg-gradient-3">SM</div>
                                                            </div>
                                                            <div className="flex-fill">
                                                                <p className="font-18 mb-1">Sergio Martínez</p>
                                                                <small className="color-font">Desarrollador iOS</small>
                                                            </div>
                                                            <div className="flex-none text-end">
                                                                <p className="font-18 mb-1">30 pts.</p>
                                                                <small className="color-font">Obj. 25 pts.</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>



                                        {/* <button className="button dark w-100 mt-3">
                                            Finalizar Sprint
                                        </button> */}
                                    </div>
                                    <button className="button dark w-100">
                                        Finalizar Sprint
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* confirmacion */}
            {/* <div className="confirmacion show">
                <div className="row align-items-center flex-fill">
                    <div className="col-9">
                        <article className="color-white font-18">
                            <span className="me-2"><i className="icon-finalizado-icon"></i></span>
                            Planeación creada con éxito, selecciona las actividades por sprint
                        </article>
                    </div>
                    <div className="col-3">
                        <button className="button dark w-100">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    )
}
