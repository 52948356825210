import { GetErrorResponse } from "../../../../ui/helpers/ErrorHelper";
import {
  cleanMessageError,
  setMessageError,
} from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import {
  clearMessage,
  setMessage,
} from "../../../../ui/store/slices/MessageSlice/MessageSlice";
import { MessageType } from "../../../../ui/types/MessageType";
import {
  DeleteEpic,
  GetEpicsByIdProject,
  SaveEpic,
  UpdateEpic,
  UpdateStatusEpic,
} from "../../services/epicService";
import {
  cleanActivities,
  setActivities,
  setAddActivities,
} from "../Activities/ActivitiesSlice";
import { cleanSections, setSections, setSectionSelected } from "../Sections/SectionsSlice";
import {
  cleanUserStories,
  setUserStories,
  setUserStorySelected,
} from "../UserStories/UserStoriesSlice";
import {
  AddEpic,
  EndLoadingEpics,
  setEpicsUpdated,
  removeEpic,
  setEpics,
  setEpicSelected,
  setNewEpicOrden,
  setRefreshEpiceSelected,
  setUpdateEpic,
  startEpicsChanges,
  startEpics,
  startEpicSelectedChange,
  EndLoadingChanges,
  EndEpicSelectedChanged,
} from "./EpicsSlice";

// const GetEpics = (IdProject = 0, IsFirstLoad = false) => {
//   return async (dispatch, getState) => {
//     dispatch(clearMessage());
//     dispatch(cleanMessageError());

//     const {
//       project: { IdProject },
//     } = getState();

//     dispatch(startEpics());

//     await GetEpicsByIdProject(IdProject).then((response) => {
//       const {
//         status,
//         data: { Items },
//       } = response;

//       if (status === 200) {
//         setTimeout(() => {
//           dispatch(setEpics(Items));

//           if (IsFirstLoad) dispatch(setEpicSelected(Items[0]?.IdEpic));
//         }, 1000);
//       }
//     });
//   };
// };

const GetEpics = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject } } = getState();
    await GetEpicsByIdProject(IdProject)
    .then((response) => {
      const { data: { Items } } = response;
      console.log(Items);
      dispatch(setEpics(Items));
    });
  }
}

const GetEpicsByProject = (IsRefresh = false, IsFirstLoad = false) => {
  return async (dispatch, getState) => {
    // dispatch(clearMessage());
    // dispatch(cleanMessageError());
    dispatch(cleanUserStories());
    dispatch(cleanSections());
    dispatch(setAddActivities(false));

    const { project: { IdProject } } = getState();

    if (IsRefresh) dispatch(startEpicsChanges());
    else dispatch(startEpics());

    await GetEpicsByIdProject(IdProject).then((response) => {
        const { data: { Items } } = response;
        if (IsRefresh) dispatch(setEpicsUpdated(Items));
        else dispatch(setEpics(Items));

        if (IsFirstLoad) {
          dispatch(setEpicSelected(Items[0]?.IdEpic));
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(EndLoadingEpics());
      });
  };
};

const NewEpic = (description, orden) => {
  return async (dispatch, getState) => {
    dispatch(startEpicsChanges());

    const { project: { IdProject }, } = getState();

    const newEpic = {
      IdProject: IdProject,
      Name: `EP ${orden}`,
      Description: description,
      Orden: orden,
    };

    SaveEpic(newEpic)
      .then((response) => {
        const { data: { AffectedId, Message } } = response;

        dispatch(AddEpic({ IdEpic: AffectedId, ...newEpic }));
        dispatch(setMessage({ message: Message, messageType: MessageType.success }));
        dispatch(setEpicSelected(AffectedId));
      })
      .catch((error) => {
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status
          })
        );
      }).finally(() => {
        dispatch(EndLoadingChanges());
      });
  };
};

const UpdateEpicsOrden = (newOrderItems, oldOrdenItems) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage());
    dispatch(cleanMessageError());

    const { project: { IdProject }, } = getState();

    dispatch(setEpicsUpdated(newOrderItems));

    newOrderItems = newOrderItems.map((item, index) => {
      return { ...item, Orden: index };
    });

    const orderUpdate = newOrderItems.map((item, index) => ({
      IdEpic: item.IdEpic,
      Orden: index,
    }));

    UpdateStatusEpic(IdProject, { EpicsOrdered: orderUpdate })
      .then(() => {
        dispatch(GetEpicsByProject(true));
        dispatch(setMessage({ message: "Se han actualizado el orden de las épicas correctamente.", messageType: MessageType.success}));
      })
      .catch((error) => {
        dispatch(setEpicsUpdated(oldOrdenItems));
        dispatch(setMessageError({ error: GetErrorResponse(error), errorType: MessageType.error }));
      });
  };
};

const RemoveEpic = (idEpic) => {
  return async (dispatch, getState) => {
    dispatch(removeEpic(idEpic));

    // dispatch(startChanges());
    // dispatch(clearMessage());
    // dispatch(cleanMessageError());

    // DeleteEpic(idEpic)
    //   .then((response) => {
    //     const { status } = response;

    //     if (status === 200) {
    //       dispatch(setEpicSelected(0));
    //       dispatch(removeEpic(idEpic));
    //       dispatch(setMessage("Se ha eliminado la épica correctamente."));
    //     }
    //   })
    //   .catch((error) => {
    //     const message =
    //       error.response && error.response.data && error.response.data.message;

    //     dispatch(setMessageError(`${message}`));
    //   });
  };
};

const UpdateEpicItem = (idEpic, name, description, orden) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage());
    dispatch(cleanMessageError());

    const {
      project: { IdProject },
    } = getState();

    UpdateEpic(idEpic, {
      IdProject: IdProject,
      Name: name,
      Description: description,
      Orden: orden,
    })
      .then((response) => {
        const {
          status,
          data: { AffectedId },
        } = response;

        console.log(response);

        if (status === 200) {
          //setEpicDescription(e.target.value);
          //setStatus(false);
          dispatch(setEpicSelected(idEpic));
          dispatch(
            setUpdateEpic({
              IdEpic: idEpic,
              IdProject: IdProject,
              Name: name,
              Description: description,
              Orden: orden,
            })
          );
          //onChangeItems();
          dispatch(setMessage("Se ha actualizado la épica correctamente."));
        }
      })
      .catch((error) => {
        console.log(error);
        const message =
          error.response && error.response.data && error.response.data.message;

        dispatch(setMessageError(`${message}`));
      });
  };
};

const ChangeEpicSelected = (idEpic) => {
  return async (dispatch, getState) => {
    const { epics: { epicSelected }, } = getState();

    if (epicSelected !== idEpic) {
      dispatch(startEpicSelectedChange());

      dispatch(setUserStorySelected(0));
      dispatch(setUserStories([]));

      dispatch(setSectionSelected(0));
      dispatch(setSections([]));

      dispatch(setActivities([]));
      //dispatch(cleanActivities());

      console.log(getState());

      setTimeout(() => {
        dispatch(setEpicSelected(idEpic));
        dispatch(EndEpicSelectedChanged());
      }, 100);
    }
  };
};

export {
  GetEpics,
  GetEpicsByProject,
  NewEpic,
  UpdateEpicsOrden,
  RemoveEpic,
  UpdateEpicItem,
  ChangeEpicSelected,
};
