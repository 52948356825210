import { Menu } from '@mui/material';
import { MenuItemFilter } from './MenuItemFilter';
import { FilterType } from '../../../../../ui/types/FilterType';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: '1px solid #000';
  border-radius: 4px;
  color: '#000';
  cursor: pointer;
  font-size: 15px;
`;

export const MenuFilter = () => {

    const { statusActivities, originActivities } = useSelector((state) => state.activities);
    const { filtersByStatusActivityIds, filtersByResourcesIds, filtersByOriginActivityIds } = useSelector((state) => state.activities);

    const { UsersAdded } = useSelector(state => state.project);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="dropdown dropdown-main arrow-none">
            <span
                className="button d-flex align-items-center grey-light font-20 px-3 dropdown-toggle"
                type="button"
                aria-controls={open ? 'filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <span><i className="icon-filtro-icon_v2"></i></span>
            </span>
            <div className="dropdown-menu w-75">


                <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                        <span className="flex-fill font-14">Filtrar</span>
                    </article>

                    <hr className="" />

                    <MenuItemFilter filterType={FilterType.ActivityStates} items={statusActivities} />

                    <hr className="" />

                    <MenuItemFilter filterType={FilterType.Resources} items={UsersAdded} />

                    <hr className="" />

                    <MenuItemFilter filterType={FilterType.ExtraTask} items={originActivities} />

                    <hr className="" />

                    <Button onClick={handleClose}>
                        <i className='bi bi-search'></i> Aplicar
                    </Button>

                </Menu>


            </div>

        </div>
    )
}
