import React from "react";
import { useState } from "react";
import { ResourceNew } from "../../../sections/dashboard/components/ResourceNew";
import { ResourceList } from "../../../sections/dashboard/components/ResourceList";
import { SectionsType } from "../../types/SectionsType";
import { ProjectResourceAdd } from "../../../sections/project/components/resources/ProjectResourceAdd";
import { ProjectResourceAdded } from "../../../sections/project/components/resources/ProjectResourceAdded";
import { ProjectResourceDetailsAdded } from "../../../sections/project/components/resources/ProjectResourceDetailsAdded";
import { useSelector } from "react-redux";
import { GoalsType } from "../../types/GoalsType";
import { memo } from "react";


export const Resources = memo(function Resources({ resourceSection = SectionsType.Dashboard }) {
  const [isNewResource, setIsNewResource] = useState(false);
  const { sprint, planning, planningsprint, UsersAdded, saved, IdProject } = useSelector((state) => state.project);
  const { idRol } = useSelector((state) => state.authorization);

  const showComponentBySection = () => {
    switch (resourceSection) {
      case SectionsType.Dashboard:
        return isNewResource ? <ResourceNew setIsNewResource={setIsNewResource} /> : <ResourceList setIsNewResource={setIsNewResource}/>;
      case SectionsType.Project:
      case SectionsType.NewProject:
        return isNewResource ?
          <ProjectResourceAdd setIsAddResources={setIsNewResource} />
          : <ProjectResourceAdded
            goals={GoalsType.None}
            setIsAddResources={setIsNewResource}
            section={resourceSection}
            UsersAdded={UsersAdded}
            saved={saved}
            idRol={idRol}
            IdProject={IdProject}
          />;
      case SectionsType.ProjectDetails:
      case SectionsType.ProjectInHold:
        return isNewResource ? <ProjectResourceAdd setIsAddResources={setIsNewResource} /> : <ProjectResourceDetailsAdded goals={GoalsType.Planning} section={resourceSection} setIsAddResources={setIsNewResource} UsersAdded={UsersAdded} saved={saved} idRol={idRol} planning={planning} planningsprint={planningsprint} sprint={sprint}  IdProject={IdProject} />
      case SectionsType.ProjectInSprint:
        return isNewResource ? <ProjectResourceAdd setIsAddResources={setIsNewResource} /> : <ProjectResourceDetailsAdded goals={GoalsType.Sprint} section={resourceSection} setIsAddResources={setIsNewResource} UsersAdded={UsersAdded} saved={saved} idRol={idRol} planning={planning} planningsprint={planningsprint}  sprint={sprint} IdProject={IdProject} />
      default:
        return <ResourceList setIsNewResource={setIsNewResource} />;
    }
  }


  return (
    <>
      <div className={resourceSection === SectionsType.Dashboard ? "card-main height-100" : "card-main overflow-card-resources mb-3"}>
        {
          showComponentBySection()
        }
      </div>

    </>
  );
});