import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const { users } = URLs;

const LogIn = (email, password) => {
  return APIHandler.post(`${users.use_LogIn}`, { email, password }).then(
    (response) => {
      const { data: userInfo } = response;

      localStorage.setItem("user", JSON.stringify(userInfo));
      return userInfo;
    }
  );
};


export { LogIn };
