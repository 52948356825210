import React, { useCallback, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';
import { HavePermission } from '../../../../ui/helpers/PermissionsHelper';

export const DraggingHandle = () => (
    <button className="dragging-handle">
        <svg viewBox="0 0 20 20" width="12">
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
        </svg>
    </button>
);

export const DraggableHandle = ({ handleListeners }) => {
    return (<div {...handleListeners}><DraggingHandle /></div>);
};

function SortableItem({ id, activeId, hasDraggHandle }) {
    const { setNodeRef, transform, transition, listeners } = useSortable({ id });
    const [isEditMode, setIsEditMode] = useState(false);

    const style = {
        transform: cssDndKit.Transform.toString(transform),
        transition,
    };

    return (
        <li
            ref={setNodeRef}
            style={style}
            {...(hasDraggHandle ? {} : listeners)}
            className={(activeId === id) ? 'sortable-item dragging-dbd-kit' : 'sortable-item'}
        >
            <div className="dragging-handle-container">
                {hasDraggHandle ? <DraggableHandle handleListeners={listeners} /> : null}
            </div>
            <span className="dragging-title">
                    {`Épica ${id}`}
                  </span>
            {
                isEditMode ? 
                (
                    <>
                    
                <input className='form-control' type="text" ref={ref => ref && ref.focus()}
                onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                />
                    </>
                   ) : <span onClick={() => setIsEditMode(true)}>Item {id - 1}</span>
            }


                    <span
                      tabIndex={500}
                      className="font-18"
                    >
                      <i className="icon-rc_btnn-quitar"></i>
                    </span>
                  
            
        </li>
    );
}

const SortableList = ({ items, onSortEnd, hasDraggHandle }) => {    
    const [activeId, setActiveId] = useState(null);
    const getIndex = (id) => items.indexOf(+id);
    
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating.
            // Slight distance prevents sortable logic messing with
            // interactive elements in the handler toolbar component.
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement.
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );

    return (
        <DndContext
            sensors={sensors}
            autoScroll={false}
            onDragStart={({ active }) => {
                if (active) {
                    setActiveId(active.id);
                }
            }}
            onDragEnd={({ active, over }) => {
                if (over && active.id !== over.id) {
                    onSortEnd({
                        oldIndex: getIndex(active.id),
                        newIndex: getIndex(over.id),
                    });
                }
                setActiveId(null);
            }}
            onDragCancel={() => setActiveId(null)}
        >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <ul className="sortable-list">
                    {items.map((id, index) => (
                        <SortableItem
                            key={`item-${id}`}
                            id={id}
                            activeId={activeId}
                            hasDraggHandle={hasDraggHandle}
                        />
                    ))}
                </ul>
            </SortableContext>
        </DndContext>
    );
}

export const DndKitList = ({ hasDraggHandle = true }) => {
    const [items, setItems] = useState(() => [0, 1, 2, 3, 4, 5].map((id) => id + 1));
    const [items2, setItems2] = useState(() => [6, 7, 8, 9, 10, 11].map((id) => id + 1));


    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            setItems((items) => arrayMoveImmutable(items, oldIndex, newIndex));
        },
        []
    );

    return (<SortableList items={items} items2={items2} onSortEnd={onSortEnd} hasDraggHandle={hasDraggHandle} />);
}