import { createSlice } from "@reduxjs/toolkit";

export const ErrorSlice = createSlice({
  name: "errors",
  initialState: {
    haveError: false,
    error: "",
    errorType: "",
    status: 0,
  },
  reducers: {
    setMessageError: (state, action) => {
      state.haveError = true;
      state.error = action.payload.message;
      state.errorType = action.payload.errorType;
      state.status = action.payload.status;
    },
    cleanMessageError: (state) => {
      state.haveError = false;
      state.error = "";
      state.errorType = "";
      state.status = 0;
    },
  },
});

export const { setMessageError, cleanMessageError } = ErrorSlice.actions;
