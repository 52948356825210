import { createSlice } from "@reduxjs/toolkit";

export const EpicsSlice = createSlice({
  name: "epics",
  initialState: {
    isLoading: true,
    hasEpicsChanged: false,
    epics: [],
    totalEpics: 0,
    epicSelected: 0,
    epicLastSelected: 0,
    isNewEpic: false,
    epicSelectedChanged: false,
    isFirstLoad: false
  },
  reducers: {
    startEpics: (state) => {
      state.isLoading = true;
    },
    startEpicsChanges: (state) => {
      state.hasEpicsChanged = true;
    },
    startEpicSelectedChange: (state) => {
      state.epicSelectedChanged = true;
    },
    EndEpicSelectedChanged: (state) => {
      state.epicSelectedChanged = false;
    },
    setEpics: (state, action) => {
      state.epics = action.payload;
      state.totalEpics = action.payload?.length;

      state.isLoading = false;
      state.isFirstLoad = true;
    },
    cleanEpics: (state) => {
      state.isLoading = true;
      state.epics = [];
      state.totalEpics = 0;
      state.epicSelected = 0;
      state.epicLastSelected = 0;
      state.isNewEpic = false;
      // state.epicSelectedChanged = false;
      // state.hasEpicsChanged = false;
    },
    setEpicSelected: (state, action) => {
      state.epicSelected = action.payload;
    },
    setRefreshEpiceSelected: (state, action) => {
      //state.epicLastSelected = state.epicSelected;
      state.epicSelected = action.payload;
      //state.hasEpicsChanged = false;
    },
    setIsNewEpic: (state, action) => {
      state.isNewEpic = action.payload;
    },
    AddEpic: (state, action) => {
      //state.hasItemsChanged = true;

      state.epics = [...state.epics, action.payload];
      state.totalEpics = state.epics?.length;

      //state.hasEpicsChanged = false;
    },
    setNewEpicOrden: (state, action) => {
      state.epics = action.payload;
    },
    removeEpic: (state, action) => {
      state.hasEpicsChanged = false;
      state.epics = state.epics.filter(
        (item) => item.IdEpic !== action.payload
      );
      state.totalEpics = state.epics?.length;
    },
    setUpdateEpic: (state, action) => {
      state.hasEpicsChanged = false;

      state.epics = state.epics.map((item) => {
        if (action.payload.IdEpic === item.IdEpic) {
          return action.payload;
        }

        return item;
      });
    },
    setEpicsUpdated: (state, action) => {
      state.epics = action.payload;
      state.totalEpics = action.payload?.length;
      state.hasEpicsChanged = false;
    },
    EndLoadingEpics: (state) => {
      state.isLoading = false;
    },
    EndLoadingChanges: (state) => {
      state.hasEpicsChanged = false;
    }
  },
});

export const {
  startEpics,
  startEpicsChanges,
  startEpicSelectedChange,


  setEpics,
  setEpicSelected,
  setIsNewEpic,
  setRefreshEpiceSelected,
  setNewEpicOrden,
  setUpdateEpic,
  setEpicsUpdated, 

  cleanEpics,
  AddEpic,
  removeEpic,

  EndLoadingEpics,
  EndEpicSelectedChanged,
  EndLoadingChanges,

} = EpicsSlice.actions;
