import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const usePermission = (module) => {
    const { permissions } = useSelector((state) => state.authorization);

    const [roleCanCreate, setRoleCanCreate] = useState(false);
    const [roleCanRead, setRoleCanRead] = useState(false);
    const [roleCanUpdate, setRoleCanUpdate] = useState(false);
    const [roleCanDelete, setRoleCanDelete] = useState(false);

    useEffect(() => {
        if (module !== undefined) {
            const _permision = permissions.find(item => item.IdModule === module);
            const { CanCreate, CanSee, CanUpdate, CanDelete } = _permision;

            setRoleCanCreate(CanCreate)
            setRoleCanRead(CanSee)
            setRoleCanUpdate(CanUpdate)
            setRoleCanDelete(CanDelete);
        }

    }, [module, permissions])


    return (
        {
            roleCanCreate,
            roleCanRead,
            roleCanUpdate,
            roleCanDelete
        }
    )
}
