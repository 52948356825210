import "./App.css";
import "./App.scss";
// import "./assets/scss/custom.scss"
import "animate.css";
import "./assets/icons-xscrum.css";
import { AppRouter } from "./router/AppRouter";

function App() {
  return <AppRouter />;
}

export default App;
