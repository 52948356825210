export const ResourcesList = () => {
    return (
        <>
            <article className="color-font ps-4 mb-3">Recursos</article>

            <div className="list-main back-none ps-4">
                <ul>
                    <li>
                        <div className="wrap align-items-center">
                            <div>
                                <div className="user-circle xs bg-gradient-1">SP</div>
                            </div>
                            <div>Sofía Padilla</div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap align-items-center">
                            <div>
                                <div className="user-circle xs bg-gradient-4">ME</div>
                            </div>
                            <div>María Escalante</div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap align-items-center">
                            <div>
                                <div className="user-circle xs bg-gradient-3">SM</div>
                            </div>
                            <div>Sergio Martínez</div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
