import { Tooltip } from "@mui/material";

export const ActivityUserStoriesItem = () => {
    return (
        <>
            <div className="plans-items">
                <Tooltip title="Look and feel" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 1</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Brief del proyecto
                            </span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Wireframe del proyecto" placement="top" arrow>
                    <div className="plan-item selected">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 2</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Wireframe del proyecto
                            </span>
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 3</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 4</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 5</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 6</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 7</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 8</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className="plans-items">
                <Tooltip title="Cotización" placement="top" arrow>
                    <div className="plan-item ">
                        <div className="names">
                            <span className=""><i className='bi bi-clock'></i></span>
                            <span className="plan-tit flex-none w-auto">US 9</span>
                            <span className="plan-name flex-fill d-inline-block d-xl-none">
                                Look and feel
                            </span>
                            {/* <span><i className="dot-sm danger ms-2"></i></span> */}
                        </div>
                    </div>
                </Tooltip>
            </div>
            
            <div className="add-item">
                <button className='button border-grey-dot flex-fill'>
                    <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                    {/* <span>Agregar</span> */}
                </button>
            </div>
        </>
    )
}
