import { URLs } from "../../../ui/constants"
import APIHandler from "../../../ui/helpers/APIHandler"

const GetUserStories = () => {
    return APIHandler.get(URLs.userStories.GetAll);
}

const GetUserStoriesByIdProject = (IdProject) => {
    return APIHandler.get(`${URLs.userStories.GetByIdProject}${IdProject}`);
}

const GetUserStoriesByIdEpic = (IdEpic) => {
    return APIHandler.get(`${URLs.userStories.GetByIdEpic}${IdEpic}`);
}


const GetUserStoriesByIdProjectRelation = (IdProject) => {
    return APIHandler.get(`${URLs.userStories.GetByIdProjectRelation}${IdProject}`);
}

const onGetByProjectAndEpicWithEpicsRelation = (projectId, epicId) => {
    return APIHandler.get(`${URLs.userStories.us_GetByProjectAndEpicWithEpicsRelation}${projectId}/${epicId}`);
}

const GetUserStoryById = (Id) => {
    return APIHandler.get(`${URLs.userStories.GetById}${Id}`);

}

const UpdateUserStory = (id, userStroy) => {
    return APIHandler.put(`${URLs.userStories.Update}${id}`, userStroy)
}

const DeleteUserStory = (userStoryId) => {
    return APIHandler.delete(`${URLs.userStories.Delete}${userStoryId}`);
}

const SaveUserStory = (newUserstory) => {
    return APIHandler.post(URLs.userStories.Create, newUserstory);
}

const SaveUserStoryWithEpic = (newUserstory) => {
    return APIHandler.post(URLs.userStories.CreateWithEpic, newUserstory);
}

const UpdateOrderUserStory = (idProject, newOrden) => {
    return APIHandler.put(`${URLs.userStories.UpdateOrder}${idProject}`, newOrden);
}


export {
    GetUserStories,
    GetUserStoriesByIdProject,
    GetUserStoriesByIdEpic,
    GetUserStoriesByIdProjectRelation,
    GetUserStoryById,
    UpdateUserStory,
    DeleteUserStory,
    SaveUserStory,
    SaveUserStoryWithEpic,
    UpdateOrderUserStory,
    onGetByProjectAndEpicWithEpicsRelation
}