import React from 'react'
import { useSelector } from 'react-redux'
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper'
import { RolesType } from '../../../../../ui/types/RolesType'

export const CheckboxCell = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const { idRol } = useSelector((state) => state.authorization);

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, ])

    return (
        <>
            <input type="checkbox" disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])} ref={resolvedRef} {...rest} />
        </>
    )
}
)