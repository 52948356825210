import React from 'react'
import { HistoryListItems } from './HistoryListItems'

export const HistoryList = () => {
    return (
        <>
            <div className="history-block mb-4">
                <div className="rows-main justify-content-between mb-3">
                    <div>
                        <span>Sprint 2</span>
                    </div>
                    <div>
                        <span>27 Feb 2012</span>
                        -
                        <span>13 Mar 2022</span>
                    </div>
                </div>

                <HistoryListItems />
            </div>

            <div className="history-block mb-4">
                <div className="rows-main justify-content-between mb-3">
                    <div>
                        <span>Sprint 1</span>
                    </div>
                    <div>
                        <span>12 Feb 2012</span>
                        -
                        <span>26 Feb 2022</span>
                    </div>
                </div>

                
                <HistoryListItems />
            </div>
        </>
    )
}
