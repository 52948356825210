import { Skeleton } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { HasLoadingAll, HasLoadingAllWithActivities } from "../../../../../ui/helpers/LoadingStatus";
import { HavePermission } from "../../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../../ui/types/RolesType";
import { useValidationState } from "../../../hooks/useValidationState";
import { onUpdateProjectStatus } from "../../../services/projectService";
import { GetProject, GetUserStoriesByEpic, UpdateUrl, GetUserStories } from "../../../slices";
import { projectStatusType } from "../../../types/projectStatusType";
import { SkeletonDetailItem } from "../../skeletons/SkeletonDetailItem";
import { ProjectUserStoryDetailsItem } from "./ProjectUserStoryDetailsItem"
import { PermissionGate } from "../../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../../ui/types/ScopesType";

export const ProjectUserStoryDetails = ({ section }) => {

  const { IdProject } = useSelector(state => state.project);
  const { userstories } = useSelector((state) => state.userstories);
  const { epicSelected } = useSelector((state) => state.epics);
  const { sectionSelected, hasActivitiesChanged } = useSelector((state) => state.sections);

  const { isLoadingComponents, isLoadingUserStoriesComponents } = useValidationState(section)

  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (epicSelected !== 0 && sectionSelected !== 0) {
      dispatch(GetUserStoriesByEpic());
    }

  }, [epicSelected, sectionSelected, dispatch])

  useEffect(() => {
    if (hasActivitiesChanged) {
      dispatch(GetUserStoriesByEpic(true));

    }
  }, [hasActivitiesChanged, dispatch])


  useEffect(() => {
    setRecords(userstories);
  }, [userstories])

  const onBackPlanning = () => {
    onUpdateProjectStatus(IdProject, projectStatusType.IN_PLANNING).then(() => {
      dispatch(UpdateUrl(`/project/${IdProject}/planning/definitions`))
      dispatch(GetProject(IdProject, false))
    });
  }

  return (
    <>
      {

      }
      <div className="col-md flex-xl-grow-0">
        <article className="color-dark font-16 text-nowrap mb-3">
          User Stories
        </article>

        <div className="add-plans-items">
          {
            ((isLoadingComponents || isLoadingUserStoriesComponents) ? Array.from(new Array(5)) : records).map((item, index) => (
              <Fragment key={index}>
                {
                  item ? (
                    <ProjectUserStoryDetailsItem key={item.IdUserStory} {...item} />
                  ) : (
                    <SkeletonDetailItem />
                  )
                }
              </Fragment>
            ))

            // epics.map(item => (
            //     <ProjectEpicDetailsItem key={item.IdEpic} {...item} />
            // ))
          }

          <div className="add-item">
            {/* <button disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
              <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
            </button> */}
            {
              (isLoadingComponents || isLoadingUserStoriesComponents) ? (
                <Skeleton sx={{ bgcolor: '#3E3E3E' }} >
                  <button className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                    <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                  </button>
                </Skeleton>
              ) : (
                <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]}>
                  <button className='button border-grey-dot flex-fill' onClick={() => onBackPlanning()}>
                    <span className="font-24 me-2"><i className='bi bi-plus'></i></span>
                  </button>
                </PermissionGate>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
