import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { Resources } from '../../../../../ui/components/Resources/Resources';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { SectionsType } from '../../../../../ui/types/SectionsType';
import { useProject } from '../../../hooks/useProject';
import { CloseSprint } from '../../../services/sprintService';
import { EndProjectStatusChanged, startProjectStatusChanged } from '../../../slices/Project/ProjectSlice';
import { SprintDialog } from '../../shared/SprintDialog';
import { ActivitiesInSprintBase } from './ActivitiesInSprintBase';

export const ActivitiesInSprint = ({ section }) => {
	const dispatch = useDispatch();

	const [showDialog, setShowDialog] = useState(false);

	const { IdProject, sprint } = useProject();
	const { idRol } = useSelector((state) => state.authorization);

	const onClickConfirm = () => {

		dispatch(startProjectStatusChanged());

		QuestionDialog(`¿Esta seguro que finalizar el sprint?`, 'Proyecto').then(resp => {
			if (resp.isConfirmed) {
				//dispatch(UpdateProjectStatus(projectStatusType.IN_SPRINT, setShowDialog));
				const { IdSprint } = sprint;
				CloseSprint(IdProject, IdSprint).then(response => {
					setShowDialog(true);

				}).catch(error => {
					console.log(error);
				});
			}
			else{
				dispatch(EndProjectStatusChanged());
			}
		});
	}



	return (
		<>
			{showDialog && <SprintDialog text={'Sprint finalizado con éxito.'} setShowDialog={setShowDialog} IdProject={IdProject} />}

			<div className="container-plans">
				<div className="row height-plans">
					<div className="col-lg-9 col-xxxl-10 mb-3 height-100">
						<div className="card-main height-100">
							<ActivitiesInSprintBase {...sprint} section={section} />
						</div>
					</div>
					<div className="col-lg-3 col-xxxl-2 mb-3 height-100">
						<Resources resourceSection={section} />

						<button className="button dark w-100"
							type="submit"
							disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])}
							onClick={onClickConfirm}>
							<span className="flex-fill">Finalizar Sprint</span>
							<span>
								<i className="bi bi-arrow-right"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
