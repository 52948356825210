import { Skeleton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { HavePermission } from "../../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../../ui/types/RolesType";
import { AddProject, UpdateProject } from "../../../slices";
import { useValidationState } from "../../../hooks/useValidationState";

export const ProjectName = ({ section }) => {
  const dispatch = useDispatch();

  const { saved, IdProject, Name } = useSelector((state) => state.project);
  const { idRol } = useSelector((state) => state.authorization);

  const { isLoadingComponents } = useValidationState( section );

  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState(0);
  const [isEditProjectName, setIsEditProjectName] = useState(false);

  const [isErrorProjectName, setIsErrorProjectName] = useState(false);

  useEffect(() => {
    setProjectName(Name);
    setProjectId(IdProject);
    setIsEditProjectName(!saved);
  }, [Name, IdProject, saved])


  const onBlurProjectName = () => {
    if (projectName === "") {
      setIsErrorProjectName(true);
      return;
    }
    else {
      if (projectId !== 0) {
        setProjectName(Name);
        setIsEditProjectName(false);
      }
      else
        setProjectName(projectName);
    }
  };

  const updateProjectName = ({ target: { value } }) => {
    if (projectName !== "") {
      setIsErrorProjectName(false);
    }
    setProjectName(value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();
      saveProject();
      setIsEditProjectName(false);
    } else if (e.key === "Escape") {
      if (projectId === 0) return;
      setProjectName(Name);
      setIsEditProjectName(false);
      setIsErrorProjectName(false);
    }
  };

  const saveProject = () => {
    // const projectData = {
    //   Name: projectName,
    //   Description: `Proyecto ${projectName}`,
    // };

    if (IdProject === 0) {
      dispatch(AddProject(projectName, projectName));
      // CreateProject(projectData)
      //   .then((response) => {
      //     const { status, data: { AffectedId } } = response;

      //     if (status === 200) {
      //       setProjectId(AffectedId);
      //       UpdateProjectStatus(AffectedId, projectStatusType.IN_PLANNING).then(() => {
      //         dispatch(GetProject(AffectedId));
      //         navigate(`/project/${AffectedId}/planning/definitions`);
      //         enqueueSnackbar(`Se ha guardado el proyecto correctamente.`, { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
      //       });

      //     }
      //   }).catch(error => {
      //     console.log(error);
      //   });
    }
    else {
      dispatch(UpdateProject(projectName, projectName));
      // UpdateProject(IdProject, projectData)
      //   .then((response) => {
      //     const { status } = response;

      //     if (status === 200) enqueueSnackbar(`Se ha editado el proyecto correctamente`, { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
      //   });
    }
  };


  // useMemo(() => {
  //   if (projectId !== 0 && projectId !== undefined) {
  //     dispatch(GetProject(projectId));
  //   }
  // }, [projectId, dispatch]);

  return (
    <>
      <div className="flex-fill names-projects">
        {
          isLoadingComponents ? (
            <>
              <Skeleton />
              <Skeleton className="font-24 lh-1" height={60} />
            </>
          ) : (
            <>
              <Link to='/dashboard' className="d-inline-block color-font mb-1">
                <span className="me-1">
                  <i className="icon-arrow_back"></i>
                </span>
                Reporte de Actividades
              </Link >
              <br />
              {
                (isEditProjectName && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])) ? (
                  <div>
                    <input
                      className={
                        isErrorProjectName
                          ? "input-name-pro font-18 input-botton-brd lh-1 p-0 border-bottom-danger no-outline animate__shakeY"
                          : "input-name-pro font-18 input-botton-brd lh-1 p-0 no-outline animate__bounceIn"
                      }
                      placeholder="Nombre del proyecto"
                      type="text"
                      value={projectName}
                      onChange={updateProjectName}
                      onBlur={onBlurProjectName}
                      onKeyDown={onKeyPress}
                      ref={(ref) => ref && ref.focus()}
                    />
                  </div>
                ) : (
                  <>
                    <Tooltip title={HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) ? "Renombrar proyecto" : "Nombre del proyecto"}>
                      <span
                        className="d-block font-24 lh-1 me-2"
                        onDoubleClick={() => {
                          setIsEditProjectName(!isEditProjectName);
                        }}
                      >
                        {projectName}
                      </span>
                    </Tooltip>
                  </>
                )
              }

              {
                isErrorProjectName && (
                  <p className="msgError m-0"> El nombre es obligatorio.</p>
                )
              }

            </>

          )
        }
      </div >
    </>
  );
};
