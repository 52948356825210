import { Skeleton } from '@mui/material'
import React from 'react'
import { SkeletonEpicItem } from './SkeletonEpicItem'

export const SkeletonDefinitionsItems = () => {
    return (
        <div className="col-md-4">
            <article className="color-dark font-16 mb-3 ms-4">
                <Skeleton height={40} />
            </article>
            <div className="add-plans-items">
                <Skeleton className="add-item" sx={{ bgcolor: '#3E3E3E' }} variant="rectangular" height={40} />
                <SkeletonEpicItem />
                <SkeletonEpicItem />
                <SkeletonEpicItem />

            </div>
        </div>
    )
}
