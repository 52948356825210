import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../sections/auth/context/AuthContext';


export const PrivateRoute = ({ children }) => {
    //const { logged } = useContext( AuthContext );
    const { logged } = useSelector( state => state.authorization );

    
    return (logged)? children : <Navigate to="/login" />
}
