import { Backdrop } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetProject, GetProjectWithRecalculationData, RecalculationData } from '../../slices';

export const ConfirmPlanning = ({ setShowDialogConfirm }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
      
    const { url, IdProject } = useSelector(state => state.project);
  
    const [IsLoading, setIsLoading] = useState(true);
  
    // useEffect(() => {
    //   navigate(url);
    // }, [url, navigate])

    const onGotoNextSection = () => {
        //dispatch(GetProject(IdProject, true));
        dispatch(GetProjectWithRecalculationData());
        setShowDialogConfirm(false);
        setIsLoading(false);
    }



    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <div className={IsLoading ? "confirmacion show" : "confirmacion"}>
                <div className="flex-fill">
                    <article className="color-white font-18">
                        <span className="me-2"><i className="icon-finalizado-icon"></i></span>
                        Planeación creada con éxito, selecciona las actividades por Sprint.
                    </article>
                </div>
                <div>
                    <button className="button dark" onClick={() => onGotoNextSection()}>
                        Aceptar
                    </button>
                </div>
            </div>
        </Backdrop>
    )
}
