import React from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useEffect } from 'react';
import { ProjectSectionItem } from './ProjectSectionItem';
import { UpdateOrderSections } from '../../services/sectionService';
import { useSelector } from 'react-redux';
import { HavePermission } from '../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../ui/types/RolesType';
import { useValidationState } from '../../hooks/useValidationState';

export const DroppableSections = ({ idProject, validateTree, canAddSection, GetSections, section }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [records, setRecords] = useState([]);
    const { idRol } = useSelector((state) => state.authorization);
    const { sections } = useSelector((state) => state.sections);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setRecords(sections);
    }, [sections]);


    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setRecords((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                const newOrderItems = arrayMove(items, oldIndex, newIndex);

                const orderUpdate = newOrderItems.map((item, index) => ({
                    IdSection: item.IdSection,
                    Orden: index
                }));

                UpdateOrderSections(idProject, { SectionsOrdered: orderUpdate }).then(() => {
                    GetSections();
                    enqueueSnackbar('Se ha actualizado el orden correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                })
                    .catch(error => {
                        console.log(error);
                    });

                return newOrderItems;
            });
        }

    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={records}
                strategy={verticalListSortingStrategy}
            >
                {
                    records?.map((section, index) =>
                        <ProjectSectionItem
                            key={section.IdSection}
                            position={index + 1}
                            item={section}
                            validateTree={validateTree}
                            CanAddSections={canAddSection}
                            GetSections={() => GetSections()}
                        />
                    )
                }
            </SortableContext>
        </DndContext>
    )
}
