import { Grid } from '@mui/material';
import React from 'react'
import { ProjectStatistics } from '../../../../../../ui/constants/project';
import { addZeroes, round } from '../../../../../../ui/helpers/NumberHelper'
import { LinearChart } from '../../LinearChart';
import { ProjectStatisticsIcon } from '../../ProjectStatisticsIcon';
import { GridCustomized, Item } from './Styles';

export const TabGridProjectSprint = ({ initData, statusData, ...rest }) => {

  const PaintCellInfoCharts = ({ percent, color }) => {
    return (
      <>
        <Grid item container xs className='flex-grow-0'>
          <Item style={{width: '30px'}}>
            {`${round(percent)}%`}
          </Item>
        </Grid>
        <Grid item xs>
          <Item>
            <LinearChart colorcustom={color} percent={round(percent)} />
          </Item>
        </Grid>
      </>
    );
  }

  const PaintRow = ({ title, value, meditionType, percent, diffValue, priority, valueStatus, color }) => {
    return (
      <>
        <Grid item container xs={3.5}>
          <Grid item xs className='d-flex gap-10'>
            <span className='d-inline-block' style={{width: '12px'}}>
              <ProjectStatisticsIcon priority={diffValue < 0 ? ProjectStatistics.LOW : diffValue === 0 ? ProjectStatistics.EQUALS : ProjectStatistics.HIGH} top={-1} left={4} />
            </span>
            <span className='d-inline-block'>
              {title}
            </span>
          </Grid>
          {/* <Grid item xs>
          </Grid> */}
        </Grid>
        <Grid item container xs={4.5}>
          {/* <Item>{`${value}${meditionType}  ${diffValue < 0 ? '-' : '+'}${Math.abs(diffValue)}%`}</Item> */}
          <Grid item xs>
            <span className="d-inline-block me-1">
              {`${value}${meditionType}`}
            </span>
            <span className="d-inline-block">
              <Item color={diffValue < 0 ? '#57A55A' : diffValue === 0 ? '#000000' : '#CD1317'}>
                {`${diffValue < 0 ? '-' : '+'}${Math.abs(round(diffValue))}%`}
              </Item>
            </span>
          </Grid>
          {/* <Grid item container xs>
          </Grid> */}

        </Grid>
        <GridCustomized container item xs={4} className='gap-2'>
          <PaintCellInfoCharts color={color} percent={percent} />
        </GridCustomized>
      </>
    );
  }

  return (
    <div role="tabpanel" {...rest}>
      <GridCustomized container spacing={1} justifyContent="flex-start">
        <Grid container item spacing={0.5} className="flex-nowrap gap-1">
          <Grid item xs={3.5}>
            <Item></Item>
          </Grid>
          <Grid item xs={4.5}>
            <Item>Totales</Item>
          </Grid>
          <Grid item xs={4}>
            <Item>Avance</Item>
          </Grid>
        </Grid>
        <Grid container item spacing={0.5} className="flex-nowrap gap-1">
          <PaintRow title={`Trabajo`}
            value={round(statusData?.CurrentPoints, 2)}
            meditionType={'p'}
            diffValue={statusData?.DiffOfPointsAccordInitValue}
            percent={statusData?.AdvancedPointsPercent}
            color='#1867e1' />
        </Grid>
        <Grid container item spacing={0.5} className="flex-nowrap gap-1">
          <PaintRow title={'Costo'}
            value={addZeroes(round(statusData?.CurrentCost, 2))}
            meditionType={'h'}
            diffValue={statusData?.DiffOfCostAccordInitValue}
            percent={statusData?.AdvancedCostPercent}
            color='#27b9c3' />
        </Grid>
        <Grid container item spacing={0.5} className="flex-nowrap gap-1">
          <PaintRow title={'Tiempo'}
            value={addZeroes(round(statusData?.CurrentTime, 2))}
            meditionType={'s'}
            diffValue={statusData?.DiffOfTimeAccordInitValue} percent={statusData?.AdvancedTimePercent}
            color='#edb32f' />
        </Grid>
      </GridCustomized>
    </div>
  )
}
