import { Skeleton } from "@mui/material";
import { useValidationState } from "../../../hooks/useValidationState";
import { ChartInformationItem } from "../ChartInformationItem";
import { CirculeChart } from "../CirculeChart";

export const ProjectChartPoints = ({ section }) => {

  const { isLoadingComponents } = useValidationState( section );


  const PaintInformation = ({ extras = 0, realized = 0, pending = 0 }) => {
    return <>
      <div className="list-main back-grey color-font flex-fill">
        <ul>
          <ChartInformationItem itemTitle="Extras" itemPoints={extras} />
          <ChartInformationItem itemTitle="Realizados" itemPoints={realized} />
          <ChartInformationItem itemTitle="Pendientes" itemPoints={pending} />
        </ul>
      </div>
    </>
  }


  return (
    <>
      <div className="chart-list py-2">
        <div className="d-flex">
          {
            isLoadingComponents ? (
              <>
                <Skeleton variant="circular" >
                  <CirculeChart />
                </Skeleton>

                <div className="list-main back-grey color-font flex-fill">
                  <ul>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <CirculeChart title="Puntos" points={'100'} />
                <PaintInformation />
              </>
            )
          }
        </div >
      </div >
    </>
  );
};
