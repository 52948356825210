import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux";
import { SectionsType } from "../../../ui/types";

export const useValidationState = (section) => {
    const { epicSelectedChanged, hasEpicsChanged, isLoading: epicsLoading } = useSelector((state) => state.epics)
    const { sectionSelectedChanged, hasSectionsChanged, isLoading: sectionsLoading, sectionSelected } = useSelector((state) => state.sections);
    const { userStorySelectedChanged, isLoading: userstoriesLoading } = useSelector((state) => state.userstories);
    const { IsLoading: projectLoading, IsFirstLoad, hasProjectStatusChanged } = useSelector((state) => state.project);
    const { IsLoading: projectsLoading } = useSelector(state => state.projects);
    const { IsLoading: usersLoading } = useSelector(state => state.users);
    const { isLoading: activititesLoading, isLoadingCatalogStatus: catalogStatus, hasActivitiesChanged } = useSelector((state) => state.activities);


    const [isLoadingComponents, setIsLoadingComponents] = useState(true);
    const [isLoadingProject, setIsLoadingProject] = useState(false);
    const [isLoadingEpicsComponents, setIsLoadingEpicsComponents] = useState(false);
    const [isLoadingUserStoriesComponents, setIsLoadingUserStoriesComponents] = useState(false);
    const [isLoadingSectionsComponents, setIsLoadingSectionsComponents] = useState(false);
    const [isLoadingActivitiesComponents, setIsLoadingActivitiesComponents] = useState(false);

    useEffect(() => {
        switch (section) {
            case SectionsType.Dashboard:
                setIsLoadingComponents(projectsLoading || usersLoading);
                break;
            case SectionsType.NewProject:
                setIsLoadingComponents(false);
                break;
            case SectionsType.Project:
                // setIsLoadingEpicsComponents(initialState);
                // setIsLoadingUserStoriesComponents(initialState);
                // setIsLoadingSectionsComponents(initialState);
                // setIsLoadingActivitiesComponents(initialState);
                console.log(projectLoading, userstoriesLoading, sectionsLoading, epicsLoading);
                if (hasEpicsChanged) {
                    setIsLoadingComponents(false);
                    //console.log('Camiof ogdfou');
                }
                else if (hasSectionsChanged) {
                    setIsLoadingComponents(false);
                }
                else {
                    if (IsFirstLoad) setIsLoadingComponents(projectLoading || userstoriesLoading || sectionsLoading || epicsLoading);
                    else setIsLoadingComponents(false);
                }



                //
                break;
            case SectionsType.ProjectDetails:
            case SectionsType.ProjectInHold:
                // console.log(hasProjectStatusChanged);
                // console.log(projectLoading , sectionsLoading , epicsLoading , activititesLoading , catalogStatus);
                // console.log(isLoadingComponents, epicSelectedChanged, userStorySelectedChanged, sectionSelectedChanged, hasActivitiesChanged);

                if (IsFirstLoad) {
                    setIsLoadingUserStoriesComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                    setIsLoadingSectionsComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                    setIsLoadingComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                } else {
                    if (epicSelectedChanged) {
                        console.log(epicsLoading, sectionsLoading, activititesLoading);
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                        setIsLoadingSectionsComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                        setIsLoadingActivitiesComponents(epicsLoading ||  sectionsLoading || activititesLoading);
                    }
                    else if (userStorySelectedChanged) {
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(false);
                        setIsLoadingSectionsComponents(false)
                        setIsLoadingActivitiesComponents(sectionSelected === 0 ? false : activititesLoading);
                    }
                    else if (sectionSelectedChanged) {
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(false);
                        setIsLoadingSectionsComponents(false)
                        setIsLoadingActivitiesComponents(activititesLoading);
                    }
                    else if (hasActivitiesChanged) {
                        setIsLoadingComponents(false);
                        setIsLoadingActivitiesComponents(activititesLoading);
                    }
                    else if(hasProjectStatusChanged) {
                        setIsLoadingComponents(true);
                    }
                    else {
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(false);
                        setIsLoadingSectionsComponents(false)
                        setIsLoadingActivitiesComponents(false);
                    }
                }
                break;
            case SectionsType.ProjectInSprint:
                // console.log(hasProjectStatusChanged);
                // console.log(projectLoading , userstoriesLoading , sectionsLoading , epicsLoading , activititesLoading , catalogStatus);
                if (IsFirstLoad) {
                    console.log('Only once');
                    setIsLoadingComponents(projectLoading || userstoriesLoading || sectionsLoading || epicsLoading || activititesLoading || catalogStatus);
                } else {
                    if (epicSelectedChanged) {
                        console.log('Epica Changed');
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(userstoriesLoading || sectionsLoading || activititesLoading);
                        setIsLoadingSectionsComponents(userstoriesLoading || sectionsLoading || activititesLoading);
                        setIsLoadingActivitiesComponents(userstoriesLoading || sectionsLoading || activititesLoading);
                    }
                    else if (userStorySelectedChanged) {
                        console.log('US Changed');
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(false);
                        setIsLoadingSectionsComponents(false)
                        setIsLoadingActivitiesComponents(sectionsLoading || activititesLoading);
                    }
                    else if (sectionSelectedChanged) {
                        console.log('SEC Changed');
                        setIsLoadingComponents(false);
                        setIsLoadingUserStoriesComponents(false);
                        setIsLoadingSectionsComponents(false)
                        setIsLoadingActivitiesComponents(activititesLoading || catalogStatus);
                    }
                    else if(hasProjectStatusChanged) {
                        setIsLoadingComponents(true);
                    }
                    else {
                        console.log('General Changed');
                        setIsLoadingComponents(false);
                        setIsLoadingActivitiesComponents(false);
                    }
                }
                break;
            default:
                break;
        }
    }, [section,
        projectsLoading,
        projectLoading,
        userstoriesLoading,
        sectionsLoading,
        epicsLoading,
        usersLoading,
        activititesLoading,
        epicSelectedChanged,
        userStorySelectedChanged,
        sectionSelectedChanged,
        catalogStatus,
        isLoadingEpicsComponents,
        IsFirstLoad,
        sectionSelected,
        hasEpicsChanged,
        hasActivitiesChanged,
        hasSectionsChanged,
        hasProjectStatusChanged
    ])

    return {
        isLoadingComponents,
        isLoadingProject,
        isLoadingUserStoriesComponents,
        isLoadingSectionsComponents,
        isLoadingActivitiesComponents,
        isLoadingEpicsComponents
    }
}
