import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const saveResource = (newResource) => {
  return APIHandler.post(URLs.users.use_Create, newResource)
};

const getCatalogs = async () => {
  await APIHandler.get(URLs.users.use_GetCatalogues, null).then((response) => {
    const {
      data: { Item },
    } = response;

    return Item;
  });
};

const deleteResource = (id) => {
  return APIHandler.delete(`${URLs.users.use_Delete}${id}`);
}

const GetAllUsers = () => {
  return APIHandler.get(URLs.users.use_GetAll);
}

export { getCatalogs, saveResource, deleteResource, GetAllUsers };
