import { GetErrorResponse } from "../../../ui/helpers/ErrorHelper";
import { setMessageError } from "../../../ui/store/slices/ErrorSlice/ErrorSlice";
import {
  hideLoading,
  showLoading,
} from "../../../ui/store/slices/LoadingSlice/LoadingSlice";
import { MessageType } from "../../../ui/types";
import { LogIn } from "../services/authService";
import { LoginUser, LogoutUser } from "./AuthSlice";

export const Login = (username, password) => {
  return async (dispatch) => {
    dispatch(showLoading());
    await LogIn(username, password)
      .then((response) => {
        dispatch(LoginUser(response));
        console.log(response);
      })
      .catch((error) => {
        const { status } = error.response;
        
        if (status === 401) {
          dispatch(
            setMessageError({
              message: "El usuario y/o contraseña son incorrectas.",
              errorType: MessageType.error,
              status: 401,
            })
          );
        } else {
          dispatch(
            setMessageError({
              message: GetErrorResponse(error),
              errorType: MessageType.error,
              status: status
            })
          );
        }
      })
      .finally(() => {
        //dispatch(cleanMessageError());
        dispatch(hideLoading());
      });
  };
};

export const Logout = () => {
  return async (dispatch) => {
    dispatch(LogoutUser());
  };
};
