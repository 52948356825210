import { useEffect } from "react";
import { useState } from "react";
import APIHelper from "../../../ui/helpers/APIHandler";
import * as Global from "../../../ui/constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../../auth/slice/AuthSlice";
import { setUsersWithStatus, startLoadingUsers } from "../../../ui/store/slices/UsersSlice/UsersSlice";

const { users } = Global.URLs;

export const useFetchUsers = () => {

  const dispatch = useDispatch();
  const { UsersAdded } = useSelector((state) => state.project );
  const { IsLoading } = useSelector(state => state.users);
  const [users_, setUsers] = useState([]);
  const [userStatus, setUserStatus] = useState([]);


  useEffect(() => {
    dispatch(startLoadingUsers());
     APIHelper.get(users.use_GetAll, null).then(
      (response) => {
        const {
          data: { Items },
        } = response;
        setUsers(Items);
        
        const usersStatus = Items.map((item) => ({
          Id: item.Id,
          Name: item.Name,
          NameToShow: item.Skill.NameToShow,
          Alias: item.Alias,
          ImgSrc: item.ImgSrc, 
          ImgClassName: item.ImgClassName,
          Skill: item.Skill,
          Status:  UsersAdded?.find((userAdd) => userAdd.Id === item.Id) === undefined ? false : true,
        }));
        

          setUserStatus(usersStatus);
          dispatch(setUsersWithStatus(usersStatus));

      },
      (error) => {
        if (error.code === "ERR_NETWORK") {
          dispatch(LogoutUser());
        }
      }
    );
  }, [UsersAdded, dispatch]);

  return {
    IsLoading,
    users: users_,
    userStatus,
    setUsers,
    setUserStatus
  };
};
