import { Box, Button, CssBaseline, Skeleton, styled, SwipeableDrawer, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMemo, useRef, useEffect, useCallback, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useRowSelect, useSortBy, useTable } from 'react-table';
import { UpdateStatus } from '../../../services/activityService';
import { AddOrRemoveActivities } from '../../../services/sprintService';
import { GetActivitiesByUpdated, GetActivitiesByUserStoryAndSection } from '../../../slices/Activities/ActivitiesTrunk';
import { DndKitList } from '../../epics/ListTst';
import { AvisoEmpty } from '../../shared/AvisoEmpty';
import { CheckboxCell, EditableNumberCell, EditableTextCell, MenuResources, TableGeneric } from '../../shared/Tables';
import { DropdownPointsCell } from '../../shared/Tables/DropdownPointsCell';
import { Board } from '../Board/Board';

export const TableActivitiesInHold = (props) => {

    const dispatch = useDispatch();
    // const firstRender = useRef(true);
    const [records, setRecords] = useState([]);
    const { activities, activitiesSelected } = useSelector((state) => state.activities);
    const { sectionSelected } = useSelector((state) => state.sections);

    const columns = useMemo(() => [
        {
            Header: 'IdActivity',
            accessor: 'IdActivity',
        },
        {
            Header: 'IdSection',
            accessor: 'IdSection',
        },
        {
            Header: 'IdStatusActivity',
            accessor: 'IdStatusActivity',
        },
        {
            Header: 'Tarea(s)',
            accessor: 'Name',
            style: 'text-start w-75',
            Cell: (cell) => <EditableTextCell {...cell} />
        },
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className='d-flex align-items-center gap-1'>
                    <CheckboxCell {...getToggleAllRowsSelectedProps()} />
                    Seleccionar
                </div>
            ),
            Cell: ({ row }) => (
                <div>
                    <CheckboxCell {...row.getToggleRowSelectedProps()} />
                </div>
            ),
        },
        {
            Header: 'Puntos',
            accessor: 'Points',
            Cell: (cell) => <DropdownPointsCell {...cell} />
        },
        {
            Header: 'Recurso',
            accessor: 'IdUser',
            Cell: (cell) => <MenuResources canShowMenu={true} {...cell} />

        },
        {
            Header: 'Prioridad',
            accessor: 'Priority',
            Cell: (cell) => <EditableNumberCell {...cell} />
        },
        {
            Header: 'Orden',
            accessor: 'Orden',
        },
    ], []);

    const initialState = {
        hiddenColumns: ['IdActivity', 'IdSection', 'IdStatusActivity', 'Orden'],
        selectedRowIds: activitiesSelected
    }

    // useEffect(() => {
    //     console.log(activities);
    //     setRecords(activities);
    // }, [activities])

    const getRowId = useCallback(row => {
        return row.IdActivity
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, sortBy }
    } = useTable({ columns, data: records, initialState, getRowId }, useSortBy, useRowSelect);

    useEffect(() => {
        setRecords(activities);
    }, [activities])

    // useEffect(() => {
    //     if (firstRender.current) {
    //         firstRender.current = false;
    //         return;
    //     }
    // })

    useEffect(() => {

        // Verificamos que las activades ya se encuentren cargadas a la tabla
        if (activities.length > 0) {
            // Verificamos si existe algun cambio de seleccion
            const idsSelectedHook = Object.keys(selectedRowIds).sort();
            const idsSelectedOrigin = Object.keys(activitiesSelected).sort();

            if (!(idsSelectedHook.length === idsSelectedOrigin.length && idsSelectedHook.every(function (v, i) { return v === idsSelectedOrigin[i] }))) {
                let ids = [];
                for (const property in selectedRowIds) {
                    if (selectedRowIds.hasOwnProperty(property)) {
                        ids = [...ids, property]
                    }
                }

                if (ids.length === 0) {
                    const activitiesDeselected = activities.map(item => item.IdActivity);
                    ids = [...activitiesDeselected]
                }

                AddOrRemoveActivities(sectionSelected, [...ids], Object.keys(selectedRowIds).length > 0 ? true : false).then(response => {
                    dispatch(GetActivitiesByUpdated());
                }).catch(error => {
                    console.error(error);
                });
            }

        }
    }, [selectedRowIds])

    useEffect(() => {
        if (sortBy.length >= 1) {
            console.log(sortBy);
            if (rows.length >= 1) {
                const _newOrder = rows.map(({ original }, index) => { return { IdActivity: original.IdActivity, Orden: (index + 1) } });

                UpdateStatus(sectionSelected, { ActivitiesOrdered: _newOrder }).then(item => {
                    //console.log(item);
                    //enqueueSnackbar('Se ha actualizado el orden de las actividades correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }, [sortBy, rows, sectionSelected])

    return (
        <>

            <Board IsSprint={true} />

            {/* <TableGeneric
                IdSection={sectionSelected}
                setRecords={setRecords}
                records={records}
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
            /> */}

        </>
    );
}
