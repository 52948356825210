import { URLs } from "../../../ui/constants";
import APIHandler from "../../../ui/helpers/APIHandler";

const CloseSprint = (idProject, idSprint) => {
  const sprint = {
    IdProject: idProject,
    IdSprint: idSprint,
  };

  return APIHandler.put(URLs.sprints.spr_CloseSprint, sprint);
};

const AddOrRemoveActivity = (idSection, idActivity, status) => {
  const activity = {
    IdSection: idSection,
    IdActivity: idActivity,
    IsSelected: status,
  };
  return APIHandler.post(
    URLs.sprints.spr_SetOrRemovePreSelectedActivity,
    activity
  );
};

const AddOrRemoveActivities = (idSection, idActivities, status) => {
  const activities = {
    IdSection: idSection,
    Activities: idActivities,
    AreSelected: status,
  };

  return APIHandler.post(
    URLs.sprints.spr_SetOrRemovePreSelectedActivities,
    activities
  );
};

const onGetHistorySprints = (projectId) => {
  return APIHandler.get(`${URLs.sprints.spr_GetHistorySprints}${projectId}`);
};

export {
  CloseSprint,
  AddOrRemoveActivity,
  AddOrRemoveActivities,
  onGetHistorySprints,
};
