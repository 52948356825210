import { useState } from "react";
import { ProjectPlaningBase } from "./ProjectPlaningBase";
import { SectionsType } from "../../../../ui/types/SectionsType";
import { Resources } from '../../../../ui/components/Resources/Resources';
import { ConfirmPlanning } from "../shared/ConfirmPlanning";
import { ProjectActivitiesBase } from "../activities/ProjectActivitiesBase";
import { ButtonInPlanning } from "./ButtonInPlanning";

export const ProjectPlanning = ({ section }) => {

	const [validateTree, setValidateTree] = useState(false);
	const [showDialogConfirm, setShowDialogConfirm] = useState(false);

	const showProject = () => {
		switch (section) {
			case SectionsType.NewProject:
			case SectionsType.Project:
				return <ProjectPlaningBase section={section} validateTree={validateTree} />
			case SectionsType.ProjectDetails:
				return <ProjectActivitiesBase section={section}/>
			default:
				break;
		}
	}

	return (
		<>
			{ showDialogConfirm && <ConfirmPlanning setShowDialogConfirm={setShowDialogConfirm} />}

			<div className="container-plans">
				<div className="row height-plans">
					<div className="col-lg-9 col-xxxl-10 mb-3 height-100">
						<div className="card-main height-100">
							{
								showProject()
							}
						</div>
					</div>
					<div className="col-lg-3 col-xxxl-2 mb-3 height-100">
						<Resources resourceSection={section} />

						<ButtonInPlanning section={section} setValidateTree={setValidateTree} setShowDialogConfirm={setShowDialogConfirm}/>
					</div>
				</div>
			</div>

		</>
	);
};
