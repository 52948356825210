import { async } from "rxjs";
import { GetErrorResponse } from "../../../../ui/helpers/ErrorHelper";
import { setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { setMessage } from "../../../../ui/store/slices/MessageSlice/MessageSlice";
import { MessageType } from "../../../../ui/types";
import {
  GetByIdSection,
  GetStatusActivities,
  onGetAllOriginActivities,
  onGetByUserStoryAndSection,
  onUpdateActivity,
  onUpdateOriginActivity,
  SaveActivity,
  UpdateOriginActivity,
} from "../../services/activityService";
import { AddOrRemoveActivities } from "../../services/sprintService";
import { RecalculationData, RecalculationProjectData } from "../Project/ProjectTrunk";
import {
  addNewActivityTemp,
  addNewActivityWithTaskTemp,
  cancelAction,
  endChangeActivities,
  EndLoadingActivities,
  endLoadingActivities,
  refreshActities,
  saveActivityTemp,
  setActivities,
  setActivitiesSelected,
  setActivity,
  setAddActivities,
  setCanContinueAdd,
  setCatalogOriginActivities,
  setCatalogStatus,
  setIsNewActivity,
  startActionLoading,
  startActivities,
  startChangeActivities,
  startLoadCatalogStatus,
  updateActivities,
} from "./ActivitiesSlice";

// const GetActivities = (IsRefresh = false, CanContinueAdd = false) => {
//   return async (dispatch, getState) => {
//     const {
//       sections: { sectionSelected },
//       project: { sprint },
//     } = getState();

//     if (IsRefresh) dispatch(startChangeActivities());
//     else dispatch(startActivities());

//     GetByIdSection(sectionSelected).then((response) => {
//       const {
//         data: { Items },
//       } = response;

//       //if(Items.length >= 1) dispatch(setAddActivities(true));

//       console.log(response);

//       // Load Activities
//       // dispatch(setActivities(Items));
//       dispatch(setAddActivities(true));
//       if (IsRefresh) dispatch(refreshActities(Items));
//       else dispatch(setActivities(Items));

//       // Load SelectedActivities
//       const obj = {};

//       Items.forEach((item) => {
//         if (item.IsSelectedForSprint) {
//           const key = item.IdActivity;
//           obj[key] = true;
//         }
//       });

//       dispatch(setActivitiesSelected(obj));

//       if (CanContinueAdd) {
//         if (sprint)
//           dispatch(
//             addNewActivityWithTaskTemp({
//               activities: Items,
//               sectionid: sectionSelected,
//               size: Items?.length + 1,
//             })
//           );
//         else
//           dispatch(
//             addNewActivityTemp({
//               activities: Items,
//               sectionid: sectionSelected,
//               size: Items?.length + 1,
//             })
//           );
//         dispatch(setIsNewActivity(true));
//       } else {
//         dispatch(setIsNewActivity(false));
//       }
//     });
//   };
// };

const GetActivitiesByUpdated = () => {
  return async (dispatch, getState) => {
    const { epics: { epicSelected }, sections: { sectionSelected }, userstories: { userstorySelected } } = getState();
    dispatch(RecalculationData(true));
    dispatch(startChangeActivities());

    await onGetByUserStoryAndSection(epicSelected, sectionSelected, userstorySelected).then((response) => {
      const {data: { Items } } = response;
      dispatch(setAddActivities(true));

      const obj = {};

      Items.forEach((item) => {
        if (item.IsSelectedForSprint) {
          const key = item.IdActivity;
          obj[key] = true;
        }
      });

      console.log(response);
      dispatch(setActivitiesSelected(obj));
      dispatch(refreshActities(Items))
    }).catch(error => console.log(error));

  }
}

const GetActivitiesByUserStoryAndSection = (
  IsRefresh = false,
  CanContinueAdd = false
) => {
  return async (dispatch, getState) => {
    const {
      authorization: { idRol, idUser },
      epics: { epicSelected },
      sections: { sectionSelected },
      userstories: { userstorySelected },
      activities: { canAddMoreActivities },
      project: { sprint },
    } = getState();

    if (sectionSelected === 0 || userstorySelected === 0) {
      dispatch(EndLoadingActivities());
      return;
    }

    if (IsRefresh) dispatch(startChangeActivities());
    else dispatch(startActivities());

    await onGetByUserStoryAndSection(epicSelected, sectionSelected, userstorySelected)
      .then((response) => {
        const {
          data: { Items },
        } = response;

        console.log(getState());

        //dispatch(startActionLoading({ type: 1, payload: 'algo' }));
        dispatch(setAddActivities(true));

        // Load SelectedActivities
        const obj = {};

        Items.forEach((item) => {
          if (item.IsSelectedForSprint) {
            const key = item.IdActivity;
            obj[key] = true;
          }
        });
        dispatch(setActivitiesSelected(obj));

        if (canAddMoreActivities) {
          if (sprint)
            dispatch(
              addNewActivityWithTaskTemp({
                activities: Items,
                epicId: epicSelected,
                sectionid: sectionSelected,
                userstoryid: userstorySelected,
                size: Items?.length + 1,
                idRol: idRol,
                idUser: idUser
              })
            );
          else
            dispatch(
              addNewActivityTemp({
                activities: Items,
                epicId: epicSelected,
                sectionid: sectionSelected,
                userstoryid: userstorySelected,
                size: Items?.length + 1,
                idRol: idRol,
                idUser: idUser
              })
            );
          dispatch(setIsNewActivity(true));
        } else {
          if (IsRefresh) dispatch(refreshActities(Items));
          else dispatch(setActivities(Items));
          dispatch(setIsNewActivity(false));
        }
      })
      .finally(() => {
        dispatch(EndLoadingActivities());
      });
  };
};

// const GetActivitiesWithSelectionBySection = (
//   IsRefresh = false,
//   CanContinueAdd = false
// ) => {
//   return async (dispatch, getState) => {
//     const {
//       sections: { sectionSelected },
//       userstories: { userStorySelected },
//     } = getState();

//     console.log('Helllow');

//     if (IsRefresh) dispatch(startChangeActivities());
//     else dispatch(startActivities());

//     //GetByIdSection(sectionSelected).then((response) => {
//       await onGetByUserStoryAndSection(sectionSelected, userStorySelected).then((response) => {
//       const {
//         status,
//         data: { Items },
//       } = response;

//       const obj = {};

//       Items.forEach((item) => {
//         if (item.IsSelectedForSprint) {
//           const key = item.IdActivity;
//           obj[key] = true;
//         }
//       });

//       dispatch(setActivitiesSelected(obj));

//       if (IsRefresh) dispatch(refreshActities(Items));
//       else dispatch(setActivities(Items));

//       if (CanContinueAdd) {
//         dispatch(setIsNewActivity(true));
//         dispatch(
//           addNewActivityTemp({
//             sectionid: sectionSelected,
//             size: Items?.length + 1,
//           })
//         );
//       } else {
//         console.log('Estamos cancelando desde linea 204');
//         dispatch(setIsNewActivity(false));
//       }
//     });
//   };
// };

const NewActivityTemp = () => {
  return async (dispatch, getState) => {
    const {
      authorization: { idRol, idUser },
      epics: { epicSelected },
      sections: { sectionSelected },
      activities: { activities },
      userstories: { userstorySelected },
      project: { sprint },
    } = getState();

    console.log(getState());

    dispatch(setIsNewActivity(true));
    dispatch(setCanContinueAdd(true));

    if (sprint !== null)
      dispatch(
        addNewActivityWithTaskTemp({
          activities: activities,
          epicId: epicSelected,
          sectionid: sectionSelected,
          userstoryid: userstorySelected,
          size: activities?.length + 1,
          idRol: idRol,
          idUser: idUser
        })
      );
    else
      dispatch(
        addNewActivityTemp({
          activities: activities,
          epicId: epicSelected,
          userstoryid: userstorySelected,
          sectionid: sectionSelected,
          size: activities?.length + 1,
          idRol: idRol,
          idUser: idUser
        })
      );
  };
};

const CancelAddingActivity = () => {
  return async (dispatch, getState) => {
    dispatch(setIsNewActivity(false));

    dispatch(cancelAction());
  };
};

const GetCatalogStatusActivities = () => {
  return async (dispatch, getState) => {
    dispatch(startLoadCatalogStatus());
    GetStatusActivities().then((response) => {
        const { data: { Items }  } = response;

        dispatch(setCatalogStatus(Items));
      })
      .catch((error) => console.log(error));
  };
};

const GetCatalogs = () => {
  return async (dispatch, getState) => {
    dispatch(startLoadCatalogStatus());
    GetStatusActivities().then((response) => {
      const { data: { Items }  } = response;

      dispatch(setCatalogStatus(Items));
    });
    onGetAllOriginActivities().then(({ data: { Items }}) => {
      dispatch(setCatalogOriginActivities(Items));
    });

  };
}

const GetActivitiesWithFilters = () => {
  return async (dispatch, getState) => {

  }
}

const CreateActivity = ({ IdEpic, IdSection, IdStatusActivity, IdUserStory, IdUser, Name, Description, Points, Priority, Orden }) => {
  return async (dispatch, getState) => {
    dispatch(setCanContinueAdd(true));

    let NewActivity = {
      IdEpic: IdEpic,
      IdSection: IdSection,
      IdStatusActivity: IdStatusActivity,
      IdUserStory: IdUserStory,
      IdUser: IdUser,
      Name: Name,
      Description: Description,
      Points: Points,
      Priority: Priority,
      Orden: Orden
  };

    SaveActivity(NewActivity).then((response) => {
      const {   data: { AffectedId },
      } = response;
      //onItemChanged(IdSection, false);
      //setDescription('');
      dispatch(GetActivitiesByUserStoryAndSection(true, true));
      //dispatch(setActivity({ IdActivity : AffectedId, Name: activity.Name, Description: activity.Description }));
    });
  };
};

const UpdateActivityTemp = ({...rest}) => {
  return async (dispatch, getState) => {
    console.log(rest);
    dispatch(saveActivityTemp(rest));
  };
};

const UpdateActivity = ({ IdActivity, IdStatusActivity, IdUser, Name, Description, Points, Priority, Orden, showMessage= true }) => {
  return async (dispatch, getState) => {
  
    const ActivityEdited = {
      IdStatusActivity: IdStatusActivity,
      IdUser: IdUser,
      Name: Name,
      Description: Description,
      Points:  parseInt(Points),
      Priority: parseInt(Priority),
      Orden: Orden,
    };

    console.log(ActivityEdited);
  
    await onUpdateActivity(IdActivity, ActivityEdited).then((res) => {
      console.log(res);
      //dispatch(RecalculationData());

        if(showMessage){
          dispatch(
            setMessage({
              message: "Se ha actualizado la actividad correctamente",
              messageType: MessageType.success,
              status: '200',
            })
          );
        }
        
          dispatch(GetActivitiesByUpdated());
      }).catch((error) => {
        console.log(error);
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
      });
  }
};

const AddOrRemoveActivitiesSelected = (ids, statusSelected) => {
  return async (dispatch, getState) => {
    const { sections: { sectionSelected }, activities: { activities }} = getState();

    const idsActives = (activities.filter(act => act.IsSelectedForSprint)).map(act => act.IdActivity);
    const universeIdsActives = [...idsActives, ...ids];

    await AddOrRemoveActivities(sectionSelected, universeIdsActives, statusSelected).then(() => {
      dispatch(GetActivitiesByUpdated());
    });
  }
}

const UpdateOriginActivityNew = ({...rest}) => {
  return async (dispatch) => {

    const { IdActivity, OriginActivity: { IdOriginActivity } } = rest

    onUpdateOriginActivity(IdActivity, IdOriginActivity).then(res => {
      dispatch(updateActivities(rest));

      dispatch(
        setMessage({
          message: "Se ha actualizado la actividad correctamente",
          messageType: MessageType.success,
          status: '200',
        })
      );
    });


  }
}

const UpdateActivityNew = ({ showMessage= true, ...rest }) => {
  return async (dispatch, getState) => {

    const { IdActivity, IdStatusActivity, IdUser, Name, Description, Points, Priority, Orden } = rest;

    const ActivityEdited = {
      IdStatusActivity: IdStatusActivity,
      IdUser: IdUser,
      Name: Name,
      Description: Description,
      Points:  parseInt(Points),
      Priority: parseInt(Priority),
      Orden: Orden,
    };

    console.log(ActivityEdited);
  
    await onUpdateActivity(IdActivity, ActivityEdited).then((res) => {
      dispatch(RecalculationProjectData());

      if(showMessage){
        dispatch(
          setMessage({
            message: "Se ha actualizado la actividad correctamente",
            messageType: MessageType.success,
            status: '200',
          })
        );
      }
      //dispatch(GetActivitiesByUpdated());
      dispatch(updateActivities(rest));

      }).catch((error) => {
        console.log(error);
        const { status } = error.response;

        dispatch(
          setMessageError({
            message: GetErrorResponse(error),
            errorType: MessageType.error,
            status: status,
          })
        );
      }).finally(() => {
        dispatch(endChangeActivities());
      });
  }
}

export {
  CreateActivity,
  // GetActivities,
  // GetActivitiesWithSelectionBySection,
  NewActivityTemp,
  UpdateActivityTemp,
  UpdateActivity,
  CancelAddingActivity as CancelOperation,
  GetCatalogStatusActivities,
  GetActivitiesByUserStoryAndSection,
  GetActivitiesByUpdated,
  AddOrRemoveActivitiesSelected,
  UpdateOriginActivityNew,
  UpdateActivityNew,
  GetCatalogs,
  GetActivitiesWithFilters,
};
