import { Tooltip } from '@mui/material';
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { UpdateActivity, UpdateActivityNew } from '../../../slices/Activities/ActivitiesTrunk';
import { BOARD_SECTIONS } from '../../activities/Board/constants';
import { StatusActivitiesType } from '../../activities/Board/types';

export const EditableNumberCell = ({ value: initialValue, row , canEditBySection, boardID}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [newPriority, setNewPriority] = useState(initialValue);
    const [isEditMode, setIsEditMode] = useState(false);
    const { idRol } = useSelector((state) => state.authorization);
    const { activities } = useSelector((state) => state.activities);

    const { original } = row;
    const { IdUser, Orden, IdActivity } = original;

    const handleClickOutside = () => {
        setIsEditMode(false);
        if (initialValue !== parseInt(newPriority)) {
            dispatch(UpdateActivityNew({ ...original, Priority: newPriority, showMessage: false }));
        }
    }

    useOnClickOutside(ref, handleClickOutside)

    const PaintBorderByPriorityRepeat = () => {

        if(boardID === BOARD_SECTIONS.Finalizadas) return "";

        if (activities.filter((act) =>
            act.IdUser === IdUser 
            && IdUser !== 0
            && parseInt(act.IdStatusActivity) !== parseInt(StatusActivitiesType.Finalizada)
            && act.Priority === parseInt(newPriority)
            && act.Orden !== Orden)?.length > 0) {
            return 'border-danger';
        }
        return  '';
    }

    useEffect(() => {
        setNewPriority(initialValue)
    }, [initialValue])


    return (
        <>
            {
                ((isEditMode && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource]) && canEditBySection && IdActivity !== 0)) ?
                    (
                        <input ref={ref} className={`text-center input-table-activities num ${PaintBorderByPriorityRepeat()}`}
                            type="number"
                            min={0}
                            value={newPriority}
                            onChange={({ target: { value } }) => setNewPriority(value)}
                        />
                    ) : (
                        <Tooltip title={PaintBorderByPriorityRepeat() !== '' ? 'Prioridad repetida' : ''} placement="bottom" arrow>
                            <div className={`num ${PaintBorderByPriorityRepeat()}`} onClick={() => setIsEditMode(true)}>
                                {
                                    newPriority === '' ? setIsEditMode(true) : newPriority
                                }
                            </div>
                        </Tooltip>
                    )
            }
        </>
    )
}
