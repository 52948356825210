import { Skeleton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuestionDialog } from '../../../../ui/components/Dialogs'
import { HasLoadingAll, HavePermission } from '../../../../ui/helpers'
import { RolesType, SectionsType } from '../../../../ui/types'
import { useValidationState } from '../../hooks/useValidationState'
import { onUpdateProjectStatus } from '../../services/projectService'
import { GetProject, GetProjectWithRecalculationData, UpdateProjectStatus } from '../../slices'
import { EndProjectStatusChanged, startProjectStatusChanged } from '../../slices/Project/ProjectSlice'
import { projectStatusType } from '../../types/projectStatusType'
import { PermissionGate } from '../../../../ui/components/PermissionGate/PermissionGate'
import { ModuleType } from '../../../../ui/types/ModuleType'
import { SCOPES } from '../../../../ui/types/ScopesType'
import { hideLoading, showLoading } from '../../../../ui/store/slices/LoadingSlice/LoadingSlice'

export const ButtonInPlanning = ({ section, setValidateTree, setShowDialogConfirm }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { IdProject, lastStatus, UsersAdded, canContinueProject } = useSelector(state => state.project);
    const { epics } = useSelector((state) => state.epics);
    const { userStories, isUserStoriesRelationshipCompleted } = useSelector((state) => state.userstories);
    const { sections, isSectionRelationshipCompleted } = useSelector((state) => state.sections);

    const { isLoadingComponents } = useValidationState(section)


    const [btnText, setBtnText] = useState('');

    useEffect(() => {
        if (section === SectionsType.Project || section === SectionsType.NewProject)
            setBtnText('Continuar');
        else
            setBtnText('Confirmar Planeación');
    }, [section])


    const onClickHandler = () => {
        if (UsersAdded && UsersAdded.length === 0)
            enqueueSnackbar('Es necesario que por lo menos exista un recurso en el proyecto.', { autoHideDuration: 4000, variant: 'warning', preventDuplicate: true });
        else if ((epics && epics.length === 0) || (userStories && userStories === 0) || (sections && sections.length === 0))
            enqueueSnackbar('Es necesario que exista por lo menos una épica, una user story y una sección.', { autoHideDuration: 4000, variant: 'warning', preventDuplicate: true });


        if (section === SectionsType.Project) {

            setValidateTree(true);

            if (isUserStoriesRelationshipCompleted && isSectionRelationshipCompleted) {
                closeSnackbar();
                dispatch(showLoading());
                onUpdateProjectStatus(IdProject, lastStatus !== 1 ? lastStatus : projectStatusType.IN_ACTIVITY_DEFINITION).then(() => {
                    dispatch(GetProject(IdProject, true, true));
                    dispatch(hideLoading());
                }).catch(error => console.log(error));
            }
            else {
                enqueueSnackbar('Se han encontrado User Stories y/o Secciones sin vincular, ¡Hazlo ahora para poder continuar!.', { autoHideDuration: 4000, variant: 'warning', preventDuplicate: true });
            }
        }
        else if (section === SectionsType.ProjectDetails) {
            dispatch(startProjectStatusChanged());

            QuestionDialog(`¿Esta seguro que desea confirmar la Planeación?`, 'Proyecto').then(resp => {
                if (resp.isConfirmed) {
                    dispatch(UpdateProjectStatus(projectStatusType.IN_HOLD, true, setShowDialogConfirm));
                }
                else {
                    dispatch(EndProjectStatusChanged());
                }
            });
        }
    }

    return (
        <>
            {
                isLoadingComponents ? (
                    <Skeleton className="button dark w-100" />
                ) : (
                    <PermissionGate module={ModuleType.Projects} scopes={[SCOPES.CanUpdate]} errorProps={{ disabled: canContinueProject }}>
                        <button className="button dark w-100"
                            type="submit"
                            onClick={onClickHandler}>
                            <span className="flex-fill">{btnText}</span>
                            <span>
                                <i className="bi bi-arrow-right"></i>
                            </span>
                        </button>
                    </PermissionGate>
                )
            }
        </>
    )
}
