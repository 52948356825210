import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
  return {
    IsLoading: false,
    users: [],
    userswithstatus: []
  };
};

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoadingUsers: (state) => {
      state.IsLoading = true;
    },
    setUsers: (state, action) => {
      state.IsLoading = false;
      state.users = action.payload;
    },
    setUsersWithStatus: (state, action) => {
        state.IsLoading = false;
        state.userswithstatus = action.payload;
    },
    removeUsers: (state) => {
      state.IsLoading = false;
      state.users = [];
    },
  },
});

export const { startLoadingUsers, setUsers, setUsersWithStatus, removeUsers } = UsersSlice.actions;
