import { useSelector } from "react-redux";
import { ProjectEpic } from "../epics/ProjectEpic"
import { ProjectSections } from "../sections/ProjectSections";
import { HeaderTitle } from "../shared/Project/HeaderTitle";
import { ProjectUserStory } from "../userstories/ProjectUserStory";
import { SkeletonDefinitionsItems } from "../skeletons/SkeletonDefinitionsItems";
import { useValidationState } from "../../hooks/useValidationState";

export const ProjectPlaningBase = ({ section, validateTree }) => {

  const { isLoadingComponents, isLoadingUserStoriesComponents, isLoadingSectionsComponents } = useValidationState(section)



  return (
    <>
      <HeaderTitle section={section} />

      <div className="row overflowwrap">
        { isLoadingComponents ? <SkeletonDefinitionsItems /> : <ProjectEpic section={section} /> }
        { isLoadingComponents && isLoadingUserStoriesComponents ? <SkeletonDefinitionsItems /> : <ProjectUserStory validateTree={validateTree} section={section} /> }
        { isLoadingComponents && isLoadingSectionsComponents ? <SkeletonDefinitionsItems /> : <ProjectSections validateTree={validateTree} section={section} /> }
      </div >
    </>
  )
}
