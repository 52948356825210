import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { Resources } from '../../../../../ui/components/Resources/Resources';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { SectionsType } from '../../../../../ui/types/SectionsType';
import { useProject } from '../../../hooks/useProject';
import { UpdateProjectStatus } from '../../../slices';
import { EndProjectStatusChanged, startProjectStatusChanged } from '../../../slices/Project/ProjectSlice';
import { projectStatusType } from '../../../types/projectStatusType';
import { SprintDialog } from '../../shared/SprintDialog';
import { ActivitiesInHoldBase } from './ActivitiesInHoldBase';

export const ActivitiesInHold = ({ messageDialog = '', section}) => {
	// const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const [showDialog, setShowDialog] = useState(false);
	const { IdProject } = useProject();
	const { idRol } = useSelector((state) => state.authorization);
	const { haveError } = useSelector((state) => state.errors);


	// useEffect(() => {
	// 	if (!haveError) {
	// 		setShowDialog(true);
	// 	}
	// }, [haveError])

	const onClickConfirm = () => {

		// UpdateProjectStatus(IdProject, projectStatusType.IN_SPRINT).then(() => {
		// 	setShowDialog(true);
		// }).catch(error => {
		// 	const { data: { Message = '' } } = error.response;

		// 	if (Message !== '') {
		// 		enqueueSnackbar(Message, { variant: 'warning', preventDuplicate: true, autoHideDuration: 4000 });
		// 	}
		// });
		dispatch(startProjectStatusChanged());

		QuestionDialog(`¿Esta seguro de iniciar un Sprint?`, 'Proyecto').then(resp => {
			if (resp.isConfirmed) {
				dispatch(UpdateProjectStatus(projectStatusType.IN_SPRINT, true, setShowDialog));
			}
			else{
				dispatch(EndProjectStatusChanged());
			}
		});
	}

	return (
		<>
			{
				showDialog && <SprintDialog text={'Actividades seleccionadas con éxito, comienza el Sprint.'} setShowDialog={setShowDialog} IdProject={IdProject} />
			}
			<div className="container-plans">
				<div className="row height-plans">
					<div className="col-lg-9 col-xxxl-10 mb-3 height-100">
						<div className="card-main height-100">
							<ActivitiesInHoldBase section={section} />
						</div>
					</div>
					<div className="col-lg-3 col-xxxl-2 mb-3 height-100">
						<Resources resourceSection={SectionsType.ProjectInHold} />

						<button className="button dark w-100"
							type="submit"
							disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])}
							onClick={onClickConfirm}>
							<span className="flex-fill">Iniciar Sprint</span>
							<span>
								<i className="bi bi-arrow-right"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
