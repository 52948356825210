import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { cleanSections, GetSectionsByProject } from "../../slices";
import { DroppableSections } from "./DroppableSections";
import { ProjectSectionNew } from "./ProjectSectionNew";
import { DraggingButtonHandle } from "../shared/DraggingButtonHandle";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { useEffectOnce } from "usehooks-ts";
import Mousetrap from "mousetrap";
import { shortcutKeys } from "../../../../ui/constants/shortcutKeysConfig";
import { usePermission } from "../../../../ui/hooks/usePermission";

export const ProjectSections = ({ validateTree, section }) => {
    const dispatch = useDispatch();
    const { IdProject, UsersAdded } = useSelector((state) => state.project);
    const { totalSections } = useSelector((state) => state.sections);
    const { epics, epicSelected } = useSelector(state => state.epics);
    const { userstories } = useSelector((state) => state.userstories);
    const { hasUserStoriesChanged } = useSelector((state) => state.userstories);
    const [isNewSeccion, setIsNewSeccion] = useState(false);
    const { roleCanCreate } = usePermission(ModuleType.Sections);

    useEffect(() => {
        if (IdProject && IdProject !== 0) dispatch(GetSectionsByProject());
        else dispatch(cleanSections());
    }, [IdProject, epicSelected, dispatch])

    useEffect(() => {
        if (hasUserStoriesChanged)
            dispatch(GetSectionsByProject(true));
    }, [hasUserStoriesChanged, dispatch])


    const onCreateSection = () => setIsNewSeccion(true);
    const onCancelSection = () => setIsNewSeccion(false);
    const canEnable = () => ((epics?.length >= 1 && userstories?.length >= 1) && UsersAdded?.length >= 1) && roleCanCreate;

    useEffect(() => {
        Mousetrap.bind(shortcutKeys.newSection, (e) => {
          if (canEnable()) {
            setIsNewSeccion(true);
            e.preventDefault();
          }
        });
      }, [UsersAdded]);

    return (
        <div className="col-md-4">
            <article className="color-dark font-16 mb-3 ms-4">
                Secciones de Proyecto
            </article>
            <div className="add-plans-items">
                {
                    !isNewSeccion && (
                        <div className="add-item">
                            <span className="icon">
                                <DraggingButtonHandle hasPointer={false} />
                            </span>
                            <PermissionGate module={ModuleType.Sections} scopes={[SCOPES.CanCreate]} errorProps={{ disabled: true }}>
                                <button
                                    className="button border-grey-dot flex-fill"
                                    onClick={onCreateSection}
                                >
                                    <span className="me-2">
                                        <i className="bi bi-plus"></i>
                                    </span>
                                    <span>Agregar</span>
                                </button>
                            </PermissionGate>
                        </div>
                    )
                }

                {isNewSeccion && (
                    <ProjectSectionNew
                        sectionNum={totalSections + 1}
                        idProject={parseInt(IdProject)}
                        onCancelSection={onCancelSection}
                        onChangeItems={() => dispatch(GetSectionsByProject(true))}
                    />
                )}
                <DroppableSections
                    idProject={IdProject}
                    validateTree={validateTree}
                    canAddSection={canEnable()}
                    GetSections={() => dispatch(GetSectionsByProject(true))}
                    section={section}
                />
            </div>
        </div>
    )
}
