import { combineReducers } from "@reduxjs/toolkit";

import { AuthSlice } from "../../sections/auth/slice/AuthSlice";
import {
  EpicsSlice,
  UserStoriesSlice,
  SectionsSlice,
} from "../../sections/project/slices";
import { ActivitiesSlice } from "../../sections/project/slices/Activities/ActivitiesSlice";
import { ProjectSlice } from "../../sections/project/slices/Project/ProjectSlice";
import { ErrorSlice } from "./slices/ErrorSlice/ErrorSlice";
import { LoadingSlice } from "./slices/LoadingSlice/LoadingSlice";
import { MessageSlice } from "./slices/MessageSlice/MessageSlice";
import { ProjectsSlice } from "./slices/ProjectsSlice/ProjectsSlice";
import { UsersSlice } from "./slices/UsersSlice/UsersSlice";


export const rootReducer = combineReducers({ 
    loading: LoadingSlice.reducer,
    authorization: AuthSlice.reducer,
    project: ProjectSlice.reducer,
    projects: ProjectsSlice.reducer,
    users: UsersSlice.reducer,
    epics: EpicsSlice.reducer,
    userstories: UserStoriesSlice.reducer,
    sections: SectionsSlice.reducer,
    activities: ActivitiesSlice.reducer,
    errors: ErrorSlice.reducer,
    messages: MessageSlice.reducer,
  });