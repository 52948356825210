import React, { useEffect, useState } from 'react';
import { closestCorners, DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors, defaultDropAnimation, } from '@dnd-kit/core';
import { ActivityCard } from './Card/ActivityCard';
import { useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { findBoardSectionContainer, initializeBoard } from './utils/board';
import { getTaskById } from './utils/activities';
import { BOARD_SECTIONS } from './constants';
import { AddOrRemoveActivity } from '../../../services/sprintService';
import { GetActivitiesByUpdated } from '../../../slices/Activities/ActivitiesTrunk';
import { useDispatch } from 'react-redux';
import { ContainerCustomized } from './Styles';
import { projectStatusType } from '../../../types/projectStatusType';
import { UpdateStatus } from '../../../services/activityService';
import { BoardSections } from './BoardSection/BoardSections';
import { AvisoEmpty } from '../../shared/AvisoEmpty';
import { Fragment } from 'react';

export const Board = ({ IsSprint = false }) => {

  const dispatch = useDispatch();
  const { activities, canAddActivities } = useSelector((state) => state.activities);
  const { IdProjectStatus } = useSelector((state) => state.project);
  const { sectionSelected } = useSelector((state) => state.sections);

  const [boardSections, setBoardSections] = useState([]);
  const [activeActivityId, setActiveActivityId] = useState(null);

  useEffect(() => {
    const initialBoardSections = initializeBoard(activities, IdProjectStatus);
    setBoardSections(initialBoardSections);
  }, [activities])


  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveActivityId(active.id);
  };

  const handleDragOver = ({ active, over }) => {


    if (IdProjectStatus === projectStatusType.IN_ACTIVITY_DEFINITION) return;

    const activeContainer = findBoardSectionContainer(
      boardSections,
      active.id
    );
    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id
    );

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      const activeIndex = activeItems.findIndex((item) => item.IdActivity === active.id);
      const overIndex = overItems.findIndex((item) => item.IdActivity !== over?.id);

      const activity = activeItems.find((item) => item.IdActivity === active.id);
      const statusSelected = activeContainer === BOARD_SECTIONS['Backlog'] && overContainer === BOARD_SECTIONS['En proceso'] ? true : false;

      AddOrRemoveActivity(activity.IdSection, activity.IdActivity, statusSelected).then(response => {
        dispatch(GetActivitiesByUpdated());
      }).catch(error => {
        console.error(error);
      });

      return {
        ...boardSection,
        [activeContainer]: [
          ...boardSection[activeContainer].filter(
            (item) => item.IdActivity !== active.id
          ),
        ],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(
            overIndex,
            boardSection[overContainer].length
          ),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }) => {

    //if (IdProjectStatus === projectStatusType.IN_ACTIVITY_DEFINITION) return;

    const activeContainer = findBoardSectionContainer(
      boardSections,
      active.id
    );
    const overContainer = findBoardSectionContainer(
      boardSections,
      over?.id
    );

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = boardSections[activeContainer].findIndex((task) => task.IdActivity === active.id);
    const overIndex = boardSections[overContainer].findIndex((task) => task.IdActivity === over?.id);

    if (activeIndex !== overIndex) {
      console.log(activeIndex, overIndex, activeContainer, overContainer);

      const newOrden = arrayMove(boardSections[overContainer], activeIndex, overIndex).map((item, index) => ({
        IdActivity: item.IdActivity,
        Orden: index
      }));

      UpdateStatus(sectionSelected, { ActivitiesOrdered: newOrden });

      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(
          boardSection[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveActivityId(null);
  };

  const dropAnimation = {
    ...defaultDropAnimation,
  };

  const activity = activeActivityId ? getTaskById(activities, activeActivityId) : null;

  return (
    <Fragment>
      {
        canAddActivities ?
          <ContainerCustomized>
            < DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
            >
              {
                Object.keys(boardSections).map((boardSectionKey, index) => (
                  <BoardSections
                    key={index}
                    boardID={boardSectionKey}
                    IsSprint={IsSprint}
                    activities={boardSections[boardSectionKey]}
                  />
                ))
              }
              < DragOverlay dropAnimation={dropAnimation} >
                {activity ? <ActivityCard item={activity} /> : null}
              </DragOverlay >
            </DndContext >
          </ContainerCustomized > : <AvisoEmpty />
      }
    </Fragment>
  );
};