import React from 'react'
import { usePermission } from '../../hooks/usePermission';
import { ModuleType } from '../../types/ModuleType';
import { UserAvatar } from '../User/UserAvatar';

export const ResourceItemBasic = ({ showStatistics, onActionHandler, ...rest }) => {
    const { roleCanDelete } = usePermission(ModuleType.Users);

    const { Id, showName, Skill } = rest || {};

    return (
        <>
            <li className={showStatistics ? 'mb-2' : ''}>
                <div className="wrap">
                    <div>
                        <UserAvatar {...rest} />
                    </div>
                    <div className="flex-fill">
                        <p className="font-18 mb-1">{showName}</p>
                        <span className="d-inline-block color-font mb-1">{Skill.NameToShow}</span>
                    </div>
                    {
                        roleCanDelete && (
                            <div>
                                <span className="font-18 color-grey-6" onClick={() => onActionHandler(Id)}>
                                    <i className="icon-rc_btnn-quitar"></i>
                                </span>
                            </div>
                        )
                    }
                </div>
            </li>
        </>
    )
}
