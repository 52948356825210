import { SkeletonResourceTitle } from "../Skeletons/SkeletonResourceTitle"

export const ResourceTitle = ({ isLoading, totalItems, showDescription = false }) => {

    return (
        <>
            {
                isLoading ? (
                    <SkeletonResourceTitle showDescription={showDescription} />
                ) : (
                    <>
                        <div className="d-flex">
                            <span className="flex-fill font-20">Recursos XScrum</span>
                            <span className="font-20"> {totalItems} </span>
                        </div>
                        {showDescription && (<p className="lh-1"> Crea tu equipo de trabajo para el proyecto. </p>)}
                    </>
                )
            }

        </>
    )
}
