import React from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ProjectUserStoryItem } from './ProjectUserStoryItem';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { UpdateOrderUserStory } from '../../services/userStoryService';
import { useSelector } from 'react-redux';
import { HavePermission } from '../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../ui/types/RolesType';
import { useValidationState } from '../../hooks/useValidationState';



export const DroppableUserStories = ({ validateTree, canAddUserStory, getUserStories, section }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [userStories, setUserStories] = useState([]);
    const { idRol } = useSelector((state) => state.authorization);
    const { userstories } = useSelector((state) => state.userstories);
    const { IdProject } = useSelector(state => state.project);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setUserStories(userstories);
    }, [userstories])


    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setUserStories((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                const newOrderItems = arrayMove(items, oldIndex, newIndex);

                const orderUpdate = newOrderItems.map((item, index) => ({
                    IdUserStory: item.IdUserStory,
                    Orden: index
                }));

                UpdateOrderUserStory(IdProject, { UserStoriesOrdered: orderUpdate }).then(() => {
                    getUserStories();
                    enqueueSnackbar('Se ha actualizado el orden correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                })
                    .catch(error => {
                        console.log(error);
                    });

                return newOrderItems;
            });
        }
    }


    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={userStories}
                strategy={verticalListSortingStrategy}
            >
                {
                    userStories?.map((userStory, index) => (
                        <ProjectUserStoryItem
                            key={userStory.IdUserStory}
                            position={index + 1}
                            item={userStory}
                            validateTree={validateTree}
                            canAddUserStory={canAddUserStory}
                            getUserStories={() => getUserStories()}
                        />
                    ))
                }
            </SortableContext>
        </DndContext>
    )
}
