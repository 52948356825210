import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice = createSlice({
  name: "messages",
  initialState: {
    haveMessage: false,
    message: "",
    messageType: "",
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload.message;
      state.messageType = action.payload.messageType;
      state.haveMessage = true;
    },
    clearMessage: (state) => {
      state.message = "";
      state.haveMessage = false;
    },
  },
});

export const { setMessage, clearMessage } = MessageSlice.actions;
