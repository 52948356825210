import React from 'react'
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RecalculationData } from '../../../slices';
import { GetCatalogs } from '../../../slices/Activities/ActivitiesTrunk';
import { FilterComponent } from '../../shared/Filter/FilterComponent';
import { HistorySprints } from '../../shared/Project/HistorySprints';
import { ProjectPlanningDetailsBase } from '../ProjectPlanningDetailsBase';
import { ActivitiesInSprintDetails } from './ActivitiesInSprintDetails';

export const ActivitiesInSprintBase = ({ Name, section }) => {

    const dispatch = useDispatch();

    const { IdProject } = useSelector((state) => state.project);
    
    useEffect(() => {
        if (IdProject !== 0)
            dispatch(GetCatalogs());

    }, [IdProject, dispatch])

    return (
        <>
            <div className="new-project-body-top mb-4">
                <div className="row">
                    <div className="col-md-7">
                        <div className="dato-name color-font lh-1 mb-2 mb-md-0">
                            <div className="dropdown dropdown-main">
                                <span className="color-blue-2 font-18 dropdown-toggle mb-2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    {Name}
                                </span>

                                <div className="dropdown-menu w-75">
                                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                        <span className="flex-fill font-14">Sprints</span>
                                    </article>
                                    <hr className="" />

                                    <HistorySprints />
                                </div>
                            </div>
                            <span className="d-block">
                                Finaliza las tareas para completar el Sprint
                            </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex gap-10">
                            <span className="flex-fill">
                                <input type="text" className="form-control" placeholder="Buscar" />
                            </span>
                            <FilterComponent />

                            <div className="dropdown dropdown-main arrow-none">
                                <span className="button d-flex align-items-center grey-light font-20 px-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                    <span><i className="icon-ic_move-active"></i></span>
                                </span>

                                <div className="dropdown-menu w-75">
                                    <article className="d-flex align-items-center gap-10 lh-1 px-3">
                                        <span className="flex-fill font-14">Opciones</span>
                                    </article>
                                    <hr className="" />

                                    <div className="list-main back-none font-14 px-3">
                                        <ul>
                                            <li>
                                                <div className="wrap color-font">
                                                    <div className="flex-fill">
                                                        <span>Archivo</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="wrap color-font">
                                                    <div className="flex-fill">
                                                        <span>Detener proyecto</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown dropdown-main arrow-none" onClick={() => dispatch(RecalculationData(true))}>
                                <span className="button d-flex align-items-center grey-light font-20 px-3" type="button">
                                    <span><i className="bi bi-arrow-repeat"></i></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row overflowwrap'>
                <ProjectPlanningDetailsBase section={section}/>

                <ActivitiesInSprintDetails section={section}/>
            </div>
        </>
    )
}
