import React from 'react'
import { useState } from 'react';
import { useMemo } from 'react';
import { GetStatusActivities } from '../../../services/activityService';

export const FilterItemStatus = () => {

    const [statusActivities, setStatusActivities] = useState([])

    useMemo(() =>
        GetStatusActivities().then(response => {
            const { data: { Items } } = response;
            setStatusActivities(Items);
        }), [])

    return (
        <>
            <div className="list-main back-none font-14 px-3">
                <article className="color-font mb-2">Estado</article>
                <ul>
                    {
                        statusActivities.map((item) => (
                            <li key={item.IdStatusActivity}>
                                <div className="wrap">
                                    <div className="flex-fill">
                                        <span>{item.Name}</span>
                                    </div>
                                    <div>
                                        <span>
                                            <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))
                    }

                    {/* <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>En espera</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>En proceso</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Detenida</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Finalizada</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Cancelada</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li> */}
                </ul>
            </div>
        </>
    )
}
