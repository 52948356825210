import PropTypes from 'prop-types';
import { StadisticsIcon } from './Styles';
import { ProjectStatistics } from '../../../../../ui/constants/project'


const propTypes = {
  priority: PropTypes.string.isRequired,
};

export const ProjectStatisticsIcon = ({ priority, ...otherProps }) => {
  const iconType = [ProjectStatistics.EQUALS].includes(priority) ? 'equals' : [ProjectStatistics.LOW, ProjectStatistics.LOWEST].includes(priority)
  ? 'arrow-down'
  : 'arrow-up';

  return <StadisticsIcon type={iconType} color={priority} {...otherProps} />;
}

ProjectStatisticsIcon.propTypes = propTypes;
