import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts';
import { GetHistorySprints } from '../../../../slices';

export const HistorySprints = () => {
    const dispatch = useDispatch();
    const { IdProject, historySprints } = useSelector((state) => state.project);

    useEffect(() => {
        if (IdProject !== 0)
            dispatch(GetHistorySprints());
    }, [IdProject, dispatch])


    return (
        <div className="list-main back-none px-3 mb-3">
            <ul className="font-14">
                {
                    historySprints?.map(sprint => (
                        <li key={sprint?.IdSprint}>
                            <div className="wrap">
                                <div className="flex-fill">
                                    <span className="color-blue-2 flex-fill">{ sprint?.Name }</span>
                                </div>
                                <div>
                                    <span className="flex-none">
                                        { sprint?.TotalFinishedPoints } <span className="color-font">/{sprint?.TotalPoints} p</span>
                                    </span>
                                </div>
                                <div>
                                    <span className="color-blue-2">99%</span>
                                </div>
                            </div>
                        </li>
                    ))
                }

                {/* <li>
                    <div className="wrap">
                        <div className="flex-fill">
                            <span className="color-green flex-fill">Sprint 2</span>
                        </div>
                        <div>
                            <span className="flex-none">
                                100 <span className="color-font">/356 pts.</span>
                            </span>
                        </div>
                        <div>
                            <span className="color-green">70%</span>
                        </div>
                    </div>
                </li> */}
            </ul>
        </div>
    )
}
