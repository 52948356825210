import { createSlice } from "@reduxjs/toolkit";

export const LoadingSlice = createSlice({
  name: "loading",
  initialState: {
    IsLoading: false,
  },
  reducers: {
    showLoading: (state) => {
      state.IsLoading = true;
    },
    hideLoading: (state) => {
      state.IsLoading = false;
    }
  },
});

export const { showLoading, hideLoading  } = LoadingSlice.actions;
