import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkeletonResourceItem } from "../../../../ui/components/Skeletons/SkeletonResourceItem";
import { HavePermission } from "../../../../ui/helpers/PermissionsHelper";
import { RolesType } from "../../../../ui/types/RolesType";
import { HasLoadingAll } from "../../../../ui/helpers/LoadingStatus";
import { GetProject } from "../../slices";
import { ProjectResourceItemAdded } from "./ProjectResourceItemAdded";
import { ResourceTitle } from "../../../../ui/components/Resources/ResourceTitle";
import { Skeleton } from "@mui/material";
import { useValidationState } from "../../hooks/useValidationState";
import { memo } from "react";

export const ProjectResourceAdded = memo(function Resources({ ...rest }) {
  const dispatch = useDispatch();
  //const { IdProject, UsersAdded, saved } = useSelector((state) => state.project);
  //const {  } = useSelector((state) => state.authorization);
  const { IdProject, UsersAdded, saved, section, idRol, setIsAddResources  } = rest;

  const { isLoadingProject } = useValidationState(section);

  const onChangedItem = () => {
    dispatch(GetProject(IdProject, false, true));
  };

  return (
    <>
      {/* <div className="d-flex">
        <span className="flex-fill font-20">Recursos XScrum</span>
        <span className="font-20"> {UsersAdded?.length} </span>
      </div>
      <p className="lh-1"> Crea tu equipo de trabajo para el proyecto. </p> */}
      <ResourceTitle isLoading={isLoadingProject} totalItems={UsersAdded?.length} showDescription={true} />

      {
        HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager]) && (
          isLoadingProject ? (<Skeleton sx={{ bgcolor: '#3E3E3E' }}  height={40} />
          ) : (
            <div className="add-item mb-3">
              <button
                className={
                  saved && UsersAdded?.length === 0
                    ? "button border-grey-dot w-100 animate__bounceIn"
                    : "button border-grey-dot w-100"
                }
                disabled={!saved}
                onClick={() => setIsAddResources(true)}
              >
                <span className="me-2">
                  <i className="bi bi-plus"></i>
                </span>
                <span>Agregar</span>
              </button>
            </div>
          )
        )
      }

      <div className="overflowresources lg">
        <div className="list-main borders-bottom">
          <ul>
            {
              (isLoadingProject ? Array.from(new Array(5)) : UsersAdded)?.map((resource, index) => (
                <Fragment key={index}>
                  {
                    (resource ? <ProjectResourceItemAdded key={resource.Id} {...resource} {...rest} showName={resource.Name} /> : <SkeletonResourceItem />)
                  }
                </Fragment>
              ))
            }
          </ul>
        </div>
      </div>
    </>
  );
});
