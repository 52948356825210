
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidationState } from '../../../hooks/useValidationState';
import { GetActivitiesByUserStoryAndSection, NewActivityTemp } from '../../../slices/Activities/ActivitiesTrunk';
import { SkeletonTable } from '../../skeletons/SkeletonTable';
import { TableActivities } from './TableActivities';

export const ProjectActivitiesDetails = ({ section }) => {
    const dispatch = useDispatch();

    const { sectionSelected } = useSelector((state) => state.sections);
    const { userstorySelected } = useSelector((state) => state.userstories);
    const { hasActivitiesChanged } = useSelector((state) => state.activities);

    const { isLoadingComponents, isLoadingActivitiesComponents } = useValidationState(section)

    useEffect(() => {
        if (sectionSelected !== 0 && userstorySelected !== 0)
            dispatch(GetActivitiesByUserStoryAndSection(false, false));
    }, [sectionSelected, userstorySelected,dispatch])

    useEffect(() => {
      if(hasActivitiesChanged)
        dispatch(GetActivitiesByUserStoryAndSection(true, false));
    }, [hasActivitiesChanged, dispatch])
    


    const onNewActivity = () => {
        // setIsNewActivity(true);
        // setActivities([...activities, {
        //     IdSection: sectionSelected,
        //     IdActivity: 0,
        //     IdStatusActivity: 2,
        //     IdUser: 0,
        //     Name: '',
        //     Description: '',
        //     Points: 0,
        //     Priority: 0,
        //     Orden: activities.length + 1
        // }]);

        dispatch(NewActivityTemp());
    }

    const onCancelNewItem = () => {
        //setIsNewActivity(false);

        //const news = activities.filter(item => item.IdActivity !== 0);
        //setActivities(news);
    }

    // const onDeleteActivity = (IdActivity) => {

    //     QuestionDialog("¿Desea eliminar la actividad de la plataforma?", "Actividad").then((result) => {
    //         if (result.isConfirmed) {
    //             DeleteActivity(IdActivity).then(response => {
    //                 const { status } = response;

    //                 if (status === 200) {
    //                     enqueueSnackbar('Se ha eliminado la actividad correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
    //                     //GetActivitiesByIdSection(sectionSelected);
    //                     dispatch(GetActivities(true, false));
    //                 }
    //             });
    //         }
    //     });
    // }

    return (
        <>
            <div className="col-xl-4 flex-grow-1">
                <article className="color-dark font-16 mb-3">
                    Actividades
                </article>

                {
                    (isLoadingComponents || isLoadingActivitiesComponents) ? <SkeletonTable /> : <TableActivities />
                }

                {/* {
                    HasLoadingAllWithActivities() ? <SkeletonTable /> : (
                        (activities.length !== 0 || sectionSelected !== 0) ? (
                            <TableActivities onDeleteActivity={onDeleteActivity}
                            />
                        ) : (
                            <AvisoEmpty />
                        )
                    )
                } */}
                {/* {
                    activities.length !== 0 && (
                        <div className="table-responsive table-simple">
                            <TableActivities
                                activities={activities}
                                IdSection={sectionSelected}
                                onDeleteActivity={onDeleteActivity}
                                GetActivitiesByIdSection={() => dispatch(GetActivities(true, false))}
                                onCancelNewItem={onCancelNewItem}
                            //setIsNewActivity={setIsNewActivity}
                            />
                        </div>
                    )
                }
                {
                    (userStorySelected === 0 || sectionSelected === 0) && <AvisoEmpty />
                } */}

                {/* {
                    (!isNewActivity && (activities.length !== 0 || sectionSelected !== 0) && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])) && (
                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={onNewActivity}>
                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                            Agregar Actividad
                        </span>)
                } */}
            </div>
        </>
    )
}
