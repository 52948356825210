import { Skeleton } from '@mui/material';
import { convertDate } from '../../../../../../ui/helpers';
import { SectionsType } from '../../../../../../ui/types';
import { GoalsType } from '../../../../../../ui/types/GoalsType';
import { useValidationState } from '../../../../hooks/useValidationState';
import { TabGridProjectSprint } from './TabGridProjectSprint';
import { TabItem } from './TabItem';
import { TabProject } from './TabProject';

export const EstimateTabs = ({ ...rest }) => {

    console.log(rest);

    const { section, goals, planning, planningsprint, sprint, setTabSelected } = rest || {};
    const { isLoadingComponents } = useValidationState(section)

    function tabsHeaderProps(index, isActive = false) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `dynamic-tab-panel-${index}`,
            'data-bs-target': `#dynamic-tab-panel-${index}`,
            'className': `nav-link ${isActive ? 'active' : ''}`,
        };
    }

    function tabContentProps(index, isActive = false) {
        return {
            id: `dynamic-tab-panel-${index}`,
            'aria-labelledby': `simple-tab-${index}`,
            'className': `tab-pane fade ${isActive ? 'show active' : ''}`
        }
    }


    return (
        <div className="estimacion mb-3">
            <article className="color-font text-center mb-3">
                <span className="font-16 mb-1">{(isLoadingComponents) ? <Skeleton sx={{ bgcolor: '#3E3E3E' }} /> : 'Estimación del Trabajo'}</span>                    <br />
                {(!isLoadingComponents && goals === GoalsType.Sprint) && <small>{convertDate(sprint?.StartDate)} - {convertDate(sprint?.PlannedEndDate)}</small>}
            </article>

            {
                (!isLoadingComponents) && (<>
                    <ul className="nav nav-tabs tabs-main" id="myTab" role="tablist">
                        {goals === GoalsType.Planning &&
                            (<>
                                <TabItem onClick={() => setTabSelected(0)} title={'Proyecto'} {...tabsHeaderProps(0, (section === SectionsType.Project || section === SectionsType.ProjectDetails || section === SectionsType.ProjectInHold) ? true : false)} />
                                <TabItem onClick={() => setTabSelected(1)} title={'Sprint'} {...tabsHeaderProps(1, (section === SectionsType.ProjectInSprint) ? true : false)} />
                            </>)
                        }
                        {
                            goals === GoalsType.Sprint && (<>
                                <TabItem onClick={() => setTabSelected(0)} title={'Proyecto'} {...tabsHeaderProps(0)} />
                                <TabItem onClick={() => setTabSelected(1)} title={'Sprint'} {...tabsHeaderProps(1, true)} />
                                {/* <TabItem onClick={() => setTabSelected(2)} title={'Sprint'} {...tabsHeaderProps(3, true)} /> */}
                            </>)
                        }

                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="objetivos-tab" data-bs-toggle="tab" data-bs-target="#objetivos-tab-pane" type="button" role="tab" aria-controls="objetivos-tab-pane" aria-selected="true">
                                Proyecto
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="avance-tab" data-bs-toggle="tab" data-bs-target="#avance-tab-pane" type="button" role="tab" aria-controls="avance-tab-pane" aria-selected="false">
                                Sprint
                            </button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="estimaciones-tab" data-bs-toggle="tab" data-bs-target="#estimaciones-tab-pane" type="button" role="tab" aria-controls="estimaciones-tab-pane" aria-selected="false">
                                Estimaciones
                            </button>
                        </li> */}
                    </ul>
                    <div className="tab-content pt-1" id="myTabContent">
                        {goals === GoalsType.Planning &&
                            (
                                <>
                                    <TabGridProjectSprint initData={planning} statusData={sprint?.PlanningComparison} {...tabContentProps(0, (section === SectionsType.Project || section === SectionsType.ProjectInHold ||  section === SectionsType.ProjectDetails) ? true : false)} />
                                    <TabGridProjectSprint initData={planningsprint} statusData={sprint?.PlanningSprintComparison} {...tabContentProps(1, (section === SectionsType.ProjectInSprint) ? true : false)} />
                                </>
                            )
                        }
                        {

                            goals === GoalsType.Sprint && (<>
                                {/* <TabProject item={planning} IsSprint={true} {...tabContentProps(0)} />
                                <TabProject item={planningsprint} IsSprint={true} {...tabContentProps(1, true)} /> */}
                                <TabGridProjectSprint initData={planning} statusData={sprint?.PlanningComparison}  {...tabContentProps(0)} />
                                <TabGridProjectSprint initData={planningsprint} statusData={sprint?.PlanningSprintComparison}  {...tabContentProps(1, true)} />
                                

                                {/* <TabSprint item={sprint} {...tabContentProps(3, true)} /> */}

                            </>
                            )
                        }

                        {/* <TabSprint item={rest} {...tabContentProps(1)} /> */}
                        {/* <div className="tab-pane fade" id="avance-tab-pane" role="tabpanel" aria-labelledby="avance-tab">
                            2
                        </div> */}
                        {/* <div className="tab-pane fade" id="estimaciones-tab-pane" role="tabpanel" aria-labelledby="estimaciones-tab" tabindex="0">
                            3
                        </div> */}
                    </div>
                </>)
            }
        </div>
    )
}
