import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import imagenes from "../../../assets/imgs/imagenes";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Login } from "../slice/thunks";

export const LoginPage = () => {
  const { register, formState: { errors }, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const [showOrHide, setShowOrHide] = useState(false)

  const onLogin = ({ email, password }) => {
    dispatch(Login(email, password));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onLogin)}>
        <div className="container-main-wrap">
          <div className="login-wrap m-auto pt-md-5">
            <div className="logo text-center">
              <img src={imagenes.img1} alt="" />
            </div>

            <div className="card-main mt-4">
              <article className="font-24 fw-bold mb-4">Iniciar Sesión</article>

              <div className="mb-3">
                <label className="form-label">Correo Electrónico</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <p className="msgError"> El campo es obligatorio.</p>
                )}
              </div>

              <div className="mb-2">
                <label className="form-label">Password</label>
                <div className="input-group over-icon">
                  <input
                    type={showOrHide ? 'text' : 'password'}
                    className="form-control"
                    {...register("password", { required: true })}
                    placeholder=""
                  />
                  <span className="btn " type="button" onClick={() => setShowOrHide(!showOrHide)} >
                    {showOrHide ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>
                    }
                  </span>
                </div>
                {errors.password && (
                  <p className="msgError"> El campo es obligatorio. </p>
                )}
              </div>

              <Link to="/loginchangepass" className="color-blue-2 font-16">
                Olvidé mi contraseña
              </Link>

              <button className="button dark w-100 mt-4">Ingresar</button>
            </div>

            <div className="text-center mt-4">
              <Link to="" className="color-font-dark">
                ¿Aún no tienes cuenta?
              </Link>

              <button className="button grey-light w-100 mt-4">
                Crear cuenta
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
