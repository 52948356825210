import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionDialog } from '../../../../../ui/components/Dialogs';
import { useValidationState } from '../../../hooks/useValidationState';
import { DeleteActivity} from '../../../services/activityService';
import { GetActivitiesByUserStoryAndSection, NewActivityTemp } from '../../../slices/Activities/ActivitiesTrunk';
import { SkeletonTable } from '../../skeletons/SkeletonTable';
import { TableActivitiesInSprint } from './TableActivitiesInSprint';

// const defaulNewActivity = (idSection, idUser, order) => {
//     return {
//         IdSection: idSection,
//         IdActivity: 0,
//         IdStatusActivity: 2,
//         IdUser: 0,
//         Name: '',
//         Description: '',
//         Points: 0,
//         Priority: 0,
//         Orden: order,
//         OriginActivity: { IdOriginActivity: 3 }
//     }
// }

export const ActivitiesInSprintDetails = ({ section }) => {
    const { sectionSelected } = useSelector((state) => state.sections);
    const { userstorySelected } = useSelector((state) => state.userstories);
    const { statusActivities } = useSelector((state) => state.activities);

    const { isLoadingComponents, isLoadingActivitiesComponents } = useValidationState(section)



    //const { UsersAdded } = useProject();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    //const [isNewActivity, setIsNewActivity] = useState(false);

    //const [activities, setActivities] = useState([]);

    // const GetActivitiesByIdSection = (IdSection, continueAdd = false) => {
    //     if (IdSection !== 0) {
    //         GetByIdSection(IdSection).then(response => {
    //             const { status, data: { Items } } = response;

    //             if (status === 200)
    //             setChangedActivities(true);

    //             if(continueAdd){
    //                 setIsNewActivity(true);
    //                 setActivities([...Items, defaulNewActivity(IdSection, UsersAdded[0]?.Id, Items.length + 1)]);
    //             }
    //             else{
    //                 setActivities(Items);
    //                 setIsNewActivity(false);
    //             }
    //         });
    //     }
    // }

    // useMemo(() =>
    //     GetStatusActivities().then(response => {
    //         const { data: { Items } } = response;
    //         setStatusActivities(Items);
    //     }), [])

    useEffect(() => {

        if (sectionSelected !== 0 && userstorySelected !== 0) {
            // GetStatusActivities().then(response => {
            //     const { data: { Items } } = response;
            //     setStatusActivities(Items);
            dispatch(GetActivitiesByUserStoryAndSection());
            // })
        }
    }, [sectionSelected, userstorySelected, dispatch])

    // useEffect(() => {
    //     if (IdSection && IdSection !== 0) {
    //         GetByIdSection(IdSection).then(response => {
    //             const { status, data: { Items } } = response;

    //             if (status === 200) {
    //                 setActivities(Items);
    //             }
    //         });
    //     } else {
    //         setActivities([]);
    //     }
    // }, [IdSection])


    const onNewActivity = () => {
        // setIsNewActivity(true);
        // setActivities([...activities, defaulNewActivity(IdSection, UsersAdded[0]?.Id, activities.length + 1)]);
        dispatch(NewActivityTemp(true));

    }

    const onCancelNewItem = () => {
        // setIsNewActivity(false);
        // const news = activities.filter(item => item.IdActivity !== 0);
        // setActivities(news);
        // GetActivitiesByIdSection(IdSection, false);
    }

    const onDeleteActivity = (IdActivity) => {
        QuestionDialog("¿Desea eliminar la actividad del proyecto?", "Actividad").then((result) => {
            if (result.isConfirmed) {
                DeleteActivity(IdActivity).then(response => {
                    const { status } = response;

                    if (status === 200) {
                        enqueueSnackbar('Se ha eliminado la actividad correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
                        //GetActivitiesByIdSection(sectionSelected);
                        dispatch(GetActivitiesByUserStoryAndSection(true, false, true));
                    }
                });
            }
        });
    }

    return (
        <>
            <div className="col-xl-4 flex-grow-1">
                <article className="color-dark font-16 mb-3">
                    Actividades
                </article>

                {
                    (isLoadingComponents || isLoadingActivitiesComponents) ? <SkeletonTable /> : <TableActivitiesInSprint />

                }
                {/* {
                    (!isNewActivity && (activities.length !== 0 || sectionSelected !== 0) && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])) && (
                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={onNewActivity}>
                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                            Agregar Actividad
                        </span>)
                } */}

                {/* {
                    (IdSection !== 0) ? (
                        <div className="table-responsive table-simple">
                            <TableActivitiesInSprint
                                statusActivities={statusActivities}
                                activities={activities}
                                IdSection={IdSection}
                                onDeleteActivity={onDeleteActivity}
                                onItemChanged={(id, status) => GetActivitiesByIdSection(id, status)}
                                onCancelNewItem={onCancelNewItem}
                                setIsNewActivity={setIsNewActivity}
                            />
                        </div>

                    ) : (
                        <AvisoEmpty />
                    )
                }
                {
                    (!isNewActivity && IdSection !== 0 && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource])) && (
                        <span role="button" className="d-inline-flex align-items-center color-font lh-1" onClick={onNewActivity}>
                            <span className="font-18 me-2"><i className='bi bi-plus'></i></span>
                            Agregar Actividad
                        </span>)
                } */}
            </div>
        </>
    )
}
