import { useSnackbar } from "notistack";
import { useRef } from "react";
import { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useOnClickOutside } from "usehooks-ts";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { QuestionDialog } from "../../../../ui/components/Dialogs";
import { shortcutKeys } from "../../../../ui/constants/shortcutKeysConfig";
import { isEmptyOrSpaces } from "../../../../ui/helpers/StringHelper";
import { NewEpic } from "../../slices";
import { SkeletonButtonAddItem } from "../skeletons/SkeletonButtonAddItem";
import { DraggingButtonHandle } from "../shared/DraggingButtonHandle";

export const ProjectEpicNew = ({ EpicNum, onCancelNewEpic }) => {
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();


  const [epicName, setEpicName] = useState("");

  const dispatch = useDispatch();
  const { hasEpicsChanged } = useSelector(state => state.epics);

  const { message } = useSelector((state) => state.messages);

  useEffect(() => {
    if (message && message !== '') {
      setEpicName('');
      //enqueueSnackbar(message, { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
    }
    // if (IsError) enqueueSnackbar(errorMessage, { variant: 'warning', preventDuplicate: true, autoHideDuration: 4000 });

  }, [message, enqueueSnackbar])


  // const onUpdateEpic = ({ target: { value } }) => {
  //   //setName(value);
  //   setEpicName(value);
  // };

  const onKeyHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!isEmptyOrSpaces(epicName)) {
        dispatch(NewEpic(epicName, EpicNum));
      }

    } else if (e.keyCode === 27) {
      setEpicName('');
      onCancelNewEpic();
    }
  }

  // const onKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (!isEmptyOrSpaces(epicName)) {
  //       savingEpic();
  //     }
  //   } else if (e.key === "Escape") {
  //     setEpicName('');
  //     onCancelNewEpic();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {

      setEpicName('');
      onCancelNewEpic();
    }
  };

  // const onSave = () => {
  //   //dispatch(NewEpic(epicName, EpicNum));

  //   const newEpic = {
  //     IdProject: IdProject,
  //     Name: `EP ${EpicNum}`,
  //     Description: epicName,
  //     Orden: EpicNum,
  //   };

  //   SaveEpic(newEpic).then((response) => {
  //     const { status, data: { AffectedId } } = response;

  //     if (status === 200) {
  //       //setCanAddUserStories(true);
  //       //setIsNewEpic(false);
  //       //onSaveEpic(AffectedId);
  //       setEpicName('');
  //       onChangeItems();
  //     }
  //   });
  // }

  const onCancel = () => {
    setEpicName('');
    onCancelNewEpic();
  }

  const savingEpic = () => {
    dispatch(NewEpic(epicName, EpicNum));

    // const newEpic = {
    //   IdProject: IdProject,
    //   Name: `EP ${EpicNum}`,
    //   Description: epicName,
    //   Orden: EpicNum,
    // };

    // SaveEpic(newEpic).then((response) => {
    //   const { status, data: { AffectedId } } = response;

    //   if (status === 200) {
    //     //setCanAddUserStories(true);
    //     //setIsNewEpic(false);
    //     onSaveEpic(AffectedId);
    //     setEpicName('');
    //     //getEpics();
    //   }
    // });
  };

  const handleClickOutside = () => {
    // if (!isEmptyOrSpaces(epicName)) {
    //   QuestionDialog('Existe información no guardado en Épicas, ¿Desea guardarlo?', 'Épicas').then(result => {
    //     if (result.isConfirmed) {
    //       dispatch(NewEpic(epicName, EpicNum));
    //   }});
    // }else {
    //   onCancelNewEpic();
    // }
    onCancelNewEpic();

  }

  useOnClickOutside(ref, handleClickOutside)



  return (
    <>
      <div
        ref={ref}
        className="plans-items d-flex"
        onKeyDown={handleKeyDown}
      >
        <span className="icon mt-2">
          <DraggingButtonHandle />
        </span>
        {
          hasEpicsChanged ? (<SkeletonButtonAddItem />) : (
            <div className="plan-item flex-fill">
              <div className="names">
                <span className="plan-tit color-font-dark flex-none">{`Épica ${EpicNum}`}</span>
                <ControllerInput
                  value={epicName}
                  onChange={({ target: { value } }) => setEpicName(value)}
                  onKeyDown={onKeyHandler}
                  className={"form-control"}
                />
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}
