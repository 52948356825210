import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinearChart = ({ percent, colorcustom }) => {

    const BorderLinearProgress = styled(LinearProgress)(({ theme, colorcustom }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: colorcustom === undefined ? '#1867e1' : colorcustom,
        },
    }));

    return (
        <BorderLinearProgress colorcustom={colorcustom} variant="determinate" value={percent} />
    )
}
