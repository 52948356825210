import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { QuestionDialog } from '../../../ui/components/Dialogs';
import { CancelOperation } from '../slices/Activities/ActivitiesTrunk';

export const usePersistData = () => {

    const dispatch = useDispatch();

    const { activities } = useSelector((state) => state.activities);

    console.log(activities);

    // Metodo encargado de validar si existe alguna actividad no guardada.
    const hasActivityNoSaved = () => {
        if(activities?.filter(act => act.IdActivity === 0).length > 0){
            QuestionDialog('Hay actividades con cambios pendientes de guardar, ¿Desea descartar los cambios?', 'Actividades').then(result => {
                if(result.isConfirmed){
                    //goNextAction();
                }
            });
        }
    }

    return ({
        hasActivityNoSaved
    })
}
