import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
  return {
    IsLoading: true,
    saved: false,
    project: [],
    IdProject: 0,
    IdProjectStatus: 0,
    Name: "",
    UsersAdded: [],
    url: "",
    urlNextStatus: "",
    lastStatus: 0,
    sprint: null,
    planning: null,
    planningsprint: null,
    canContinueProject: false,
    IsFirstLoad: true,
    hasProjectStatusChanged: false,
    previousSection: 0,
    IsProjectUpdated: false,
    historySprints: []
  };
};

export const ProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    startLoadingProject: (state) => {
      state.IsLoading = true;
    },
    startProjectStatusChanged: (state) => {
      state.hasProjectStatusChanged = true
    },
    setProject: (state, action) => {
      state.IsLoading = false;
      state.saved = true;
      state.project = action.payload.Item;

      const { Item: { IdProject, IdStatusProject, IdStatusProjectPrev, Name, ProjectUsersList, Sprint, Planning, PlanningSprint } } = action.payload;

      state.IdProjectStatus = IdStatusProject;
      state.Name = Name;
      state.UsersAdded = ProjectUsersList;
      state.url = action.payload.url;
      state.lastStatus = IdStatusProjectPrev;
      state.sprint = Sprint;
      state.planning = Planning;
      state.planningsprint = PlanningSprint;
      state.IdProject = IdProject;

      state.IsFirstLoad= false;
    },
    setPreviousSection: (state, action) => {
      state.previousSection = action.payload;
    },
    cleanProject: (state) => {
      state.saved = false;
      state.project = [];
      state.IsLoading = true;
      state.IdProject = 0;
      state.IdProjectStatus = 0;
      state.Name = "";
      state.UsersAdded = [];
      state.url = "";
      state.lastStatus = 0;
      state.sprint = null;
      state.planning = null;
      state.planningsprint = null;
      state.IsFirstLoad= true;
      state.previousSection= 0;
      state.historySprints = []
    },
    setCanContinueProject: (state, action) => {
      state.canContinueProject = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    setLastProjectStatus: (state, action) => {
      state.lastStatus = action.payload;
    },
    setProjectName: (state, action) => {
      state.Name = action.payload;
    },
    EndLoadingProject: (state) => {
      state.IsLoading = false;
    },
    EndProjectStatusChanged: (state) => {
      state.hasProjectStatusChanged = false;
    },
    setStatusProject: (state, action) => {
      state.IsProjectUpdated = action.payload;
    },
    setHistorySprints: (state, action) => {
      state.historySprints = action.payload;
    },
    removeUserInProject: (state, action) => {},
    addUserToProject: (state, action) => {},
  },
});

export const {
  startLoadingProject,
  setProject,
  setUrl,
  setCanContinueProject,
  setLastProjectStatus,
  setProjectName,
  setRefreshProject,
  setPreviousSection,

  cleanProject,
  removeUserInProject,
  addUserToProject,
  EndLoadingProject,
  startProjectStatusChanged,
  EndProjectStatusChanged,
  setStatusProject,
  setHistorySprints,
  
} = ProjectSlice.actions;
