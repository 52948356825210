import imagenes from "../../../assets/imgs/imagenes";

export const LoginResetPassPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="login-wrap m-auto pt-md-5">
                    <div className="logo text-center">
                        <img src={imagenes.img1} alt="" />
                    </div>

                    <div className="card-main mt-4">
                        <article className="font-24 fw-bold mb-4">Restablece tu contraseña</article>
                        <p>
                            Ingresa tu correo electrónico a continuación, te enviemos un código para restablecer la contraseña.
                        </p>

                        <div className="mb-4">
                            {/* <label className="form-label">Ingresa nueva contraseña</label> */}
                            <input type="password" className="form-control" />
                        </div>

                        <button className="button dark w-100 mb-2">Continuar</button>
                        <button className="button light w-100">Cancelar</button>
                    </div>
                </div>
            </div>
        </>
    )
}
