import { GetErrorResponse } from "../../../../ui/helpers/ErrorHelper";
import { cleanMessageError, setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { clearMessage, setMessage } from "../../../../ui/store/slices/MessageSlice/MessageSlice";
import { MessageType } from "../../../../ui/types/MessageType";
import {
  GetUserStoriesByIdEpic,
  GetUserStoriesByIdProjectRelation,
  onGetByProjectAndEpicWithEpicsRelation,
  SaveUserStory,
} from "../../services/userStoryService";
import {
  cleanActivities,
  EndLoadingActivities,
  setActivities,
  setAddActivities,
} from "../Activities/ActivitiesSlice";
import { EndEpicSelectedChanged } from "../Epics/EpicsSlice";
import {
  EndCheckUserStoryRelated,
  endLoadingSections,
  restoreSections,
  setRefreshSectionSelected,
  setSections,
  setSectionSelected,
} from "../Sections/SectionsSlice";
import {
  checkUserStoriesRelationshipCompleted,
  refreshUserStories,
  setUserStories,
  startUserStoriesChanges,
  startUserStories,
  setUserStorySelected,
  endLoadingUserStories,
  setRefreshUserStorySelected,
  startUserStorySelectedChange,
  setUserStorySelectedByIndex,
  startUserStorySelectedChangeByIndex,
  EndLoadingUserStoriesChanges,
  setCanLoadSections,
  startCheckSectionRelated,
} from "./UserStoriesSlice";

const GetUserStoriesByProject = (IsRefresh = false) => {
  return async (dispatch, getState) => {
    dispatch(clearMessage());
    dispatch(cleanMessageError());

    if (IsRefresh) dispatch(startUserStoriesChanges());
    else dispatch(startUserStories());

    const {
      project: { IdProject },
      epics: { epicSelected }
    } = getState();

    await GetUserStoriesByIdProjectRelation(IdProject)
    //await onGetByProjectAndEpicWithEpicsRelation(IdProject, epicSelected)
      .then((response) => {
        const {
          status,
          data: { Items },
        } = response;

        console.log(response);


        if (status === 200) {
          if (IsRefresh) dispatch(refreshUserStories(Items));
          else dispatch(setUserStories(Items));

          dispatch(checkUserStoriesRelationshipCompleted(Items));
        }
      })
      .finally(() => {
        dispatch(endLoadingUserStories());
        dispatch(endLoadingSections());
        dispatch(EndLoadingActivities());
      });
  };
};

const GetUserStoriesByEpic = () => {
  return async (dispatch, getState) => {

    const { project: { IdProject }, epics: { epicSelected }, userstories: { userstorySelected }, sections: { sections, sectionSelected}} = getState();

    //GetUserStoriesByIdEpic(epicSelected)
    await onGetByProjectAndEpicWithEpicsRelation(IdProject, epicSelected).then((response) => {
        const { data: { Items }, } = response;

        if (Items.length >= 1) {
          dispatch(setUserStories(Items));
          //dispatch(setUserStorySelected(Items[0]?.IdUserStory));
          
          const haveRelation = sections?.find(item => item.IdSection === sectionSelected)!== undefined;
          if(haveRelation){
             const { UserStoriesRelated } = sections?.find(item => item.IdSection === sectionSelected);

             // verificamos si la us seleccionada esta relacionada a la seccion seleccionada
            if(UserStoriesRelated.find(us => us.IdUserStory === userstorySelected && us.IsSelected)){
              dispatch(setUserStorySelected(userstorySelected));

            } else {
              const usItem = UserStoriesRelated.find(item => item.IsSelected);
                dispatch(setUserStorySelected(usItem.IdUserStory));
            }
         
          }
        } else {
          dispatch(setUserStorySelected(0));
          dispatch(setUserStories([]));
          dispatch(setSectionSelected(0));
          dispatch(setSections([]));
          dispatch(setAddActivities(false));
        }

      })
      .finally(() => {
        dispatch(endLoadingUserStories());
        dispatch(endLoadingSections());
        dispatch(EndLoadingActivities());
      });
  };
};

const GetUserStories = () => {
  return async (dispatch, getState) => {
    const { project: { IdProject }, epics: { epicSelected, epicSelectedChanged }, sections: { sections, sectionSelected} } = getState();

    await onGetByProjectAndEpicWithEpicsRelation(IdProject, epicSelected).then((response) => {
      const { data: { Items } } = response;

        //dispatch(setCanLoadSections(false));
        dispatch(setSections([]));
        dispatch(setActivities([]));

        if(Items?.length === 0){
          dispatch(setAddActivities(false));
          dispatch(setCanLoadSections(false));
          dispatch(setUserStorySelected(0));

          return;
        } else {
          dispatch(setUserStorySelected(Items[0]?.IdUserStory));
          dispatch(setUserStories(Items));
          console.log('Selected ->?', Items[0]?.IdUserStory);

        }

        console.log('CARGO DE PRIMERA?', !epicSelectedChanged);
       
        if(!epicSelectedChanged){
          dispatch(startCheckSectionRelated());
        } 

           // Verificamos si tenemos secciones relacionadas al primer us
           const { SectionsRelated } = Items[0];

           console.log(SectionsRelated);
 
           if(SectionsRelated.length >= 1) {
             dispatch(setCanLoadSections(true));
           }
           else {
             dispatch(setAddActivities(false));
             dispatch(setCanLoadSections(false));
           }

    }).finally(() => {
      dispatch(EndEpicSelectedChanged());
    });
  }
}

const NewUserStory = (description, orden) => {
  return async (dispatch, getState) => {
    dispatch(startUserStoriesChanges());

    const { project: { IdProject }, } = getState();

    const userStoryNew = {
      IdProject: IdProject,
      Name: `US ${orden}`,
      Description: description,
      Orden: orden,
    };

    SaveUserStory(userStoryNew).then((response) => {
      const { data: { Message }} = response;
      dispatch(GetUserStoriesByProject(true))
      dispatch(setMessage({ message: `User Story: ${Message}`, messageType: MessageType.success }));
    }).catch((error) => {
      const { status } = error.response;

      dispatch(
        setMessageError({
          message: GetErrorResponse(error),
          errorType: MessageType.error,
          status: status
        })
      );    
      dispatch(EndLoadingUserStoriesChanges());
    });
  };
};

const ChangeUserStorySelected = (idUserStory, IsRelational) => {
  return async (dispatch, getState) => {
    const { userstories: { userstorySelected, userstories }, sections: { sectionSelected } } = getState();

    if (userstorySelected !== idUserStory) {

      dispatch(setRefreshUserStorySelected(idUserStory));

      if(!IsRelational){
        console.log('Entra aqui');
        if(userstories.length >= 1){
          const { SectionsRelated } = userstories?.find(item => item.IdUserStory === idUserStory)

          if(SectionsRelated && SectionsRelated.length === 0){
            dispatch(setRefreshSectionSelected(0));
            dispatch(setSections([]));
            dispatch(setAddActivities(false));
          } else {
            dispatch(restoreSections());

            // Verificamos que el ID de User
            const secItem = SectionsRelated?.find(item => item.IdUserStory !== 0);
            console.log(secItem);
            dispatch(setRefreshSectionSelected(secItem.IdSection));
          }
        }
        dispatch(setRefreshUserStorySelected(idUserStory));
      } else {
        dispatch(EndEpicSelectedChanged());
        dispatch(startUserStorySelectedChange());
        dispatch(cleanActivities());
        // dispatch(setSectionSelected(0));
        // dispatch(setSections([]));

        setTimeout(() => {
          dispatch(setRefreshUserStorySelected(idUserStory));
        }, 100);
      }


    // if(sectionSelected !== 0 && userstories.length >= 1){
    //     console.log(userstories);
    //   const { SectionsRelated } = userstories?.find(item => item.IdUserStory === idUserStory)

    //   console.log(SectionsRelated && SectionsRelated.length === 0);

    //   if(SectionsRelated && SectionsRelated.length === 0) { 
    //     dispatch(setRefreshSectionSelected(0));
    //     dispatch(setAddActivities(false))
    //   } else {
    //     const secItem = SectionsRelated?.find(item => item.IdUserStory === idUserStory);
    //     console.log(secItem);
    //     dispatch(setRefreshSectionSelected(secItem.IdSection));
      
    //   }
      
   
    // }
    }
  };
};

export {
  GetUserStoriesByProject,
  GetUserStoriesByEpic,
  GetUserStories,
  ChangeUserStorySelected,

  NewUserStory,
};
