import { Avatar, Skeleton } from '@mui/material'
import React from 'react'

export const SkeletonResourceItem = () => {
    return (
        <li>
            <div className="wrap">
                <div>
                    <Skeleton sx={{ bgcolor: '#3E3E3E' }} variant='circular'>
                        <Avatar />
                    </Skeleton>
                </div>
                <div className="flex-fill">
                    <p className="font-18 mb-1"><Skeleton sx={{ bgcolor: '#3E3E3E' }} /></p>
                    <span className="color-font"><Skeleton sx={{ bgcolor: '#3E3E3E' }}/></span>
                </div>
                <div>
                    <span className="font-18 color-grey-6">
                        <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                    </span>
                </div>
            </div>
        </li>
    )
}
