import { createSlice } from "@reduxjs/toolkit";
import { RolesType } from "../../../../ui/types";

export const ActivitiesSlice = createSlice({
  name: "activities",
  initialState: {
    isLoading: true,
    hasActivitiesChanged: false,
    activities: [],
    activitiesSelected: [],
    isNewActivity: false,
    statusActivities: [],
    originActivities: [],
    isLoadingCatalogStatus: false,
    canAddActivities: false,
    canAddMoreActivities: false,
    filtersByStatusActivityIds: [],
    filtersByResourcesIds: [],
    filtersByOriginActivityIds: [],
  },
  reducers: {
    startActivities: (state) => {
      state.isLoading = true;
    },
    startChangeActivities: (state) => {
      state.hasActivitiesChanged = true;
    },
    endChangeActivities: (state) => {
      state.hasActivitiesChanged = false;
    },
    startLoadCatalogStatus: (state) => {
      state.isLoadingCatalogStatus = true;
    },
    startActionLoading: (state, action) => {
      //if(action.type)
      //action.payload.firstLoad ? state
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
      state.isLoading = false;
    },
    setActivitiesSelected: (state, action) => {
      state.activitiesSelected = action.payload;
    },
    setIsNewActivity: (state, action) => {
      state.isNewActivity = action.payload;
    },
    addNewActivityTemp: (state, action) => {
      console.log(action.payload.idRol);

      let activity = {
        IdEpic: action.payload.epicId,
        IdSection: action.payload.sectionid,
        IdUserStory: action.payload.userstoryid,
        IdActivity: 0,
        IdStatusActivity: 2,
        IdUser: parseInt(action.payload.idRol) === RolesType.Resource ? action.payload.idUser : 0,
        IdOriginActivityType: 1,
        IsSelectedForSprint: false,
        Name: "",
        Description: "Description",
        Points: 0,
        Priority: 0,
        Orden: action.payload.size,
        OriginActivity: { IdOriginActivity: 3 },
      };

      state.activities = [...action.payload.activities, activity];
    },
    addNewActivityWithTaskTemp: (state, action) => {
      console.log(action.payload.idRol);
      console.log(parseInt(action.payload.idRol) === RolesType.Resource);

    
      let activity = {
        IdEpic: action.payload.epicId,
        IdSection: action.payload.sectionid,
        IdUserStory: action.payload.userstoryid,
        IdActivity: 0,
        IdStatusActivity: 2,
        IdUser: parseInt(action.payload.idRol) === RolesType.Resource ? action.payload.idUser : 0,
        IdOriginActivityType: 1,
        IsSelectedForSprint: false,
        Name: "",
        Description: "Description",
        Points: 0,
        Priority: 0,
        Orden: action.payload.size,
        OriginActivity: { IdOriginActivity: 3 },
      };

      console.log(activity);


      state.activities = [...action.payload.activities, activity];
    },
    cancelAction: (state) => {
      state.activities = state.activities.filter(
        (item) => item.IdActivity !== 0
      );
      state.isNewActivity = false;
      state.canAddMoreActivities = false;
    },
    cleanActivities: (state) => {
      state.isLoading = true;
      state.hasActivitiesChanged = false;
      state.activities = [];
      state.activitiesSelected = [];
      state.canAddActivities = false;
      state.isNewActivity = false;
      state.isLoadingCatalogStatus = false;
      state.canAddMoreActivities = false;
    },
    refreshActities: (state, action) => {
      state.hasActivitiesChanged = false;
      state.activities = action.payload;
    },
    setCatalogStatus: (state, action) => {
      state.isLoadingCatalogStatus = false;
      state.statusActivities = action.payload;
    },
    setCatalogOriginActivities: (state, action) => {
      state.originActivities = action.payload;
    },
    setAddActivities: (state, action) => {
      state.canAddActivities = action.payload;
    },
    setNotifyPointsChanged: (state, action) => {
      state.hasActivitiesChanged = false;
    },
    EndLoadingActivities: (state) => {
      state.isLoading = false;
    },
    setActivity: (state, action) => {
      state.activities = state.activities.map(item => {
        if(item.IdActivity === 0){
          return {...item, ...action.payload}
        }
        return item;
      });

      state.isNewActivity = false;
    }, 
    saveActivityTemp: (state, action) => {
      console.log(action.payload);

      state.activities = state.activities.map(item => {

        if(item.IdActivity === 0){
          return {...item, ...action.payload }
        }
        return item;
      });
    },
    setCanContinueAdd: (state, action) => {
      state.canAddMoreActivities = action.payload;
    },
    updateActivities: (state, action) => {
      state.activities = state.activities.map(act => {
        if(act.IdActivity === action.payload.IdActivity){
          return {...act, ...action.payload }
        }
        return act;
      });
    },
    setFilterByStatusActivitiesIds: (state, action) => {
      state.filtersByStatusActivityIds = action.payload;
    },
    setFilterByResourcesIds: (state, action) => {
      state.filtersByResourcesIds = action.payload;
    },
    setFilterByOriginActivitiesIds: (state, action) => {
      state.filtersByOriginActivityIds = action.payload;
    },
  },
});

export const {
  startActivities,
  setActivities,
  startChangeActivities,
  startLoadCatalogStatus,
  setActivitiesSelected,
  setIsNewActivity,
  setCatalogStatus,
  setAddActivities,
  setNotifyPointsChanged,
  setCanContinueAdd,
  saveActivityTemp,
  setCatalogOriginActivities,

  addNewActivityTemp,
  addNewActivityWithTaskTemp,
  cancelAction,
  cleanActivities,
  refreshActities,
  EndLoadingActivities,
  setActivity,
  startActionLoading, 


  updateActivities,
  endChangeActivities,
  setFilterByStatusActivitiesIds,
  setFilterByResourcesIds,
  setFilterByOriginActivitiesIds

} = ActivitiesSlice.actions;
