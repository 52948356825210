import { Tooltip } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { QuestionDialog } from "../../../../../ui/components/Dialogs";
import { ChangeUserStoryByRelational, ChangeUserStorySelected } from "../../../slices";
import { CancelOperation } from "../../../slices/Activities/ActivitiesTrunk";

export const ProjectUserStoryDetailsItem = ({ IdUserStory, Name, Description }) => {
  const dispatch = useDispatch();

  const { userstorySelected } = useSelector(state => state.userstories);
  const { sections, sectionSelected, sectionswithwarnings } = useSelector(state => state.sections);
  const { activities } = useSelector((state) => state.activities);

  const [record, setRecord] = useState(0);

  const [isRelational, setIsRelational] = useState(false);

  useEffect(() => {
    if (sectionSelected !== 0) {
      const { UserStoriesRelated } = sections?.find(item => item.IdSection === sectionSelected)

      if (UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory) !== undefined) {
        const { IsSelected } = UserStoriesRelated?.find(item => item.IdUserStory === IdUserStory);
        setIsRelational(IsSelected);
      }
    } else {
      setIsRelational(false);
    }
  }, [sectionSelected, userstorySelected, IdUserStory, sections])


  useEffect(() => {
    setRecord(userstorySelected);
  }, [userstorySelected])

  const CheckValidUserStory = () => {
    return sectionswithwarnings?.filter(sec => sec.IdUserStory === IdUserStory)?.length >= 1 ? 'border-danger' : '';
  }

  const onClickHandler = () => {
    if(activities?.filter(act => act.IdActivity === 0).length > 0){
        QuestionDialog('Hay actividades con cambios pendientes de guardar, ¿Desea descartar los cambios?', 'Actividades').then(result => {
            if(result.isConfirmed){
                dispatch(CancelOperation());
                dispatch(ChangeUserStorySelected(IdUserStory, isRelational))
            }
        });
    }else{
      dispatch(ChangeUserStorySelected(IdUserStory, isRelational))
    }
}

  return (
    <>
      <div className="plans-items">
        <Tooltip title={Description} placement="right" arrow>
          <div className={`plan-item  ${IdUserStory === record ? "selected" : isRelational ? "selected-relation" : "plan-item"} ${CheckValidUserStory()}`}
            onClick={onClickHandler}
          >
            <div className="names">
              <span className=""><i className='bi bi-clock'></i></span>
              <span className="plan-tit flex-none w-auto">{Name}</span>
              <span className="plan-name flex-fill d-inline-block d-xl-none">
                {Description}
              </span>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  )
}
