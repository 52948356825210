const convertDate = (inputFormat) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), getMonthName(d.getMonth() + 1), d.getFullYear()].join(" ");
};

const getMonthName = (monthNumber) => {
  const date = new Date();

  date.setMonth(monthNumber - 1);

  const convertDate = date.toString();
  return convertDate.substr(4, 3);
};

export { convertDate, getMonthName };
