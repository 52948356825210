import { Skeleton } from "@mui/material";
import React from "react";
import { HasLoadingAll } from "../../../../../ui/helpers/LoadingStatus";
import { useValidationState } from "../../../hooks/useValidationState";
import { ChartInformationItem } from "../ChartInformationItem";
import { CirculeChart } from "../CirculeChart";

export const ProjectChartWeeks = ({ section }) => {

  const { isLoadingComponents } = useValidationState(section);

  const PaintInformation = ({ start = 'dd/mm/aaaa', delivery = 'dd/mm/aaaa', date = '-' }) => {
    return <>
      <div className="list-main back-grey color-font flex-fill">
        <ul>
          <ChartInformationItem itemTitle="Inicio" itemPoints={start} />
          <ChartInformationItem itemTitle="Entrega" itemPoints={delivery} />
          <ChartInformationItem itemTitle="Fecha Real" itemPoints={date} />
        </ul>
      </div>
    </>
  }

  return (
    <>
      <div className="chart-list py-2">
        <div className="d-flex">
          {
            isLoadingComponents ? (
              <>
                <Skeleton variant="circular" >
                  <CirculeChart />
                </Skeleton>
                <div className="list-main back-grey color-font flex-fill">
                  <ul>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                    <Skeleton>
                      <ChartInformationItem />
                    </Skeleton>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <CirculeChart title="Semanas" points={'0.0'} />
                <PaintInformation />
              </>
            )
          }
        </div>
      </div>
    </>
  );
};
