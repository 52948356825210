import { FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemText, Menu, MenuItem, Radio, RadioGroup } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { ExtraActivityType } from '../../../../../ui/types/ExtraActivityType';
import { OriginActivityType } from '../../../../../ui/types/OriginActivityType';
import { RolesType } from '../../../../../ui/types/RolesType';
import { UpdateOriginActivity } from '../../../services/activityService';
import { UpdateActivity, UpdateOriginActivityNew } from '../../../slices/Activities/ActivitiesTrunk';

export const ExtraTaskCell = ({ row }) => {
    const dispatch = useDispatch();
    const { idRol } = useSelector((state) => state.authorization);

    const { original } = row;
    const { OriginActivity: { IdOriginActivity }, IdOriginActivityType  } = original;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const UpdateIdOrigenActivity = (originActivity) => {
        if (HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])) {

            //ExtraActivityType[originActivityID]
            //console.log(originActivityID);

            const _origin = { IdOriginActivity: originActivity.Id, Name: originActivity.Name }
            dispatch(UpdateOriginActivityNew( {...original, OriginActivity: { ..._origin} } ));
            setAnchorEl(null);

            // UpdateOriginActivity(IdActivity, originActivityID).then(response => {
            //     setAnchorEl(null);
            //     enqueueSnackbar('La información de la actividad se ha actualizado correctamente.', { variant: 'success', preventDuplicate: true, autoHideDuration: 4000 });
            // });
        }
    }

    return (
        <>
            {
                IdOriginActivityType !== OriginActivityType.DURING_PLANNING &&
                (<div className="dropdown dropdown-main arrow-none" >
                    <span className="dropdown-toggle mt-1"
                        type="button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <span>
                            <i className="icon-mark_active"></i>
                        </span>
                    </span>
                    <div className="dropdown-menu">
                        {/* <FormControl className="d-flex align-items-center gap-10 lh-1 px-3">
                            <FormLabel id="demo-radio-buttons-group-label">Tarea Extra</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={IdOriginActivity}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} value="1" control={<Radio />} onClick={() => UpdateIdOrigenActivity(1)} label="Interna" />
                                <FormControlLabel disabled={!HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager])} value="2" control={<Radio />} onClick={() => UpdateIdOrigenActivity(2)} label="Externa" />
                            </RadioGroup>
                        </FormControl> */}
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            <MenuItem onClick={(() => UpdateIdOrigenActivity(ExtraActivityType.Interno))}>
                                <Radio checked={IdOriginActivity === ExtraActivityType.Interno.Id} />
                                Interno
                            </MenuItem>
                            <MenuItem onClick={() => UpdateIdOrigenActivity(ExtraActivityType.Externo)}>
                                <Radio checked={IdOriginActivity === ExtraActivityType.Externo.Id} />
                                Externo
                            </MenuItem>
                        </Menu>
                    </div>
                </div >)
            }
        </>
    )
}
