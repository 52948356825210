export const FiltersList = () => {
    return (
        <>
            <article className="color-font ps-4 mb-3">Mensajes</article>

            <div className="list-main back-none">
                <ul>
                    <li className="m-0">
                        <div className="wrap color-white back-dark ps-4 pe-2 py-2">
                            <div><i className="icon-ichead_message"></i></div>
                            <div className="flex-fill">Sin leer</div>
                            <div><i className="icon-cerrar_icon"></i></div>
                        </div>
                    </li>
                    <li className="m-0">
                        <div className="wrap ps-4 pe-2 py-2">
                            <div><i className="icon-cnv-mencion"></i></div>
                            <div className="flex-fill">Menciones</div>
                        </div>
                    </li>
                    <li className="m-0">
                        <div className="wrap ps-4 pe-2 py-2">
                            <div><i className="icon-cnv-mark"></i></div>
                            <div className="flex-fill">Importantes</div>
                        </div>
                    </li>
                    <li className="m-0">
                        <div className="wrap ps-4 pe-2 py-2">
                            <div><i className="icon-cnv-link"></i></div>
                            <div className="flex-fill">Enlaces</div>
                        </div>
                    </li>
                    <li className="m-0">
                        <div className="wrap ps-4 pe-2 py-2">
                            <div><i className="icon-cnv-files"></i></div>
                            <div className="flex-fill">Archivos</div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
