import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableBody, TableRow } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { SortableCard } from '../Card/SortableCard';
import { EnhancedTableHead } from './EnhancedTableHead';

export const EnhancedTable = ({ boardID, rows, IsSprint = false  }) => {
    const { setNodeRef } = useDroppable({ id: boardID });

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if(orderBy === 'Name' && a[orderBy] === '' && a['IdActivity'] === 0) return 0
        if(orderBy === 'Points' && a[orderBy] === 0 && a['IdActivity'] === 0) return 0
        if(orderBy === 'IdUser' && a[orderBy] === 0 && a['IdActivity'] === 0) return 0
        if(orderBy === 'IdStatusActivity' && a[orderBy] === 0 && a['IdActivity'] === 0) return 0
        if(orderBy === 'Priority' && a[orderBy] === 0 && a['IdActivity'] === 0) return 0
        if(orderBy === 'OriginActivity' && a[orderBy] === 0 && a['IdActivity'] === 0) return 0

        const valueA = orderBy !== 'OriginActivity' ? a[orderBy] : a[orderBy].IdOriginActivity;
        const valueB = orderBy !== 'OriginActivity' ? b[orderBy] : b[orderBy].IdOriginActivity;


        if (valueB < valueA) {
            return -1;
        }
        if (valueB > valueA) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });

        const newOrden = stabilizedThis.map((el) => el[0]);
        return newOrden;
    }

    return (
        <Table className="table">
            <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
            />
            <TableBody ref={setNodeRef}>
                <SortableContext
                    key={boardID}
                    id={boardID}
                    items={rows}
                    strategy={verticalListSortingStrategy}
                >
                    {stableSort(rows, getComparator(order, orderBy))
                        .map((row) => {
                            return (
                                <SortableCard key={row?.IdActivity} id={row?.IdActivity} item={row} IsSprint={IsSprint} boardID={boardID} />
                            );
                        })}
                </SortableContext>
            </TableBody>
        </Table>
    )
}
