import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { UserAvatar } from "../../../ui/components/User/UserAvatar";
import { GetProject, LoadProject } from '../../project/slices'

export const ProjectItem = ({ projectData }) => {

  const dispatch = useDispatch();
  const { Name, ProjectUsersList, IdProject } = projectData;

  const onClickHandler = useCallback(() => 
    // { dispatch(LoadProject(projectData)) },
    { dispatch(GetProject(IdProject, true))},
    [IdProject, dispatch],
  )
  

  return (
    <>
      <button className="button-projectitem mt-3" onClick={onClickHandler}>
        <div className="card-list">
          <div className="rows-main align-items-center">
            {/* <div>charts</div> */}
            <div className="flex-fill">
              <article className="color-font-dark font-20 text-start">
                {Name}
              </article>
            </div>
            <div>
              <div className="users-list d-flex">
                {
                  ProjectUsersList?.map(user => (<UserAvatar key={user.Id} {...user} />))
                }
              </div>
            </div>
          </div>
        </div>
      </button>
    </>
  );
};
