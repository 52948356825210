import { List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { HavePermission } from '../../../../../ui/helpers/PermissionsHelper';
import { RolesType } from '../../../../../ui/types/RolesType';
import { UpdateActivity, UpdateActivityNew } from '../../../slices/Activities/ActivitiesTrunk';

export const MenuStatusCell = ({ value, row }) => {
    const dispatch = useDispatch();

    const { idRol } = useSelector((state) => state.authorization);
    const { statusActivities } = useSelector((state) => state.activities);

    const { original } = row;
    const { IdActivity } = original;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedId, setSelectedId] = useState(value);

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, id) => {
        setSelectedId(id);
        setAnchorEl(null);

        dispatch(UpdateActivityNew({ ...original, IdStatusActivity: id, showMessage: false }));


        // if (IdActivity !== 0) {
        //     const ActivityEdited = {
        //         IdStatusActivity: id,
        //         IdUser: IdUser,
        //         Name: Name,
        //         Description: Description,
        //         Points: Points,
        //         Priority: Priority,
        //         Orden: Orden
        //     };

        //     onUpdateActivity(IdActivity, ActivityEdited).then(response => {
        //         //dispatch(GetActivitiesByUserStoryAndSection(true, false));
        //         dispatch(GetActivitiesByUpdated());
        //     });
        // }
        // else {
        //     dispatch(UpdateActivityTemp({ IdStatusActivity: id }));
        // }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="dropdown dropdown-main">
                <List
                    component="nav"
                    aria-label="Device settings"
                    sx={{ bgcolor: 'background.paper' }}
                >
                    <ListItem
                        alignItems="flex-start"
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickListItem}
                    >
                        <ListItemIcon className='min-width-auto'>
                            <span><i className={statusActivities.find(item => item.IdStatusActivity === value)?.ClassName}></i></span>
                        </ListItemIcon>
                        <ListItemText
                            secondary={
                                <>
                                    {statusActivities.find(item => item.IdStatusActivity === value)?.Name}
                                </>
                            }
                        />
                    </ListItem>
                </List>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={open && HavePermission(idRol, [RolesType.Admin, RolesType.ProjectManager, RolesType.Resource]) && IdActivity !== 0}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'lock-button',
                        role: 'listbox',
                    }}
                >
                    {statusActivities?.map((option, index) => (
                        <MenuItem
                            key={option?.IdStatusActivity}
                            selected={option?.IdStatusActivity === selectedId}
                            onClick={(event) => handleMenuItemClick(event, option?.IdStatusActivity)}
                        >
                            <>
                                <div className="d-flex align-items-center gap-10 lh-1">
                                    <span><i className={option?.ClassName}></i></span>
                                    <span className="flex-fill font-14">{option.Name}</span>
                                </div>
                            </>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </>
    )
}
