import React from 'react'
import { useState } from 'react';
import { UserAvatar } from '../User/UserAvatar'

export const ResourceItemAdd = ({ Alias, ImgSrc, Name, Id, Skill, Status, onActionHandler, UserAdded }) => {
    const [status, setStatus] = useState(Status);

    const onUpdateItem = () => {
        const news = UserAdded.map(item => {

            if (item.Id === Id) {
                setStatus(!item.Status)
                return { ...item, Status: !item.Status }
            }

            return item;
        });

        onActionHandler(news);
    };

    return (
        <>
            <li>
                <div className="wrap">
                    <div>
                        <UserAvatar Id={Id} Alias={Alias} Skill={Skill} ImgSrc={ImgSrc} />

                    </div>
                    <div className="flex-fill">
                        <p className="font-18 mb-1">{Name}</p>
                        <span className="color-font">{Skill.NameToShow}</span>
                    </div>
                    <div>
                        {status ? (
                            <span className="font-18" onClick={onUpdateItem}>
                                <i className="icon-rc_btnn-cancel_v2"></i>
                            </span>
                        ) : (
                            <span className="font-18 color-grey-3" onClick={onUpdateItem}>
                                <i className="icon-rc_btnn-add"></i>
                            </span>
                        )}
                    </div>
                </div>
            </li>
        </>
    )
}
