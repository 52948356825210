import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { QuestionDialog, SuccessDialog } from "../../../ui/components/Dialogs";
import { SnackBarDialogs } from "../../../ui/components/SnackBarDialogs";
import { GetErrorResponse } from "../../../ui/helpers/ErrorHelper";
import { useFetchCatalogs } from "../../../ui/hooks/useFetchCatalogs";
import { setMessageError } from "../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { MassageType, MessageType } from "../../../ui/types/MessageType";
import { saveResource } from "../services/userService";

export const ResourceNew = ({ setIsNewResource }) => {

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    catalogs: { Roles, Skills }
  } = useFetchCatalogs();

  const onCancel = () => {
    setIsNewResource(false);
  };

  const onRegister = (form) => {
    QuestionDialog("¿Desea crear un nuevo recurso?", "Agregar Recurso").then(
      (result) => {
        if (result.isConfirmed) {
          saveResource(form).then((response) => {
            const {
              data: { Message },
            } = response;

            SuccessDialog(Message, "Recurso agregado").then(() =>
              setIsNewResource(false)
            );
          }).catch((error) => {
            dispatch(setMessageError({ message: GetErrorResponse(error), errorType: MessageType.error }));
          });
        }
      }
    );
  };

  const onlyLetters = (event) => {
    var key = event.keyCode;

    if (!((key >= 65 && key <= 90) || key === 8 || key === 32 || key === 9))
      event.preventDefault();
  }

  return (
    <>
      <form onSubmit={handleSubmit(onRegister)}>
        <article className="font-18">Invitar Recurso</article>
        <p>Invita un recurso a la plataforma XScrum</p>

        <div className="mb-2">
          <label className="form-label">Nombre(s)</label>
          <input
            type="text"
            className="form-control"
            name="Name"
            onKeyDown={onlyLetters}
            {...register("Name", { required: true })}
          />
          {errors.Name && (
            <p className="msgError"> El campo es obligatorio.</p>
          )}
        </div>
        <div className="mb-2">
          <label className="form-label">Apellidos</label>
          <input
            type="text"
            className="form-control"
            name="LastName"
            onKeyDown={onlyLetters}
            {...register("LastName", { required: true })}
          />
          {errors.LastName && (
            <p className="msgError"> El campo es obligatorio.</p>
          )}
        </div>
        <div className="mb-2">
          <label className="form-label">Correo Electrónico</label>
          <input
            type="email"
            className="form-control"
            name="Email"
            {...register("Email", { required: true })}
          />
          {errors.Email && (
            <p className="msgError"> El campo es obligatorio.</p>
          )}
        </div>
        <div className="mb-2">
          <label className="form-label">Puesto</label>
          <select
            className="form-select"
            {...register("IdSkill", { required: true })}
          >
            {Skills?.map((skill) => (
              <option key={skill.IdSkill} value={skill.IdSkill}>
                {skill.NameToShow}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <label className="form-label">Tipo de Permisos</label>
          <div className="dropdown dropdown-resources">
            <select
              className="form-select"
              {...register("IdRol", { required: true })}
            >
              {Roles?.map((rol) => (
                <option key={rol.Id} value={rol.Id}>
                  {rol.NameToShow}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button type="submit" className="button large dark w-100 mt-3">
          Invitar
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="button large grey-light w-100 mt-2"
        >
          Cancelar
        </button>
      </form>
    </>
  );
};
