import React from 'react'

export const ChartInformationItem = ({ itemTitle = '', itemPoints = 0 }) => {
    return (
        <li>
            <div className="wrap">
                <div className="flex-fill">{itemTitle}</div>
                <div>{itemPoints}</div>
            </div>
        </li>
    )
}
