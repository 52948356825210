import React from 'react'

export const FilterItemTasks = () => {
    return (
        <>
            <div className="list-main back-none font-14 px-3 mb-3">
                <article className="color-font mb-2">Tareas extras</article>
                <ul>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Interno</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="wrap">
                            <div className="flex-fill">
                                <span>Externa</span>
                            </div>
                            <div>
                                <span>
                                    <input className="form-check-input m-0" type="checkbox" value="" id="" />
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
