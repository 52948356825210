import { NamesProjectsCharts } from "../../ui/components/NamesProjectsCharts"
import { NavReportsLinks } from "../../ui/components/NavReportsLinks"
import { HistoryList } from "./components/HistoryList"

export const HistoryPage = () => {
    return (
        <>
            <div className="container-main-wrap">
                <div className="container-max">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 col-xxxl-10">
                                <div className="card-main mb-3">
                                    <NamesProjectsCharts />
                                </div>
                            </div>
                            <div className="col-lg-3 col-xxxl-2">
                                <div className="card-main mb-3">
                                    <NavReportsLinks />
                                </div>
                            </div>
                        </div>

                        <div className="card-main">
                            <div className="new-project-body-top border-bottom-grey-3 pb-3 mb-4">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="dato-name color-font">
                                            <article className="font-18 mb-2">
                                                Historial
                                            </article>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex gap-10">
                                            <span className="flex-fill">
                                                <input type="text" className="form-control" placeholder="Buscar" />
                                            </span>
                                            <span type="button" className="button grey-light font-20 px-3">
                                                <span><i className="icon-filtro-icon_v2"></i></span>
                                            </span>
                                            <button className="button grey-light font-20">
                                                <i className="icon-cerrar_icon"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="history-wrap-list">
                                <HistoryList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
