import React from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { Navbar } from "../../ui/components/Navbar";
import { LoginChangePassPage } from "../auth/pages/LoginChangePassPage";
import { LoginCreatePassPage } from "../auth/pages/LoginCreatePassPage";
import { LoginResetPassPage } from "../auth/pages/LoginResetPassPage";
import { LoginNewPassPage } from "../auth/pages/LoginNewPassPage";
import { DashboardPage } from "../dashboard/DashboardPage";
import { UserAccountPage } from "../user-account/UserAccountPage";
import { ActivitiesPage } from "../activities/ActivitiesPage";
import { ConversationPage } from "../conversation/ConversationPage";
import { LayoutNewProject } from "../layouts-prueba/LayoutNewProject";
import { HistoryPage } from "../history/HistoryPage";
import { DeliveriesPage } from "../deliveries/DeliveriesPage";
import { SectionsType } from "../../ui/types/SectionsType";
import { ProjectPage } from "../project/ProjectPage";

export const SectionsRoutes = () => {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="dashboard" element={<DashboardPage />} />

        <Route path="project/new" element={<ProjectPage section={SectionsType.NewProject} />} />
        <Route path="project/:id/planning/definitions" element={<ProjectPage section={SectionsType.Project} />} />
        <Route path="project/:id/planning/activities" element={<ProjectPage section={SectionsType.ProjectDetails} />} />
        <Route path="project/:id/hold/activities" element={<ProjectPage section={SectionsType.ProjectInHold} />} />
        <Route path="project/:id/sprint/activities" element={<ProjectPage section={SectionsType.ProjectInSprint} />} />

        <Route path="loginchangepass" element={<LoginChangePassPage />} />
        <Route path="logincreatepass" element={<LoginCreatePassPage />} />
        <Route path="loginresetpass" element={<LoginResetPassPage />} />
        <Route path="loginnewpass" element={<LoginNewPassPage />} />
        <Route path="useraccount" element={<UserAccountPage />} />
        <Route path="activities" element={<ActivitiesPage />} />
        <Route path="conversation" element={<ConversationPage />} />
        <Route path="history" element={<HistoryPage />} />
        <Route path="deliveries" element={<DeliveriesPage />} />

        <Route path="layoutnewproject" element={<LayoutNewProject />} />

        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>

    </>
  );
};
