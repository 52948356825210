import { Tooltip } from "@mui/material";
import { useState } from "react";
import { QuestionDialog } from "../../../../ui/components/Dialogs";

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeleteUserStory, SaveUserStoryWithEpic, UpdateUserStory } from "../../services/userStoryService";
import { DraggableHandle } from "../epics/ListTst";
import { ControllerInput } from "../../../../ui/components/ControllerInput";
import { isEmptyOrSpaces } from "../../../../ui/helpers/StringHelper";
import { PermissionGate } from "../../../../ui/components/PermissionGate/PermissionGate";
import { ModuleType } from "../../../../ui/types/ModuleType";
import { SCOPES } from "../../../../ui/types/ScopesType";
import { RecalculationProjectData } from "../../slices";
import { useDispatch } from "react-redux";
import { setMessageError } from "../../../../ui/store/slices/ErrorSlice/ErrorSlice";
import { MessageType } from "../../../../ui/types";

export const ProjectUserStoryItem = ({ position, item, validateTree, canAddUserStory, getUserStories }) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { IdUserStory, Name, Description, Points, EpicsUserStory } = item;
  const [UserStoryDescription, setUserStoryDescription] = useState(Description);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item });

  const onRemoveItem = () => {
    QuestionDialog(`¿Esta seguro que desea eliminar ${Description}?`, Name).then((resp) => {
      if (resp.isConfirmed) {
        dispatch(RecalculationProjectData());
        DeleteUserStory(IdUserStory).then((response) => {
          const { status } = response;

          if (status === 200) getUserStories();
        }).catch(error => {
          const { status, data } = error.response;

          if (status === 400) {
              const { Message } = data;
              dispatch(
                  setMessageError({
                      message: Message,
                      errorType: MessageType.error,
                      status: status
                  })
              );
          }
        });
      }
    });
  };

  const onEditItem = () => {
    if (canAddUserStory) setIsEditMode(true);
  };

  const updateUserStoryDescription = ({ target: { value } }) => {
    setUserStoryDescription(value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (UserStoryDescription !== "") {
        UpdateUserStory(IdUserStory, {
          Name: Name,
          Description: UserStoryDescription,
          Orden: position,
          Points: Points,
        })
          .then((response) => {
            const { status } = response;

            if (status === 200) {
              setUserStoryDescription(e.target.value);
              setIsEditMode(false);
              getUserStories();
            }
          })
          .catch((error) => console.log(error));
      }
    } else if (e.key === "Escape") {
      setUserStoryDescription(Description);
      setIsEditMode(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setUserStoryDescription(Description);
      setIsEditMode(false);
    }
  };

  const onUserStoryItem = ({ canUpdate = true, IdEpica, IsSelected }) => {
    if (!canUpdate) return;

    const epicEdit = {
      IdEpic: IdEpica,
      IdUserStory: IdUserStory,
      IsSelected: !IsSelected
    };

    SaveUserStoryWithEpic(epicEdit).then(response => {
      const { status } = response;

      if (status === 200) {
        getUserStories();
      }
    }).catch(error => {
      const { status, data } = error.response;

      if (status === 400) {
          const { Message } = data;
          dispatch(
              setMessageError({
                  message: Message,
                  errorType: MessageType.error,
                  status: status
              })
          );
      }
    });
  };

  const onBlurInput = () => {
    setIsEditMode(false);
  };

  const onKeyHandler = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!isEmptyOrSpaces(UserStoryDescription)) {
        UpdateUserStory(IdUserStory, {
          Name: Name,
          Description: UserStoryDescription,
          Orden: position,
          Points: Points,
        })
          .then((response) => {
            const { status } = response;

            if (status === 200) {
              setUserStoryDescription(e.target.value);
              setIsEditMode(false);
              getUserStories();
            }
          })
          .catch((error) => console.log(error));
      }
    } else if (e.keyCode === 27) {
      setUserStoryDescription(Description);
      setIsEditMode(false);
    }
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <div className="plans-items d-flex" ref={setNodeRef} style={style} {...attributes}>
        <span className="icon flex-none mt-2" >
          <PermissionGate module={ModuleType.UserStories} scopes={[SCOPES.CanUpdate]} errorProps={{ handleListeners: {} }}>
            <DraggableHandle handleListeners={listeners} />
          </PermissionGate>
        </span>
        <div
          className={validateTree ? (EpicsUserStory?.filter(item => item.IsSelected).length >= 1 ? "plan-item flex-fill" : "plan-item flex-fill border-danger animate__zoomIn") : "plan-item flex-fill"}
          onKeyDown={handleKeyDown}
          onBlur={onBlurInput}
        >
          <div className="names">
            <span className="plan-tit color-font-dark flex-none">
              {Name}
            </span>

            {
              isEditMode ? (
                <PermissionGate module={ModuleType.UserStories} scopes={[SCOPES.CanUpdate]} >
                  <ControllerInput
                    value={UserStoryDescription}
                    onChange={({ target: { value } }) => setUserStoryDescription(value)}
                    onKeyDown={onKeyHandler}
                    className={"form-control"}
                  />
                </PermissionGate>

              ) : (
                <>
                  <div className="d-flex flex-fill">
                    <PermissionGate module={ModuleType.UserStories} scopes={[SCOPES.CanUpdate]} errorProps={{ title: '' }}>
                      <Tooltip title={"Editar User Story"}>
                        <span
                          className="plan-name flex-fill"
                          onDoubleClick={onEditItem}
                        >
                          {Description}
                        </span>
                      </Tooltip>
                    </PermissionGate>
                  </div>
                  <PermissionGate module={ModuleType.UserStories} scopes={[SCOPES.CanDelete]} errorProps={{ hidden: !canAddUserStory }}>
                    <span
                      className="font-18"
                      onClick={onRemoveItem}
                    >
                      <i className="icon-rc_btnn-quitar"></i>
                    </span>
                  </PermissionGate>
                </>
              )}
          </div>
          <div className="collapse show" id="collapsePlan-dos">
            <div className="sub-names">
              <small className="plan-tit color-font font-12 flex-none">
                Épica:
              </small>
              <div className="plan-badges-list flex-fill">
                {EpicsUserStory?.map((item, index) => (
                  <PermissionGate key={item.IdEpica} module={ModuleType.UserStories} scopes={[SCOPES.CanUpdate]} errorProps={{ onClick: onUserStoryItem({ canUpdate: false }) }}>
                    <span
                      className={
                        item.IsSelected
                          ? "badges back-dark"
                          : "badges border-color-dark"
                      }
                      onClick={() => onUserStoryItem(item)}
                    >
                      {`EP ${index}`}
                    </span>
                  </PermissionGate>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
