import { GetAllProjects } from "../../../../sections/project/services/projectService";
import { setProjects, startLoadingProjects } from "./ProjectsSlice";

export const GetProjects = () => {
  return async (dispatch) => {
    dispatch(startLoadingProjects());

    await GetAllProjects().then((response) => {
      const { data: { Items } } = response;

      dispatch(setProjects(Items));
    });
  };
};
