import { createSlice } from "@reduxjs/toolkit";

export const SectionsSlice = createSlice({
  name: "sections",
  initialState: {
    isLoading: true,
    hasSectionsChanged: false,
    sections: [],
    SectionsBackup: [],
    totalSections: 0,
    isSectionRelationshipCompleted: false,
    sectionSelected: 0,
    sectionSelectedChanged: false,
    userstoryRelated: false,
    sectionswithwarnings: []
  },
  reducers: {
    startSections: (state) => {
      state.isLoading = true;
    },
    startSectionsChanges: (state) => {
      state.hasSectionsChanged = true;
    },
    startSectionSelectedChanged: (state) => {
      state.sectionSelectedChanged = true;
    },
    setSections: (state, action) => {
      state.isLoading = false;
      state.sections = action.payload;
      state.totalSections = action.payload?.length;
    },
    setSectionsWithWarnings: (state, action) => {
      state.sectionswithwarnings = action.payload;
    },
    setSectionsBackup: (state, action) => {
      state.SectionsBackup = action.payload;
    },
    cleanSections: (state) => {
      state.isLoading = true;
      state.hasSectionsChanged = false;
      state.sections = [];
      //state.SectionsBackup = [];
      state.totalSections = 0;
      state.isSectionRelationshipCompleted = false;
      state.sectionSelected = 0;
      state.sectionSelectedChanged = false;
    },
    checkSectionsRelationshipCompleted: (state, action) => {
      state.isSectionRelationshipCompleted = true;

      if (action.payload.find(({ UserStoriesSection }) => UserStoriesSection.filter((relation) => relation.IsSelected).length === 0) !== undefined) {
        state.isSectionRelationshipCompleted = false;
      }
    },
    setSectionSelected: (state, action) => {
      state.sectionSelected = action.payload;
    },
    setRefreshSectionSelected: (state, action) => {
      state.sectionSelected = action.payload;
    },
    refreshSections: (state, action) => {
      state.hasSectionsChanged = false;
      state.sections = action.payload;
      state.totalSections = action.payload?.length;
    },
    endLoadingSections: (state) => {
      state.isLoading = false;
    },
    EndSectionSelectedChanged: (state) => {
      state.sectionSelectedChanged = false;
    },
    EndSectionChanged: (state) => {
      state.hasSectionsChanged = false;
    },
    restoreSections: (state) => {
      state.sections = state.SectionsBackup;
    },
    startCheckUserStoryRelated: (state) => {
      state.userstoryRelated = true;
    },
    EndCheckUserStoryRelated: state => {
      state.userstoryRelated = false;
    }
  },
});

export const {
  startSections,
  startSectionsChanges,
  startCheckUserStoryRelated,

  setSections,
  cleanSections,
  checkSectionsRelationshipCompleted,
  setSectionSelected,
  refreshSections,
  endLoadingSections,
  EndSectionSelectedChanged,
  startSectionSelectedChanged,
  setRefreshSectionSelected,
  EndSectionChanged,
  restoreSections,
  setSectionsBackup,
  EndCheckUserStoryRelated,
  setSectionsWithWarnings,
} = SectionsSlice.actions;
