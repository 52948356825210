import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

export const SkeletonTable = () => {
    return (
        <>
            <Table className="table">
                <TableHead>
                    <TableRow >
                        <TableCell className='text-start w-75' >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow >
                        <TableCell className='text-start w-75' >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>

                    </TableRow>
                    <TableRow >
                        <TableCell className='text-start w-75' >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>

                    </TableRow>
                    <TableRow >
                        <TableCell className='text-start w-75' >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>

                    </TableRow>
                    <TableRow >
                        <TableCell className='text-start w-75' >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>
                        <TableCell >
                            <Skeleton sx={{ bgcolor: '#3E3E3E' }} />
                        </TableCell>

                    </TableRow>
                   
                </TableBody>
            </Table>
        </>
    )
}
