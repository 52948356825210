import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HasLoadingAllInDashboard } from "../../../ui/helpers/LoadingStatus";
import { GetProjects } from "../../../ui/store/slices/ProjectsSlice/ProjectsTrunk";
import { ProjectItem } from "./ProjectItem";

export const ProjectList = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector(state => state.projects);

  const navigate = useNavigate();
  const { url } = useSelector(state => state.project);

  useEffect(() => {
    navigate(url);
  }, [url, navigate])

  useEffect(() => {
    dispatch(GetProjects());
  }, [dispatch]);

  return (
    <>
      <div className="dashboardwrap-listprojects mt-4">
        {
          (
            HasLoadingAllInDashboard() ? Array.from(new Array(2)) : projects).map((item, index) => (
              <div key={index}>
                {
                  item ? (
                    <ProjectItem key={item.IdProject} projectData={item} />
                  ) : (
                    <Skeleton key={index} className="card-list mt-3" sx={{ bgcolor: '#3E3E3E' }} variant="rectangular" height={70} />
                  )
                }
              </div>
            )
            )
        }
      </div>
    </>
  );
};
